import React, {
  useState, // ,{useEffect}
} from 'react'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import { GetAllRankings } from 'utils/apis/ranking'
import { UseAllPlayersRanking } from 'utils/apis/ranking'
import { useQueryClient } from '@tanstack/react-query'
import { IProfile } from 'utils/types/profile.type'

export default function Ranking() {
  const { data: allrankings } = GetAllRankings(10, 0)
  const { data: playersRanking } = UseAllPlayersRanking()
  const [teamSearch, setteamSearch] = useState('')
  const [playerSearch, setplayerSearch] = useState('')
  return (
    <div className="container-fluid m-0 p-0">
      <div className="container">
        <div className="section-heading text-center pt-5 border border-0 border-success">
          <h2>
            Team <span>Standings</span>
          </h2>
        </div>
        <div className="d-flex align-items-center justify-content-end border border-0 border-success contact-form-group">
          <div className="form-feild">
            <input
              onChange={(e: any) => {
                setteamSearch(e?.target?.value)
              }}
              className="form-control bd-grad mr-sm-2 bg-transparent text-white"
              type="search"
              placeholder="Search Team"
              aria-label="Search"
            />
          </div>
        </div>
        {allrankings?.map((item: any) => {
          return (
            <GetAllTeamsRankings
              key={item.id}
              data={item}
              searchKey={teamSearch?.toLowerCase()}
            />
          )
        })}
      </div>
      <div className="container mt-2">
        <div className="section-heading text-center pt-5 border border-0 border-success">
          <h2 className="text-uppercase">
            Player <span>Ranking</span>
          </h2>
        </div>
        <div className="d-flex align-items-center justify-content-end border border-0 border-success contact-form-group">
          <div className="form-feild">
            <input
              onChange={(e: any) => {
                setplayerSearch(e?.target?.value)
              }}
              className="form-control bd-grad mr-sm-2 bg-transparent text-white"
              type="search"
              placeholder="Search Player"
              aria-label="Search"
            />
          </div>
        </div>
        {playersRanking?.map((item: any) => {
          return (
            <GetAllPlayersRankings
              key={item.id}
              data={item}
              searchKey={playerSearch?.toLowerCase()}
            />
          )
        })}
      </div>
    </div>
  )
}
function GetAllTeamsRankings({ data, searchKey }: any) {
  const [teamData, setTeamData] = useState(data)
  const handleSortByWin = (orderType: number, sortType: string) => {
    const getTeamsFromParentData: any[] = teamData?.teams
    switch (sortType) {
      case 'win':
        {
          const sortedData = getTeamsFromParentData.sort((a, b) => {
            if (a?.win < b?.win) return -1 * orderType
            if (a?.win > b?.win) return 1 * orderType
            return 0
          })
          const payload = {
            ...teamData,
            teams: sortedData,
          }
          setTeamData(payload)
        }
        break
      case 'lose':
        {
          const sortedData = getTeamsFromParentData.sort((a, b) => {
            if (a?.lose < b?.lose) return -1 * orderType
            if (a?.lose > b?.lose) return 1 * orderType
            return 0
          })
          const payload = {
            ...teamData,
            teams: sortedData,
          }
          setTeamData(payload)
        }
        break
      case 'forfiet':
        {
          const sortedData = getTeamsFromParentData.sort((a, b) => {
            if (a?.forfiet < b?.forfiet) return -1 * orderType
            if (a?.forfiet > b?.forfiet) return 1 * orderType
            return 0
          })
          const payload = {
            ...teamData,
            teams: sortedData,
          }
          setTeamData(payload)
        }
        break
      case 'draw':
        {
          const sortedData = getTeamsFromParentData.sort((a, b) => {
            if (a?.draw < b?.draw) return -1 * orderType
            if (a?.draw > b?.draw) return 1 * orderType
            return 0
          })
          const payload = {
            ...teamData,
            teams: sortedData,
          }
          setTeamData(payload)
        }
        break
      default:
        break
    }
  }
  return (
    <div className="w-100 m-0 p-0 border border-0 border-danger d-flex flex-column flex-wrap">
      {/* Idr api lagni hai........(Starting.....) */}

      <h2 className="purp">{teamData?.name}</h2>
      <div className="table-data">
        <table className="table bg-gradient rounded border border-2 table-bordered">
          <thead>
            <tr className="">
              <th className="text-white ">Team Name</th>
              <th className="text-white">
                <span className="d-flex align-items-center justify-content-between">
                  <small>Win</small>
                  <button className="d-flex flex-column gap-1">
                    <BiChevronDown
                      color="white"
                      onClick={() => {
                        handleSortByWin(1, 'win')
                      }}
                    />
                    <BiChevronUp
                      color="white"
                      onClick={() => {
                        handleSortByWin(-1, 'win')
                      }}
                    />
                  </button>
                </span>
              </th>
              <th className="text-white">
                <span className="d-flex align-items-center justify-content-between">
                  <small>Lose</small>
                  <button className="d-flex flex-column gap-1">
                    <BiChevronDown
                      color="white"
                      onClick={() => {
                        handleSortByWin(1, 'lose')
                      }}
                    />
                    <BiChevronUp
                      color="white"
                      onClick={() => {
                        handleSortByWin(-1, 'lose')
                      }}
                    />
                  </button>
                </span>
              </th>
              <th className="text-white">
                <span className="d-flex align-items-center justify-content-between">
                  <small>Forfeit</small>
                  <button className="d-flex flex-column gap-1">
                    <BiChevronDown
                      color="white"
                      onClick={() => {
                        handleSortByWin(1, 'forfiet')
                      }}
                    />
                    <BiChevronUp
                      color="white"
                      onClick={() => {
                        handleSortByWin(-1, 'forfiet')
                      }}
                    />
                  </button>
                </span>
              </th>
              <th className="text-white">
                <span className="d-flex align-items-center justify-content-between">
                  <small>DD</small>
                  <button className="d-flex flex-column gap-1">
                    <BiChevronDown
                      color="white"
                      onClick={() => {
                        handleSortByWin(1, 'digitalDollars')
                      }}
                    />
                    <BiChevronUp
                      color="white"
                      onClick={() => {
                        handleSortByWin(-1, 'digitalDollars')
                      }}
                    />
                  </button>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {searchKey === undefined || '' ? (
              <>
                {teamData.teams.map((item: any) => {
                  return (
                    <tr key={item.id}>
                      <td className="text-white">{item?.name}</td>
                      <td className="text-white">{item?.win}</td>
                      <td className="text-white">{item?.lose}</td>
                      <td className="text-white">{item?.forfiet}</td>
                      <td className="text-white">{item?.digitalDollars}</td>
                    </tr>
                  )
                })}
              </>
            ) : (
              <>
                {teamData?.teams
                  ?.filter((item: any) => {
                    return item?.name?.toLowerCase().includes(searchKey)
                      ? item
                      : null
                  })
                  .map((item: any) => {
                    return (
                      <tr key={item.id}>
                        <td className="text-white">{item?.name}</td>
                        <td className="text-white">{item?.win}</td>
                        <td className="text-white">{item?.lose}</td>
                        <td className="text-white">{item?.forfiet}</td>
                        <td className="text-white">{item?.digitalDollars}</td>
                      </tr>
                    )
                  })}
              </>
            )}
          </tbody>
        </table>
      </div>
      {/* Idr api lagni hai........(Ending.....) */}
    </div>
  )
}
function GetAllPlayersRankings({ data, searchKey }: any) {
  const [playerData, setplayerData] = useState(data)
  const handleSortPlayers = (
    sortAttribute: string,
    sortIndex: number,
    sortType: string,
  ) => {
    for (let i = 0; i < playerData?.ranking.length; i++) {
      for (let j = i + 1; j < playerData?.ranking?.length; j++) {
        if (sortType === 'dec') {
          if (
            playerData?.ranking[i]?.updatedPoint[sortAttribute] <
            playerData?.ranking[j]?.updatedPoint[sortAttribute]
          ) {
            const temp = playerData?.ranking[j]
            playerData.ranking[j] = playerData.ranking[i]
            playerData.ranking[i] = temp
          }
        } else {
          if (
            playerData?.ranking[i]?.updatedPoint[sortAttribute] >
            playerData?.ranking[j]?.updatedPoint[sortAttribute]
          ) {
            const temp = playerData?.ranking[j]
            playerData.ranking[j] = playerData.ranking[i]
            playerData.ranking[i] = temp
          }
        }
      }
    }
    const payload = {
      ...playerData,
      updatedPoint: playerData?.ranking,
    }
    setplayerData(payload)
  }
   const queryClient = useQueryClient()
   const profile = queryClient.getQueryData<IProfile>(["getProfile",null])
 
 
  return (
    <div className="w-100 m-0 p-0 border border-0 border-danger d-flex flex-column flex-wrap">
      {/* Idr api lagni hai........(Starting.....) */}
      <h2 className="purp">{playerData?.name}</h2>
      <div className="table-data">
        <table className="table bg-gradient rounded border border-2 table-bordered">
          <thead>
            <tr className="">
              <th className="text-white">Player Name</th>
              {playerData?.resultsKeys?.map((item: any, index: number) => {
                return (
                  <th key={item} className="text-white">
                    <span className="d-flex align-items-center justify-content-between">
                      <small>{item}</small>
                      <button className="d-flex flex-column gap-1">
                        <BiChevronDown
                          color="white"
                          onClick={() => {
                            handleSortPlayers(item, index, 'dec')
                          }}
                        />
                        <BiChevronUp
                          color="white"
                          onClick={() => {
                            handleSortPlayers(item, index, 'asc')
                          }}
                        />
                      </button>
                    </span>
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {searchKey === undefined || '' ? (
              <>
                {playerData?.ranking?.map((item: any) => {
                  return (
                    <tr key={item?.name}>
                      <td className="text-white">
                        <Link to={(profile?.id === item?.id) ? "/profile" : `/profile/${item?.id}`}>{item?.name}</Link>
                      </td>
                      {Object.keys(item?.updatedPoint).map(
                        (item2: any, index: number) => {
                          return index >= 1 &&
                            index <= playerData?.resultsKeys?.length ? ( // this check is for mapping only those points which are in the header of the table because there are also other points coming in the table body that are not the part of the header of the table
                            <td key={index} className="text-white">
                              {item?.updatedPoint[item2]}
                            </td>
                          ) : null
                        },
                      )}
                    </tr>
                  )
                })}
              </>
            ) : (
              <>
                {playerData?.ranking
                  ?.filter((item: any) => {
                    return item?.name.toLowerCase().includes(searchKey)
                      ? item
                      : null
                  })
                  ?.map((item: any) => {
                    return (
                      <tr key={item?.name}>
                        <td className="text-white">
                          <Link to={(profile?.id === item?.id) ? "/profile" :`/profile/${item?.id}`}>{item?.name}</Link>
                        </td>
                        {Object.keys(item?.updatedPoint).map(
                          (item2: any, index: number) => {
                            return index >= 1 &&
                              index <= playerData?.resultsKeys?.length ? ( // this check is for mapping only those points which are in the header of the table because there are also other points coming in the table body that are not the part of the header of the table
                              <td key={index} className="text-white">
                                {item?.updatedPoint[item2]}
                              </td>
                            ) : null
                          },
                        )}
                      </tr>
                    )
                  })}
              </>
            )}
          </tbody>
        </table>
      </div>
      {/* Idr api lagni hai........(Ending.....) */}
    </div>
  )
}
// {
//   data?.ranking?.filter((item:any)=>{
//     return playerSearch?.toLowerCase() ===''?item:item?.name.toLowerCase().includes(playerSearch)
//   })?.map((item:any)=>{return(
//     <tr key={item?.name}>
//       <td  className="text-white"><Link to={`/profile/${item?.id}`}>{item?.name}</Link></td>
//          {
//             Object.keys(item?.updatedPoint).map((item2:any,index:number)=>{return(
//              index>=1&&index<=data?.resultsKeys?.length?    // this check is for mapping only those points which are in the header of the table because there are also other points coming in the table body that are not the part of the header of the table
//               <td key={index} className='text-white'>{item?.updatedPoint[item2]}</td>:null
//             )})
//          }
//     </tr>
//     )})
// }
