import { toast } from 'react-toastify'

export default function Notification(
  status: 'error' | 'info' | 'warning' | 'success' | 'loading',
  message: string,
) {
  if(status === "success") {return (
    toast.success(`${message}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose:1000
     })
  )} else if(status === "error") {
    return toast.error(`${message}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose:1000
     }) 
  }

}
