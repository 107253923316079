import React from 'react'

export default function CoachTeam({teamName,teamLogo,youtubeLink,twitterLink,twitchLink}:{teamName:string,teamLogo:string,youtubeLink:string,twitterLink:string,twitchLink:string}) {
  return (
    <div className='container-fluid m-0 p-0'>
      <div className='section-heading my-4 text-center wow fade-in-bottom pt-5 pb-0 row m-0 p-0 d-flex align-items-center justify-content-center'>
       <h2 className='text-center'>
          My <span>TEAM</span>
        </h2>
      </div>
      <div className='row d-flex align-items-center justify-content-center m-0 p-0 border border-0 border-danger w-100'>
        <div className=' container' style={{ maxWidth: "640px" }}>
        <div className="d-flex justify-content-between mb-5 gap-3 flex-wrap flex-sm-nowrap">
          <div className="form-field col-md-6  m-0">
            <h3 className='fw-bold mb-1'>Team Name</h3>
             <span >{teamName}</span>
          </div>
          <div className="form-field col-md-6  m-0">
            <h3 className='fw-bold mb-1'>Youtube Link</h3>
             <a target='_blank' rel="noreferrer" href={`${youtubeLink}`}>{youtubeLink}</a>
          </div>
        </div>
        <div className="d-flex justify-content-between my-5 gap-3 flex-wrap flex-sm-nowrap">
          
          <div className="form-field col-md-6  m-0">
           <h3 className='fw-bold mb-1'>Twitter Link</h3>
           <a target='_blank' rel="noreferrer" href={`${twitterLink}`}>{twitterLink}</a>
          </div>
          <div className="form-field col-md-6 m-0">
          <h3 className='fw-bold mb-1'>Twitch Link</h3>
           <a target='_blank' rel="noreferrer" href={`${twitchLink}`}>{twitchLink}</a>
          </div>
        </div>
        <div className="d-flex align-items-start justify-content-start flex-column form-field  mt-4">
            <h3 className='fw-bold mb-1'>Team Logo</h3>
            <img className='coach-logo w-25' src={teamLogo}/>
        </div>
        </div>
      </div>    
    </div>
  )
}
