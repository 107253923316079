import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import PrivateRoute from 'routes/privateRoute'
import Landing from 'views/landing'
import Cart from 'views/addCart'
import Estore from 'views/eStore'
import Stream from 'views/gameStreams'
import StudentProfile from 'views/profiles'
import Rankings from 'views/teamRankings'
import ScoreReporting from 'views/scoreReporting'
import Schedule from 'views/schedule'
import ProductDetail from 'views/productDetail'
import Checkout from 'views/shopCheckout'
import Login from 'views/studentSignUp'
import Signup from 'views/studentLogin'
import Reporting from 'views/reporting'
import GameReport from 'views/gameRemort'
import { Congratulation } from 'views/congratulation'
import CoachDashboard from 'views/dashboard'
import CoachSignup from 'views/coach/coachSigup'
import CoachSignin from 'views/coach/coachSignin'
import MainCoachDashboard from 'views/coach/'
import EditStudentProfile from 'views/editStudentProfile'
const routes = [
  {
    path: '/',
    Component: Landing,
  },
  {
    path: '/addcart',
    Component: Cart,
    isPrivate: true,
  },
  {
    path: '/estore',
    Component: Estore,
  },
  {
    path: '/live-games',
    Component: Stream,
  },
  {
    path: '/profile',
    Component: StudentProfile,
    isPrivate: true,
  },
  {
    path: '/edit-profile',
    Component: EditStudentProfile,
    isPrivate: true,
  },
  {
    path: '/profile/:id',
    Component: StudentProfile,
  },
  {
    path: '/dashboard',
    Component: CoachDashboard,
    isPrivate: true,
  },
  {
    path: '/ranking',
    Component: Rankings,
  },
  {
    path: '/about',
    Component: Schedule,
  },
  {
    path: '/play-now',
    Component: ScoreReporting,
  },
  {
    path: '/productDetail/:id',
    Component: ProductDetail,
  },
  {
    path: '/checkout',
    Component: Checkout,
  },
  {
    path: '/login',
    Component: Signup,
  },
  {
    path: '/signup',
    Component: Login,
  },
  {
    path: '/reporting',
    Component: Reporting,
  },
  {
    path: '/game-report/:id',
    Component: GameReport,
  },
  {
    path: '/game-report',
    Component: GameReport,
  },
  // {
  //   path: '/add-game',
  //   Component: AddGame,
  //   isPrivate: true,
  // },
  // {
  //   path: '/add-team',
  //   Component: AddTeam,
  //   isPrivate: true,
  // },
  {
    path: '/coach-signup',
    Component: CoachSignup,
    // isPrivate: true,
  },
  {
    path: '/coach-signin',
    Component: CoachSignin,
    // isPrivate: true,
  },
  {
    path: '/coach-dashboard',
    Component: MainCoachDashboard,
    isPrivate: true,
  },
  {
    path: '/congratulation',
    Component: Congratulation,
  },
]
export default function AppRoutes() {
  return (
    <Routes>
      {routes.map(({ path, Component, isPrivate }) => (
        <Route
          key={path}
          path={path}
          element={
            isPrivate ? (
              <PrivateRoute>
                <Component />
              </PrivateRoute>
            ) : (
              <Component />
            )
          }
        />
      ))}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}
