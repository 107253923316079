import { client } from './api-client'
import { useQuery } from '@tanstack/react-query'
import { GamePlayers } from 'utils/types/home.type'
export const useAllTeamScores = (limit:number,offset:number)=>
useQuery<any,Error>(
    ['getTeamsAllScores'],
    ()=>client(`score/getAllScores?limit=${limit}&offset=${offset}`)
)
export const usePlayerByRanking = ()=>
useQuery<GamePlayers[],Error>(
    ['getGamersByRanking'],
    ()=>client('score/getPlayerRanking')
) 