import axios, { AxiosResponse } from 'axios'

const params = {
  consumer_key: 'ck_ccf9d6d56b765a624afd2d5920bc1aad9f0f1f4a',
  consumer_secret: 'cs_e1c71b8013c8ae60d9241d7bd51ac1c3ad22ab59',
  per_page: 25,
}
export const getAllProducts = async () => {
  const response: AxiosResponse = await axios.get(
    'https://admin.yellasports.com/wp-json/wc/v3/products',
    { params },
  )
  return response.data
}
export const handelGetProducts = (requestEndPoint: string) => {
  return new Promise((resolve, reject) => {
    fetch(`https://admin.yellasports.com/wp-json/wc/v3/${requestEndPoint}`, {
      headers: {
        Authorization:
          'Basic ' +
          btoa(
            'ck_7bbbf9ce12e853413b7241982456d72d2b248ea7:cs_7ca6c0f58e1e4a8a88fd7847ce73283b8a2b212f',
          ),
      },
    })
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw new Error('Error in fetching the data...')
        }
      })
      .then(products => {
        resolve(products)
      })
      .catch(error => {
        reject(error)
      })
  })
}



// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const placeOrder = async (data: any) => {
  const response: AxiosResponse = await axios.post(
    'https://admin.yellasports.com/wp-json/wc/v3/orders',
    data, { params }
  )
  return response.data
}

// export const handelGetProducts =(requestEndPoint:string)=>{
//     return new Promise((resolve,reject)=>{
//     fetch(`https://admin.yellasports.com/wp-json/wc/v3/${requestEndPoint}`, {headers: {'Authorization': 'Basic ' + btoa('ck_7bbbf9ce12e853413b7241982456d72d2b248ea7:cs_7ca6c0f58e1e4a8a88fd7847ce73283b8a2b212f')}}).
//      then((res)=>{
//         if(res.ok)
//         {
//             return res.json()
//         }
//         else
//         {
//             throw new Error ('Error in fetching the data...');
//         }
//      } ).
//      then((products)=>{resolve(products)}).
//      catch((error)=>{reject(error)})

//     })
// }
