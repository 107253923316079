/* eslint-disable @typescript-eslint/no-explicit-any */
import { client } from './api-client'
import { useQuery,useQueryClient,useMutation } from '@tanstack/react-query'
import { ScoringTeam } from 'utils/types/scoreReporting.Type'
export const getAllTeams =(limit:number,offset:number)=>{
return useQuery<ScoringTeam[],Error>(
    ['getAllTeams'],
    ()=>client(`team/getAllTeams?limit=${limit}&offset=${offset}`),
    )
}
export const useAddTeam = ()=>{
    const queryClient = useQueryClient()
      return useMutation<ScoringTeam[], Error, any>((data: any)=>
       client('team/addTeams',{data}),
         {
             onSuccess(){
                 queryClient.invalidateQueries(["getAllTeams"])
             }
         }
    )
}
export const useUpdateTeam = () =>{
    const queryClient = useQueryClient()
    return useMutation<ScoringTeam[], Error, any>((data: { id: any; payload: any })=>
     client(`team/updateTeam/${data?.id}`, {data:data?.payload}),
       {
           onSuccess(){
               queryClient.invalidateQueries(["getAllTeams"])
           }
       }
  )
}

export const useTeamUpdate =()=>{
    const queryClient = useQueryClient();
    return useMutation<ScoringTeam[],Error,any>((data: { id: any; payload: any })=>
    client(`team/updateTeam/${data.id}`,{data:data.payload}),
    {
        onSuccess(){
            queryClient.invalidateQueries(["getAllTeams"])
        }
    }
    )
}

export const useDeleteTeam = ()=>{
    const queryClient = useQueryClient()
    return useMutation<ScoringTeam[], Error, any>((id: any)=>
     client(`team/deleteTeam/${id}`, {data: {}}),
       {
           onSuccess(){
               queryClient.invalidateQueries(["getAllTeams"])
           }
       }
  )
}
