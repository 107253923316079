import { useQuery
  ,useMutation,useQueryClient
 } from "@tanstack/react-query";
import { client } from "./api-client";
import { IProfile } from "utils/types/profile.type";
export const useProfile = (id?: string) =>{
 return  useQuery<IProfile, Error>(['getProfile', id], 
  () =>  client(id ? `user/getUserById/${id}` : `user/getLoggedInUser`)
  )
}
export const useGetUserGame = (id:string | undefined,offset:number,limit:number) =>
useQuery<unknown, Error>(['useGame'],() =>
client(`live-game/getUserGames/${id}?limit=${limit}&offset=${offset}`))

export const useGetUserGames = (id:string | undefined , limit:number, offset:number)=>
  useQuery<any,Error>(['getUserGames',id],()=>
client(`live-game/getUserGames/${id}?limit=${limit}&offset=${offset}`)
)
export const UsePlayerRankingById = (id:any) =>
useQuery<any,Error>(
    ['getPlayerRankingById', id],
    ()=>client(`score/getPlayerRankingById/${id}`)



    
)
export const useUpdateDigitalDollars = () =>{
   const queryClient = useQueryClient();
   return useMutation<any>((data:any) =>
    client(`user/deductDigitalDollar/${data?.rdd}`,{
      data:data?.userData
    }),
    {
      onSuccess(){
        queryClient.invalidateQueries(['getProfile'])
      }
    }
    )
}