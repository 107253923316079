import React, { useEffect } from 'react'
import country from 'assets/img/usa-flag.svg'
// import gameThumbnail from 'assets/img/post-1.jpg'
import {
  useProfile,
  // useGetUserGames
} from 'utils/apis/profile'
import { UsePlayerRankingById } from 'utils/apis/profile'
import { Link, useParams } from 'react-router-dom'
import { getAllTeams } from 'utils/apis/score-reporting'
import { useIsLogin } from 'hooks'
export default function StudentProfile() {
  const { id } = useParams()
  const { data: profileData } = useProfile(id)
  const isLogin = useIsLogin()
  const { data: loginUserRanking } = UsePlayerRankingById(
    profileData?.id && profileData?.id,
  )
  // const { data: userGames } = useGetUserGames(profileData?.id, 10, 0)
  const { data: allTeams } = getAllTeams(20, 0)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <section className="team-details">
        <div className="page-header-shape">
          <div className="shape"></div>
          <div className="shape center"></div>
          <div className="shape center back"></div>
          <div className="shape right"></div>
        </div>
        <div className="container">
          <div className="page-header-info player-details  position-relative">
            <div className="player-thumb">
              <img src={profileData?.profilePicURL} className="profile-image" />
            </div>
            { (isLogin && !id) && <button className="match-category">
              <Link to="/edit-profile">Edit Profile</Link>
            </button>}
            <h3 className="mb-1">{profileData?.name}</h3>
            <p className="mb-2">Level : {profileData?.playerLevel}</p>
            <p>{profileData?.skillset}</p>
            <div className="align-items-center  justify-content-center pt-3">
              <div>
                <span className="fs-4 px-2 mb-2">
                  {profileData?.team?.name}
                </span>{' '}
                | <span className="fs-4 px-2 mb-2">{profileData?.school}</span>
              </div>
              {allTeams
                ?.filter(item => item.id === profileData?.teamId)
                .map(item => {
                  return (
                    <img
                      key={item?.id}
                      className=" mt-2 m-0 p-0  team-logo"
                      src={item?.logoUrl}
                    />
                  )
                })}
            </div>
          </div>
        </div>
      </section>

      <div className="team-details-info player-details ">
        <div className="container">
          <div className="team-details-wrap d-flex align-items-center justify-content-between">
            <div
              className="player-team d-flex flex-column"
              style={{ marginRight: '15px' }}
            >
              <h3>
                DD Earned : <span>${profileData?.digitalDollars}</span>
              </h3>
              <h3>
                DD Remaining :{' '}
                <span>${profileData?.digitalDollarsBalance}</span>
              </h3>
              <h3 className="d-none">
                DD Spent : <span>${profileData?.digitalDollarsSpent}</span>
              </h3>
            </div>
            <ul
              className="social-list border border-0 border-success"
              style={{ visibility: 'visible' }}
            >
              <li>Follow Me:</li>
              <li>
                <Link
                  target={'_blank'}
                  to={profileData && profileData?.youtubeLinkURL}
                >
                  <i className="fab fa-youtube"></i>
                </Link>
              </li>
              <li>
                <Link
                  target={'_blank'}
                  to={profileData && profileData?.twitchLinkURL}
                >
                  <i className="fab fa-twitch"></i>
                </Link>
              </li>
              <li className="d-none">
                <a href="#">
                  <i className="fab fa-youtube"></i>
                </a>
              </li>
            </ul>
            <ul className="player-info">
              <li>
                <div style={{ visibility: 'hidden' }}>
                  <img className="flag" src={country} />{' '}
                  <span>{profileData?.nationality}</span>
                </div>
                <h4 style={{ visibility: 'hidden' }}>Nationality</h4>
              </li>
              <li style={{ visibility: 'hidden' }}>
                <div>
                  <i className="las la-user"></i>
                  <span>Age</span>
                </div>
                <h4>{profileData?.age} Years</h4>
              </li>
              <li>
                <div>
                  <i className="las la-calendar"></i>
                  <span>Join</span>
                </div>
                <h4>{profileData?.createdAt.split('T')[0]}</h4>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* Game Stats starts */}

      <div className="section-heading text-center mt-40 ">
        <div className="container">
          <div className="badges d-flex justify-content-center flex-wrap">
            {profileData?.badges.map((item, index) => {
              return (
                <div key={index} className="">
                  <div className="border border-0 border-success">
                    <img
                      className="img-fluid"
                      src={item?.image}
                      style={{
                        width: '150px',
                        height: '150px',
                        objectFit: 'cover',
                      }}
                    />
                    <h3>{item?.name}</h3>
                    <h6 className="fw-bold">X {item?.count}</h6>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="container Stats border border-0 border-success">
        <div className="section-heading text-center mt-40 ">
          <h2>
            Gamer <span className="purps">Stats</span>
          </h2>
        </div>
        {loginUserRanking?.length > 0 ? (
          loginUserRanking &&
          loginUserRanking.map((item: any) => {
            return item?.ranking?.length > 0 ? (
              <div
                key={item.id}
                className="container border border-0 border-danger"
              >
                <h3 className="purp">{item?.name}</h3>
                <div className="table-responsive">
                  <table className="table bg-gradient rounded border border-2 table-bordered">
                    <thead>
                      <tr className="">
                        {item?.ranking.map((item: any) => {
                          return Object.keys(item?.updatedPoint).map(
                            (item2: any, index: number) => {
                              return index > 0 && item2 != 'Avg' ? (
                                <th key={item2} className="text-white">
                                  {item2}
                                </th>
                              ) : null
                            },
                          )
                        })}
                        {/* {item?.resultsKeys?.map((item: any) => (
                          <th key={item} className="text-white">
                            {item}
                          </th>
                        ))} */}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {item?.ranking.map((item: any) => {
                          return Object.keys(item?.updatedPoint).map(
                            (item2: any, index: number) => {
                              return index > 0 && item2 != 'Avg' ? (
                                <td key={item2} className="text-white">
                                  {item?.updatedPoint[item2]}
                                </td>
                              ) : null
                            },
                          )
                        })}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null
          })
        ) : (
          <div className="p-5 container d-flex align-items-center justify-content-center">
            <h1>No Stats are avilable now</h1>
          </div>
        )}
        <div className="pt-3 ">
          <div className="d-flex flex-row justify-content-around flex-wrap">
            {profileData && profileData?.gamerRating ? (
              <div>
                <h3 className="purp">2K GAMER RATING</h3>
                <h1> {profileData?.gamerRating?.toFixed(2)}</h1>
              </div>
            ) : null}
            <div>
              <h3 className="purp">Total Game Played</h3>
              <h1 className="text-center">{profileData?.totalPlayed}</h1>
            </div>
            <div>
              <h3 className="purp">Total Wins</h3>
              <h1 className="text-center">{profileData?.totalWins}</h1>
            </div>
            <div>
              <h3 className="purp">WIN PERCENTAGE</h3>
              <h1>
                {profileData?.winPercentage} <span>%</span>
              </h1>
            </div>
          </div>
        </div>
      </div>
      {/* Game Stats ends */}

      <section className="latest-gameplay py-5">
        <div className="container">
          <div className="section-heading text-center mb-40">
            <h2>
              My <span> Highlights</span>
            </h2>
          </div>
          <div className="row border border-0 border-success">
            {profileData?.highlights?.length ? (
              profileData?.highlights.map(item => {
                return (
                  <div
                    key={item}
                    className="col-lg-4 col-md-6 sm-padding border border-0 border-danger"
                  >
                    <div className="gameplay-card">
                      {/* <img src={gameThumbnail} alt="thumb" /> */}
                      <iframe
                        style={{ width: '100%', minHeight: '270px' }}
                        src={item
                          ?.replace('watch', 'embed')
                          ?.replace('?v=', '/')}
                      />
                      <a
                        data-autoplay="true"
                        data-vbtype="video"
                        href={item}
                        target="_blank"
                        rel="noreferrer"
                        className="dl-video-popup play-btn"
                      >
                        <i className="las la-play"></i>
                      </a>
                      {/* <div className="gameplay-info">
                        <ul className="post-meta">
                          <li>
                            <i className="las la-calendar"></i>
                            {moment(item?.createdAt.split('T')[0]).format(
                              'Do MMMM YYYY',
                            )}
                          </li>
                        </ul>
                        <h2>{item?.name}</h2>
                      </div> */}
                    </div>
                  </div>
                )
              })
            ) : (
              <h1 className="text-center">No Games received yet</h1>
            )}
          </div>
        </div>
      </section>
    </>
  )
}
