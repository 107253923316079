import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useState } from 'react'
import { useAllTeamScores } from 'utils/apis/home'
import { Link } from 'react-router-dom'
export default function CoachScoreReports() {
  const [show, setShow] = useState(false)
  const { data: TeamsAllScores } = useAllTeamScores(10, 0)
  const [retportName, setretportName] = useState('')
  const handleClose = () => setShow(false)
  // const handleShow = () => setShow(true)
  return (
    <>
      <div className="container">
        <div className="section-heading text-center py-5 ">
          <h2>
            Score <span>Reporting</span>
          </h2>
        </div>
      </div>
      <div className="container ">
        <div className="crud shadow-lg p-3 mb-5 bg-gradient rounded">
          <div className="row ">
            <div className="col-sm-3  mb-4 text-gred">
              <div className="search">
                <form className="form-inline">
                  <input
                    className="form-control bg-grad mr-sm-2"
                    type="search"
                    placeholder="Search by game title"
                    aria-label="Search"
                    onChange={(e: any) => {
                      setretportName(e?.target?.value)
                    }}
                  />
                </form>
              </div>
            </div>
            <div
              className="col-sm-3 offset-sm-2  mb-4 text-gred"
              style={{ color: 'green' }}
            >
              <p className="text-white"></p>
            </div>
          </div>
          <div className="row">
            <div className="table-responsive ">
              <table className="table  bg-gradient text-white">
                <thead>
                  <tr>
                    <th>Game Title</th>
                    <th>Teams</th>
                    <th>Final Scores</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {TeamsAllScores?.filter((item: any) => {
                    return retportName.toLowerCase() === ''
                      ? item
                      : item?.league?.name.toLowerCase().includes(retportName)
                  }).map((item: any) => {
                    return (
                      <tr key={item.id}>
                        <td className="text-white">
                          {item?.league?.name}
                          {/* Game Coached by : <span>Coach Rowan</span> */}
                        </td>
                        <td>
                          <p>
                            <span>
                              {item?.team1?.name || item?.player1?.name}
                            </span>{' '}
                            vs{' '}
                            <span>
                              {item?.team2?.name || item?.player2?.name}
                            </span>
                          </p>
                        </td>
                        <td>
                          <p>
                            <span>{item?.team1Points}</span> -{' '}
                            <span>{item?.team2Points}</span>
                          </p>
                        </td>
                        <td>
                          <a
                            className="edit"
                            title="View"
                            data-toggle="tooltip"
                          >
                            <Link to={`/game-report/${item.id}`}>
                              <i className="material-icons">&#xE417;</i>
                            </Link>
                          </a>
                          {/* <a
                            href="#"
                            className="edit"
                            title="Edit"
                            data-toggle="tooltip"
                          >
                            <i className="material-icons" onClick={handleShow}>
                              &#xE254;
                            </i>
                          </a> */}
                          <a
                            className="delete d-none"
                            title="Delete"
                            data-toggle="tooltip"
                          >
                            <i className="material-icons">&#xE872;</i>
                          </a>
                        </td>
                      </tr>
                    )
                  })}
                  <tr className="d-none">
                    <td className="text-white">
                      Game Coached by : <span>Coach Rowan</span>
                    </td>
                    <td>
                      <a
                        href="#"
                        className="edit d-none"
                        title="View"
                        data-toggle="tooltip"
                      >
                        <i className="material-icons">&#xE417;</i>
                      </a>
                      {/* <a
                        href="#"
                        className="edit"
                        title="Edit"
                        data-toggle="tooltip"
                      >
                        <i className="material-icons" onClick={handleShow}>
                          &#xE254;
                        </i>
                      </a> */}
                      <a
                        className="delete"
                        title="Delete"
                        data-toggle="tooltip"
                      >
                        <i className="material-icons">&#xE872;</i>
                      </a>
                    </td>
                  </tr>
                  <tr className="d-none">
                    <td className="text-white">
                      Game Coached by : <span>Coach BOB</span>
                    </td>
                    <td>
                      <a
                        href="#"
                        className="edit"
                        title="View"
                        data-toggle="tooltip"
                      >
                        <i className="material-icons">&#xE417;</i>
                      </a>
                      {/* <a
                        href="#"
                        className="edit"
                        title="Edit"
                        data-toggle="tooltip"
                      >
                        <i className="material-icons" onClick={handleShow}>
                          &#xE254;
                        </i>
                      </a> */}
                      <a
                        className="delete"
                        title="Delete"
                        data-toggle="tooltip"
                      >
                        <i className="material-icons">&#xE872;</i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* <!--- Model Box ---> */}
          <div className="model_box">
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              className="bg-gradient"
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Team Data</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <div className="form-group bg-gradient">
                    <input
                      type="text"
                      className="form-control bg-gradient"
                      id="teamName"
                      placeholder="Team Name"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      id="teameMode"
                      placeholder="Team Mode"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      id="teamLeague"
                      placeholder="Team League"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <input
                      type="file"
                      className="form-control"
                      id="teamLogo"
                      placeholder="upload Team logo"
                    />
                  </div>

                  <button type="submit" className="btn btn-success mt-4">
                    Update
                  </button>
                </form>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            {/* Model Box Finsihs */}
          </div>
        </div>
      </div>
    </>
  )
}
