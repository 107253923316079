import React, { useState } from 'react'
import AddTeam from 'views/dashboard/adminAddTeam'
import AddGame from 'views/dashboard/adminAddGame'
import AllPlayers from 'views/dashboard/adminAllPlayers'
import CoachScoreReports from 'views/dashboard/adminScoreReport'
import AddLeague from 'views/dashboard/adminAddLeague'
import { Fragment } from 'react'
export default function CoachDashboard() {
  const [state, setState] = useState('team');

  return (
   <Fragment>
      <div className="container mx-auto">
        <div className="row mt-5">
          <div className="col-12 col-md-2 left-section border bd-grad p-2 dashboard-content bg-grad shadow-lg">
            <h3>Admin Dashbaord</h3>
            <ul className='cursorHand'>
              <li className="py-2">
                <a className={state==='team'?'fw-bold shadow':''} onClick={() => setState('team')}>Teams</a>
              </li>
              <li className="py-2">
                <a className={state==='scoreReporting'?'fw-bold shadow':''} onClick={() => setState('scoreReporting')}>
                  Score Reporting
                </a>
              </li>
              <li className="py-2">
                <a className={state==='allPlayer'?'fw-bold shadow':''} onClick={() => setState('allPlayer')}>Players</a>
              </li>
              <li className="py-2 d-none">
                <a className={state==='league'?'fw-bold shadow':''} onClick={() => setState('league')}>League</a>
              </li>
              <li className="py-2 d-none">
                <a className={state==='addGame'?'fw-bold shadow':''} onClick={() => setState('addGame')}>Games</a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-10 right-section p-2  right-dashboard">
            {/* {team === 'team' ? ( */}
            {state === 'team' && (
              <>
                <AddTeam />
              </>
            )}
            {state === 'allPlayer' && (
              <>
                <AllPlayers />
              </>
            )}
            {state === 'addGame' && (
              <>
                <AddGame />
              </>
            )}
            {state === 'league' && (
              <>
                <AddLeague />
              </>
            )}
            {state === 'scoreReporting' && (
              <>
                <CoachScoreReports />
              </>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  )
}
