import React from 'react'
import logo from 'assets/img/logo.png'
import { Link } from 'react-router-dom'
import { useIsLogin } from 'hooks'
import { useNavigate, useLocation } from 'react-router-dom'
import { BsCartPlus } from 'react-icons/bs'
export default function Header() {
  const navigate = useNavigate()
  const location = useLocation()
  const { isLogin, role } = useIsLogin()
  const handlelogoutUser = () => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('role')
    navigate('/')
  }
  return (
    <>
      <header className="header">
        <div className="primary-header">
          <div className="container">
            <div className="primary-header-inner">
              <div className="header-logo">
                <Link to="/">
                  <img className="logo" src={logo} />
                </Link>
              </div>
              <div className="header-menu-wrap">
                <ul className="nav-menu">
                  <li
                    className={location?.pathname?.length == 1 ? 'active' : ''}
                  >
                    <Link to="/"> Home</Link>
                  </li>
                  {isLogin && (role === 'Admin' || role === 'coach') ? (
                    <li
                      className={
                        location?.pathname.includes('play-now') ? 'active' : ''
                      }
                    >
                      <Link to="/play-now"> Play Now</Link>
                    </li>
                  ) : null}
                  <li
                    className={
                      location?.pathname.includes('ranking') ? 'active' : ''
                    }
                  >
                    <Link to="/ranking">Ranking</Link>
                  </li>
                  <li
                    className={
                      location?.pathname.includes('live-games') ? 'active' : ''
                    }
                  >
                    <Link to="/live-games">Yellas TV</Link>
                  </li>
                  <li
                    className={
                      location?.pathname.includes('schedule') ? 'active' : ''
                    }
                  >
                    <Link to="/about">About US</Link>
                  </li>
                  <li
                    className={
                      location?.pathname.includes('eStore') ? 'active' : ''
                    }
                  >
                    <Link to="/eStore">E-Store</Link>
                  </li>
                  {/* {
                    isLogin?
                  <li  className={location?.pathname.includes('cart')?'active':''}>
                    <Link to="/addcart">Cart</Link>
                  </li>:null
                  } */}
                  {role == 'Admin' ? (
                    <>
                      <li
                        className={
                          location?.pathname.includes('add-team')
                            ? 'active d-none'
                            : 'd-none'
                        }
                      >
                        <Link to="/add-team">Add Game</Link>
                      </li>
                      <li
                        className={
                          location?.pathname.includes('add-game')
                            ? 'active d-none'
                            : 'd-none'
                        }
                      >
                        <Link to="/add-game">Add Team </Link>
                      </li>
                    </>
                  ) : null}
                  {isLogin && role !== 'Admin' && role !== 'coach' ? (
                    <li
                      className={
                        location?.pathname === '/profile' ? 'active' : ''
                      }
                    >
                      <Link to="/profile">My Profile</Link>
                    </li>
                  ) : null}
                  {isLogin && role == 'Admin' ? (
                    <>
                      <li
                        className={
                          location?.pathname.includes('dashboard')
                            ? 'active'
                            : ''
                        }
                      >
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                    </>
                  ) : null}
                  {isLogin && role == 'coach' ? (
                    <>
                      <li
                        className={
                          location?.pathname.includes('dashboard')
                            ? 'active'
                            : ''
                        }
                      >
                        <Link to="/coach-dashboard">Dashboard</Link>
                      </li>
                    </>
                  ) : null}
                  <li>
                    {isLogin ? (
                      <button
                        onClick={handlelogoutUser}
                        className="match-category"
                      >
                        Logout
                      </button>
                    ) : (
                      <Link to="/login">Login</Link>
                    )}
                    {!isLogin && (
                      <ul>
                        <li>
                          <Link to="/signup">Register as a Student</Link>
                        </li>
                        <li>
                          <Link to="/coach-signup">Register as a Coach</Link>
                        </li>
                      </ul>
                    )}
                  </li>
                </ul>
              </div>
              <div className="header-right">
                {/* <div className="search-icon dl-search-icon">
                  <i className="las la-search"></i>
                </div> */}
                {isLogin ? (
                  <Link to="/addcart">
                    <BsCartPlus />
                  </Link>
                ) : null}

                <div className="mobile-menu-icon">
                  <div className="burger-menu">
                    <div className="line-menu line-half first-line"></div>
                    <div className="line-menu"></div>
                    <div className="line-menu line-half last-line"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <div id="popup-search-box">
        <div className="box-inner-wrap d-flex align-items-center">
          <form id="form" action="#" method="get" role="search">
            <input
              id="popup-search"
              type="text"
              name="s"
              placeholder="Type keywords here..."
            />
            <button id="popup-search-button" type="submit" name="submit">
              <i className="las la-search"></i>
            </button>
          </form>
        </div>
      </div> */}
    </>
  )
}
