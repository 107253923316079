import React, { useEffect } from 'react'
import { useForm, SubmitHandler, useFieldArray } from 'react-hook-form'
import { useUploadFile } from 'utils/apis/signup'
import { useNavigate } from 'react-router-dom'
import { addScore } from 'utils/apis/ranking'
import { FormData } from 'utils/types/scoreReporting.Type'
import { getAllTeams } from 'utils/apis/score-reporting'
import { getAllLeagues } from 'utils/apis/Leagues'
import { useGetTeamOnePlayers, useTeamTwoPlayers } from 'utils/apis/teams'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
export default function TeamReporting() {
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      team1Players: [],
      team2Players: [],
      team1Result: 'win',
      team2Result: 'lose',
    },
  })

  const { data: getTeam } = getAllTeams(10, 0) // for getting all the teams
  const { data: getAllgames } = getAllLeagues(10, 0) /// for getting all the games
  const team1Id = watch('team1Id')
  const team2Id = watch('team2Id')
  const teamOneWin = watch('team1Result')
  const teamTwoWin = watch('team2Result')
  const { isFetching: isUpdating1Players, data: teamOnePlayers } =
    useGetTeamOnePlayers(team1Id)
  const { isFetching: isUpdating2Players, data: teamTwoPlayers } =
    useTeamTwoPlayers(team2Id)

  const {
    mutate: submitGameScore,
    isSuccess: scoreSubmited,
    isLoading: submitingScore,
    data: scoreData,
  } = addScore()
  const {
    mutate: uploadFile,
    data: imageData,
    isSuccess: imageUploaded,
    isLoading: uploadingImage,
  } = useUploadFile() //  this is the custom hook for uploading the file

  const selectedGame = watch('selectedGame')
  const {
    fields: team1Players,
    append: appendPlayerResult1,
    remove: removePlayerResult1,
  } = useFieldArray({
    control,
    name: 'team1Players',
  })
  const {
    fields: team2Players,
    append: appendPlayerResult2,
    remove: removePlayerResult2,
  } = useFieldArray({
    control,
    name: 'team2Players',
  })
  const handleUploadMedia = (e: any) => {
    uploadFile({
      folderName: 'TeamScore',
      field: 'TeamsScorePic',
      file: e.target.files[0],
    })
  }
  const onSubmit: SubmitHandler<FormData> = () => {
    // check that weather the image is uploded if so then check weather the link is a valid youtube or twitch
    if (imageUploaded) {
      sendTeamsData()
    }
  }
  const sendTeamsData = () => {
    const data = getValues()
    const selectedGameId = getAllgames?.filter(
      item => item.name == data.selectedGame,
    )

    const userOneId = teamOnePlayers?.filter(
      player => player.name === data.team1Players[0].player,
    )?.[0]?.id
    const userTwoId = teamTwoPlayers?.filter(
      player => player.name === data.team2Players[0].player,
    )?.[0]?.id

    if (!userOneId || !userTwoId) {
      return toast.error(`Select Team ${!userTwoId ? '2' : '1'} player`, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }

    const teamOneObject = {
      id: userOneId,
      points: data.team1Players[0],
    }
    const teamTwoObject = {
      id: userTwoId,
      points: data.team2Players[0],
    }
    const teamOneArray = []
    const teamTwoArray = []
    teamOneArray.push(teamOneObject)
    teamTwoArray.push(teamTwoObject)
    const payload = {
      ...data,
      team1Id: team1Id,
      team2Id: team2Id,
      imageUrl: imageData?.url,
      team1Players: teamOneArray,
      team2Players: teamTwoArray,
      leagueId: selectedGameId && selectedGameId[0].id,
    }
    ChangeData(payload)
  }

  useEffect(() => {
    if (team1Players?.length || team2Players?.length) {
      setValue('team1Players', [])
      setValue('team2Players', [])
    }
  }, [selectedGame])

  useEffect(() => {
    if (getTeam?.length) {
      setValue('team1Id', getTeam?.[0]?.id)
      setValue('team2Id', getTeam?.[1]?.id)
    }
  }, [getTeam])

  useEffect(() => {
    if (scoreSubmited) {
      toast.success('Score report is submited successfully.', {
        position: toast.POSITION.TOP_RIGHT,
      })
      reset({
        team1Id: '',
        team2Id: '',
        team1Result: '',
        team2Result: '',
        videoLink: '',
        gameLink: '',
        team1Points: 0,
        team2Points: 0,
        gameNotes: '',
        imageUrl: '',
        selectedGame: '',
        team1Players: [],
        team2Players: [],
      })
      const id: any = scoreData?.id
      navigate(`/game-report/${id}`)
    }
  }, [scoreSubmited])

  //  function for converting the players points from string to number
  function ChangeData(testDataa: any) {
    const testData = testDataa.team1Players
    const testData2 = testDataa.team2Players
    const newTeamOnePlayersArray: any = []
    const points: any = {}
    const pointsArray = []
    const newTeamOnePlayersArray2: any = []
    const points2: any = {}
    const pointssArray2 = []
    for (let i = 0; i < testData.length; i++) {
      const newPoints = Object.keys(testData[i].points)
      for (let j = 0; j < newPoints.length; j++) {
        if (j === 0) {
          pointsArray[j] = Object.values(testData[i].points)[j]
          points[newPoints[j]?.replace('_', '.')] = pointsArray[j]
        } else {
          const pointsInString: any = Object.values(testData[i].points)[j]
          pointsArray[j] = parseInt(pointsInString)
          points[newPoints[j]?.replace('_', '.')] = pointsArray[j]
        }
      }
      const obj: any = {
        id: testData[i].id,
        points: points,
      }
      newTeamOnePlayersArray.push(obj)
    }
    for (let i = 0; i < testData2.length; i++) {
      const newPoints = Object.keys(testData2[i].points)
      for (let j = 0; j < newPoints.length; j++) {
        if (j === 0) {
          pointssArray2[j] = Object.values(testData2[i].points)[j]
          points2[newPoints[j]?.replace('_', '.')] = pointssArray2[j]
        } else {
          const pointsInString: any = Object.values(testData2[i].points)[j]
          pointssArray2[j] = parseInt(pointsInString)
          points2[newPoints[j]?.replace('_', '.')] = pointssArray2[j]
        }
      }
      const obj2: any = {
        id: testData2[i].id,
        points: points2,
      }
      newTeamOnePlayersArray2.push(obj2)
    }
    const testSample = {
      ...testDataa,
      team1Players: newTeamOnePlayersArray,
      team2Players: newTeamOnePlayersArray2,
    }
    submitGameScore(testSample)
  }

  return (
    <form
      action=""
      method="post"
      id="liveGame"
      className="form-horizontal"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="report-form-group ">
        <div className="d-flex justify-content-between gap-3">
          <div className="form-field w-100 ">
            <label>Select Team 1</label>
            {team1Id && (
              <select
                defaultValue={team1Id}
                className="form-control bd-grad"
                {...register('team1Id', { required: true })}
              >
                {getTeam
                  ?.filter(item => item.id !== getValues('team2Id'))
                  ?.map((item, index) => (
                    <option key={index} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
              </select>
            )}
          </div>
          <div className="form-field w-100">
            <label>Select Team 2 </label>
            {team2Id && (
              <select
                defaultValue={team2Id}
                className="form-control bd-grad"
                {...register('team2Id', { required: true })}
              >
                {getTeam
                  ?.filter(item => item.id !== getValues('team1Id'))
                  .map((item, index) => (
                    <option key={index} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
              </select>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between gap-3">
          <div className="form-field py-2 w-100">
            <label>(Win , Lose, Forfeit) for Team 1</label>
            {teamTwoWin && (
              <select
                className="form-control bd-grad"
                defaultValue="win"
                {...register('team1Result', { required: true })}
              >
                {['Win', 'Lose', 'Forfeit']
                  .filter(item => item.toLowerCase() !== teamTwoWin)
                  .map(item => {
                    return (
                      <option key={item} value={item.toLowerCase()}>
                        {item}
                      </option>
                    )
                  })}
              </select>
            )}
          </div>
          <div className="form-field py-2 w-100 ">
            <label>(Win , Lose, Forfeit) for Team 2</label>
            {teamOneWin && (
              <select
                defaultValue="lose"
                className="form-control bd-grad"
                {...register('team2Result', { required: true })}
              >
                {['Win', 'Lose', 'Forfeit']
                  .filter(item => item.toLowerCase() !== teamOneWin)
                  .map(item => {
                    return (
                      <option key={item} value={item.toLowerCase()}>
                        {item}
                      </option>
                    )
                  })}
              </select>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between gap-3">
          <div className="form-field w-100">
            <label>Total points for team 1</label>
            <input
              type="number"
              id="Team_one_point"
              className="form-control bd-grad"
              placeholder=""
              {...register('team1Points', {
                required: 'Add total points for Team 1',
              })}
            />
            {errors?.team1Points && errors?.team1Points?.message && (
              <p className="error text-danger">
                {errors?.team1Points?.message}
              </p>
            )}
          </div>
          <div className="form-field w-100">
            <label>Total points for team 2</label>
            <input
              type="number"
              id="Team_two_point"
              className="form-control bd-grad"
              placeholder=""
              {...register('team2Points', {
                required: 'Add total points for Team 2',
              })}
            />
            {errors?.team2Points && errors?.team2Points?.message && (
              <p className="error text-danger">
                {errors?.team2Points?.message}
              </p>
            )}
          </div>
        </div>

        <div className="form-field py-2">
          <label>Twitch or YouTube link for Game</label>
          <input
            type="text"
            id="videoLink"
            className="form-control bd-grad"
            placeholder=""
            {...register('videoLink', {
              required: 'Enter valid youtube link',
            })}
          />
          {errors?.videoLink && errors?.videoLink?.message && (
            <p className="error text-danger">{errors?.videoLink?.message}</p>
          )}
        </div>
        <div className="form-field py-2">
          <label className="py-2">
            Final Score Screenshot <br />
            Upload Screenshot of final score on screen
          </label>
          <input
            type="file"
            id="imageUrl"
            className="form-control bd-grad"
            placeholder=""
            {...register('imageUrl')}
            onChange={handleUploadMedia}
          />
        </div>
        <div className="form-field message py-2">
          <textarea
            id="gameNote"
            className="form-control bd-grad"
            placeholder="Game Notes"
            {...register('gameNotes')}
          ></textarea>
        </div>
        <div className="form-field py-2 ">
          <label>Select Game</label>
          <select
            className="form-control bd-grad"
            id="teams"
            {...register('selectedGame', {
              required: 'Please select game',
            })}
          >
            <option value="">Select Game</option>
            {/* these teams should be dynamic (START) */}
            {getAllgames?.map(item => {
              return (
                <option key={item?.name} value={item?.name}>
                  {item?.name}
                </option>
              )
            })}
          </select>
          {errors?.selectedGame && errors?.selectedGame?.message && (
            <p className="error text-danger">{errors?.selectedGame?.message}</p>
          )}
        </div>
        {selectedGame && (
          <div className="scoreTable py-3">
            <p className="text-white">Game result for Team 1</p>
            <div className="table-responsive">
              <table className="table table-bordered bd-grad">
                <thead>
                  <tr>
                    <th className="text-white">Player</th>
                    {getAllgames
                      ?.filter(team => team?.name === selectedGame)[0]
                      ?.resultsKeys?.map(item => (
                        <th key={item} className="text-white">
                          {item}
                        </th>
                      ))}
                    <th className="text-white">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {team1Players?.map((field, index) => {
                    return (
                      <tr key={field.id}>
                        {Object.keys(field)?.map(item => {
                          if (item !== 'id')
                            return (
                              <td key={item} className="text-white">
                                {item === 'player' ? (
                                  !isUpdating1Players && teamOnePlayers?.[0] ? (
                                    <select
                                      {...register(
                                        `team1Players.${index}.${item}`,
                                      )}
                                    >
                                      <option value="" selected disabled hidden>
                                        Select player
                                      </option>
                                      {teamOnePlayers?.map(item =>
                                        item.isCoach === item.isAdmin ? (
                                          <option
                                            key={item?.id}
                                            value={item?.name}
                                          >
                                            {item?.name}
                                          </option>
                                        ) : null,
                                      )}
                                    </select>
                                  ) : (
                                    'No player available'
                                  )
                                ) : (
                                  <input
                                    type="number"
                                    {...register(
                                      `team1Players.${index}.${[item]}`,
                                      { required: true },
                                    )}
                                    placeholder={item
                                      ?.toUpperCase()
                                      ?.replace('_', '.')}
                                  />
                                )}
                              </td>
                            )
                          return null
                        })}
                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={() => removePlayerResult1(index)}
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <button
              type="button"
              className="text-white"
              onClick={() => {
                const values = getAllgames?.filter(
                  team => team?.name === selectedGame,
                )[0]?.resultsKeys
                const newObj = values?.reduce(
                  (obj, cur) => ({
                    ...obj,
                    [cur?.replace('.', '_')]: '',
                  }),
                  { player: '' },
                )
                appendPlayerResult1(newObj)
              }}
            >
              Add player
            </button>
          </div>
        )}
        {/* Selection for the second team Start */}
        {selectedGame && (
          <div className="scoreTable py-3">
            <p className="text-white">Game result for Team 2</p>
            <div className="table-responsive m-0 p-0">
              <table className="table table-bordered bd-grad">
                <thead>
                  <tr>
                    <th className="text-white">Player</th>
                    {getAllgames
                      ?.filter(team => team?.name === selectedGame)[0]
                      ?.resultsKeys?.map(item => (
                        <th key={item} className="text-white">
                          {item}
                        </th>
                      ))}
                    <th className="text-white">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {team2Players.map((field, index) => {
                    return (
                      <tr key={field.id}>
                        {Object.keys(field)?.map(item => {
                          if (item !== 'id')
                            return (
                              <td key={item} className="text-white">
                                {item === 'player' ? (
                                  !isUpdating2Players && teamTwoPlayers?.[0] ? (
                                    <select
                                      {...register(
                                        `team2Players.${index}.${item}`,
                                      )}
                                    >
                                      <option value="" selected disabled hidden>
                                        Select player
                                      </option>
                                      {teamTwoPlayers?.map(item =>
                                        item.isCoach === item.isAdmin ? (
                                          <option
                                            key={item?.id}
                                            value={item?.name}
                                          >
                                            {item?.name}
                                          </option>
                                        ) : null,
                                      )}
                                    </select>
                                  ) : (
                                    'No player available'
                                  )
                                ) : (
                                  <input
                                    type="number"
                                    {...register(
                                      `team2Players.${index}.${item}`,
                                      { required: true },
                                    )}
                                    placeholder={item
                                      ?.toUpperCase()
                                      ?.replace('_', '.')}
                                  />
                                )}
                              </td>
                            )
                          return null
                        })}
                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={() => removePlayerResult2(index)}
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <button
              type="button"
              className="text-white"
              onClick={() => {
                const values = getAllgames?.filter(
                  team => team?.name === selectedGame,
                )[0]?.resultsKeys
                const newObj = values?.reduce(
                  (obj, cur) => ({ ...obj, [cur.replace('.', '_')]: '' }),
                  { player: '' },
                )
                appendPlayerResult2(newObj)
              }}
            >
              Add player
            </button>
          </div>
        )}

        {/* Selection for the second team End */}

        <div className="form-field py-2 text-center">
          {uploadingImage || submitingScore ? (
            <button className="default-btn">
              Submiting Score.....<span></span>
            </button>
          ) : (
            <button id="submit" className="default-btn" type="submit">
              Submit Report<span></span>
            </button>
          )}
        </div>
      </div>
      <div id="form-messages" className="alert" role="alert"></div>
    </form>
  )
}
