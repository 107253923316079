import React,{useEffect} from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useAddGames,  useGetAllGames } from 'utils/apis/live-game';
import { ILiveGame } from 'utils/types/liveGame.type';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function Stream() {
  const {mutate:gameData,isLoading,isSuccess} = useAddGames()
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<ILiveGame>()
  const onSubmit: SubmitHandler<ILiveGame> = (data) =>{
    if(data.gameLink.includes('youtube'))
    {
      gameData(data)
    }
    else{
      toast.error('Please enter the valid youtube link.....', {
        position: toast.POSITION.TOP_RIGHT
       });
    }
  } 
  useEffect(()=>{
    if(isSuccess)
    {
      toast.success('Game Link is added.....', {
        position: toast.POSITION.TOP_RIGHT
       });
      reset({
        name:'',
        gameLink:''
      });
    }
  },[isSuccess])
  return (
    <>
      {/* Game stream Form */}
      {
      localStorage.getItem('access_token')!=undefined?
      <div className="container mb-3">
        <div className="col-md-12 sm-padding py-5">
          <div className="text-center ">
            <h2 className='d-none'>ENTER GAME DETAILs</h2>
            <h2>Yellas TV Live</h2>
            <p>( Enter the names of your game & stream Link )</p>
          </div>
          <div className="contact-form ">
            <form
              action=""
              method="post"
              id="streamListing"
              className="form-horizontal"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="contact-form-group d-flex flex-column">
                <div className="form-field">
                  <input
                    type="text"
                    id="contact_name"
                    className="form-control bd-grad"
                    placeholder="Enter the names of your team / Game title ex: Kings v.s. Monsters / NBA 2k"
                    {...register('name', { required: true })}
                  />
                  {errors.name && 'Title is required'}
                </div>
                <div className="form-field">
                  <input
                    type="text"
                    id="contact_name"
                    className="form-control bd-grad"
                    placeholder="Enter Game Link"
                    {...register('gameLink', { required: true })}
                  />
                  {errors.gameLink && 'Enter Game Link'}
                </div>
                <div className="form-field">
                  {
                    isLoading?
                  <button className="default-btn">
                    Submiting....<span></span>
                  </button>:
                  <button id="submit" className="default-btn" type="submit">
                  Submit<span></span>
                </button>
                
                  }
                </div>
              </div>
              <div id="form-messages" className="alert" role="alert"></div>
            </form>
          </div>
        </div>
      </div>:null
      }

      {/* Game Streams */}
      <section className="latest-matches pb-3">
        <div className="container">
          <div className="text-center py-5">
            <h2 className='d-none'>Live Stream Listing</h2>
            <h2>Yellas TV Live</h2>
            <p>Students, teachers and families can now watch all games live and on demand to support our E-Sport student athletes</p>
          </div>
          <GetAllStreams />
        </div>
      </section>
    </>
  )
}

function GetAllStreams(){
   const {data:allGames} = useGetAllGames(0,10)
  return(
    <>
    {allGames?.map(game=>(
      game?.gameLink.includes('youtube')?
  <div key={game?.id} className="latest-matches-lists border border-0 border-success">
  <div className="latest-matches-list">
    <div className="matches-thumb ">
       <iframe src={(game?.gameLink.replace('watch','embed'))?.replace('?v=','/')}
        frameBorder='0'
        allow='autoplay; encrypted-media'
        allowFullScreen
        title='video'
      />
     </div>
    <div className="latest-match-info">
      {/* <a href="#" className="match-category">
        E-Football
      </a> */}
      <h3>{game?.name}</h3>
      <ul className="match-meta">
        <li>
          <a href="#">{new Date(game?.createdAt).toDateString()} - {new Date(game?.createdAt).toLocaleTimeString([], {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  })}</a>
        </li>
      </ul>
    </div>
    <div className="watch-info">
      <a
        className="dl-video-popup"
        data-autoplay="true"
        data-vbtype="video"
        href={game?.gameLink}
        target="_blank"
        rel="noreferrer"
      >
        <i className="lab la-twitch"></i>Watch Stream
      </a>
    </div>
  </div>
  
 </div>:null
    ))}

    </>
  )
}