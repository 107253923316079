import React from 'react'
import { Modal } from 'react-bootstrap'

export default function DeleteModal (props:any){
    return(
      <div className="model_box">
              <Modal
                show={props?.show}
                onHide={props?.onClose}
                backdrop="static"
                keyboard={false}
                className="bg-gradient"
              >
                <Modal.Header closeButton>
                  <Modal.Title className='text-dark mb-0'>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                 <div className='d-flex flex-column align-items-center justify-content-center m-0 p-3'>
                   <p className='text-center text-dark'>{props?.message}</p>
                   <div className='d-flex align-items-center justify-content-center w-100 gap-4'>
                      <button className='btn btn-danger text-white fs-6 w-25' onClick={props.onDelete}>Delete</button>
                      <button className='btn btn-light text-dark border border-1 fs-6 w-25' onClick={props?.onClose}>Cancel</button>
                   </div>
                 </div>
                </Modal.Body>
      </Modal>
    {/* Model Box Finsihs */}
   </div>
    )
  }
