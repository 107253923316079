import React from 'react'
import logo from 'assets/img/logo.png'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <footer className="footer-section d-none">
      <div className="container">
        <div className="row footer-items">
          <div className="col-lg-3 col-sm-6 sm-padding">
            <div className="footer-item">
              <a className="brand" href="index.html">
                <img src={logo} alt="logo" />
              </a>
              <p>
                Our success in creating business solutions is due in large part
                to our talented and highly committed team.
              </p>
              <ul className="social-list">
                <li>
                  <a href="#">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 sm-padding d-none">
            <div className="footer-item footer-list">
              <div className="widget-title">
                <h3>Useful Links</h3>
              </div>
              <ul className="footer-links">
                <li className='d-none'>
                  <a href="upcoming-matches.html">Tournaments</a>
                </li>
                <li>
                  <Link to="/"> Home</Link>
                </li>
                <li>
                   <Link to="/play-now"> Play Now</Link>
                </li>
                <li>
                   <Link to="/ranking">Ranking</Link>
                </li>
                <li>
                  <Link to="/live-games">Live Games</Link>
                </li>
                <li>
                  <Link to="/eStore">E-Store</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 sm-padding d-none">
            <div className="footer-item">
              <div className="widget-title">
                <h3>Contact Us</h3>
              </div>
              <ul className="footer-contact">
                <li className='d-none'>
                  <span>Location:</span>153 Williamson Plaza, Maggieberg, MT
                  09514
                </li>
                <li>
                  <span>Join Us:</span>Info@YourGmail24.com
                </li>
                <li className='d-none'>
                  <span >Phone:</span>+1 (062) 109-9222
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 sm-padding d-none">
            <div className="footer-item subscribe-wrap">
              <div className="widget-title">
                <h3>Newsletter Signup</h3>
              </div>
              <form action="#" className="subscribe-form">
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  placeholder="Your Email"
                />
                <input type="hidden" name="action" value="mailchimpsubscribe" />
                <button className="submit">Subscribe Now</button>
                <div className="clearfix"></div>
                <div id="subscribe-result">
                  <div className="subscription-success"></div>
                  <div className="subscription-error"></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-wrap">
        <div className="container">
          <p>
            © <span id="currentYear"></span> Yellasports All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}
