import React from 'react'
import { useSpecificCoachPlayers } from 'utils/apis/coach'
export default function CoachPlayers(props:any) {
   const {data:playerData} = useSpecificCoachPlayers(props?.teamId,10,0);
  return (
    <div className="row">
            <div className="table-responsive ">
              <table className="table  bg-gradient text-white">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Positions</th>
                    <th>Total Win</th>
                    <th>Digital dollars</th>
                    <th>Level</th>
                  </tr>
                </thead>
                
                 <tbody>
                  {
                   playerData?.map((item:any)=>{return(
                    <tr key={item.id}>
                     <td>{item?.name}</td>
                     <td>{item?.skillset}</td>
                     <td>{item.totalWins}</td>
                     <td>{item.digitalDollars}</td>
                     <td>{item?.playerLevel}</td>
                    </tr>
                     )})
                  }
                    
                  </tbody>
              </table>
            </div>
          </div>
  )
}
