import React from 'react'
import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

export default function AddLeague() {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  return (
    <>
      <div
        className="section-heading mb-40 text-center wow fade-in-bottom pt-5 pb-2"
        data-wow-delay="200ms"
      >
        <h2>
          ADD <span>Leaugue</span>
        </h2>
      </div>
      <form action="" method="post" id="liveGame" className="form-horizontal">
        <div className="d-flex justify-content-between gap-3 ">
          <div className="form-field w-100">
            <label>Name</label>
            <input
              type="text"
              id="name"
              className="form-control form-input bd-grad text-white"
              placeholder="league Name"
            />
          </div>
          <div className="form-field w-100">
            <label>Season</label>
            <input
              type="text"
              id="season"
              className="form-control form-input bd-grad text-white"
              placeholder="season"
            />
          </div>
        </div>
        <div className="d-flex justify-content-between gap-3 ">
          <div className="form-field w-100">
            <label>Logo URL</label>
            <input
              type="file"
              id="logoUrl"
              className="form-control bd-grad"
              placeholder="league Url"
            />
          </div>
          <div className="form-field w-100">
            <label>Date</label>
            <input
              type="date"
              id="logoUrl"
              className="form-control bd-grad"
              placeholder=""
            />
          </div>
        </div>
        <div className="d-flex justify-content-between gap-3 ">
          <div className="form-field w-50">
            <label>Results Keys - not confirmed</label>
            <input
              type="text"
              id="resultsKeys"
              className="form-control form-input bd-grad text-white"
              placeholder=" "
            />
          </div>
        </div>
        <div className="form-field py-2 text-center mt-3">
          <button id="submit" className="default-btn" type="submit">
            Submit
            <span></span>
          </button>
        </div>
      </form>
      <div className="container ">
        <div className="crud shadow-lg p-3 mb-5 bg-gradient rounded">
          <div className="row ">
            <div className="col-sm-3  mb-4 text-gred">
              <div className="search">
                <form className="form-inline">
                  <input
                    className="form-control mr-sm-2"
                    type="search"
                    placeholder="Search "
                    aria-label="Search"
                  />
                </form>
              </div>
            </div>
            <div
              className="col-sm-3 offset-sm-2  mb-4 text-gred"
              style={{ color: 'green' }}
            >
              <p className="text-white"></p>
            </div>
          </div>
          <div className="row">
            <div className="table-responsive ">
              <table className="table  bg-gradient text-white">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>League</th>
                    <th>Season</th>
                    <th>Teams</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-white">Rocket League</td>
                    <td className="text-white">-</td>
                    <td className="text-white">-</td>
                    <td className="text-white">-</td>
                    <td>
                      <a
                        href="#"
                        className="edit"
                        title="View"
                        data-toggle="tooltip"
                      >
                        <i className="material-icons">&#xE417;</i>
                      </a>
                      <a
                        href="#"
                        className="edit"
                        title="Edit"
                        data-toggle="tooltip"
                      >
                        <i className="material-icons" onClick={handleShow}>
                          &#xE254;
                        </i>
                      </a>
                      <a
                        className="delete"
                        title="Delete"
                        data-toggle="tooltip"
                      >
                        <i className="material-icons">&#xE872;</i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* <!--- Model Box ---> */}
          <div className="model_box">
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              className="bg-gradient"
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Player Data</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Player Name"
                    />
                  </div>

                  <div className="form-group mt-3">
                    <select name="" id="school" className="form-control">
                      <option value="">--School--</option>
                      <option value="urban-dove-team-charter-school">
                        Urban Dove Team Charter School
                      </option>
                      <option value="Varsity-team">Varsity Team</option>
                    </select>
                  </div>
                  <div className="form-group mt-3">
                    <select name="" id="team" className="form-control">
                      <option value="">--Team--</option>
                      <option value="interShool-league">
                        InterShool League
                      </option>
                    </select>
                  </div>
                  <div className="form-group mt-3">
                    <select name="" id="position" className="form-control">
                      <option value="">--Position--</option>
                      <option value="center-forward">Center Forward</option>
                    </select>
                  </div>

                  <button type="submit" className="btn btn-success mt-4">
                    Update
                  </button>
                </form>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            {/* Model Box Finsihs */}
          </div>
        </div>
      </div>
    </>
  )
}
