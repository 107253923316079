/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { client } from './api-client'
export const useAllCoaches = (limit: number, offset: number) =>
  useQuery<any, Error>(['getAllCoaches'], () =>
    client(`user/getAllCoachs?limit=${limit}&offset=${offset}`),
  )
export const useSpecificCoachPlayers = (
  id: string,
  limit: number,
  offset: number,
) =>
  useQuery<any, Error>(['getAllCoachPlayers'], () =>
    client(`user/getPlayersByTeam/${id}?limit=${limit}&offset=${offset}`),
  )

export const useAllPlayers = (
  limit: number,
  offset: number,
) =>
  useQuery<any, Error>(['getAllCoachPlayers'], () =>
    client(`user/getAllPlayers/?limit=${limit}&offset=${offset}`),
  )


export const useAssignBadge = () => {
  return useMutation<any, Error, any>((data: any) =>
    client('user/addBagde', {
      data,
    }),
  )
}
// assign level and update the component
export const useAssignLevel = () => {
  const queryClient = useQueryClient()
  return useMutation<any, Error>(
    (data: any) =>
      client(`user/setLevel/${data.userId}/${data.points}`, {
        data,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries(['getAllCoachPlayers'])
      },
    },
  )
}
export const useGetAllCoachPlayers = (limit: number, offset: number) =>
  useQuery<any, Error>(['getAllCoachesPlayerScores'], () =>
    client(`score/getScoresByCoach?limit=${limit}&offset=${offset}`),
  )
