import React,{useEffect, useState} from 'react'
import { toast } from 'react-toastify';
import { useSpecificCoachPlayers } from 'utils/apis/coach'
import { useAssignLevel } from 'utils/apis/coach';
export default function CoachLevels(props:any) {
    const {data:playerData} = useSpecificCoachPlayers(props?.teamId,10,0);
    const {mutate,isSuccess} = useAssignLevel();
    const [badge, setBadge] = useState('');
    const [level,setLevel] = useState<number>()
    const assignBadge = (event: any) => {
        const setPoints = event.target.value;
        setBadge(setPoints);
    }
    const handleAssignLevel =(e:any)=>{
        const playerLevel = e.target.value;
        setLevel(playerLevel);
    }
    const handleSubmit = ()=>{
         let levelInNumber:any = level;
         levelInNumber=parseInt(levelInNumber)
      const payload:any ={
        points:levelInNumber,
        userId:badge
      }
      mutate(payload);
      
    }
    useEffect(()=>{
      if(isSuccess)
      {
        toast.success('Level is assigned to the player.....', {
            position: toast.POSITION.TOP_RIGHT
           });
      }
      
    },[isSuccess])
  return (
    <>
    <div className="container">
      <div
        className="section-heading mb-40 text-center wow fade-in-bottom pt-5 pb-2"
        data-wow-delay="200ms"
      >
        <h2>
          Assign <span>Levels</span>
        </h2>
        <p>Select Player to Assign Levels</p>
      </div>
    </div>
    <select
      className="form-control form-input bd-grad text-white my-3 w-25 mx-auto"
      onChange={e => assignBadge(e)}
    >
      <option>--Select Players--</option>
      {
        playerData?.map((item:any)=>{return(
          <option key={item.id} value={item?.id}>{item?.name}</option>
        )})
      }
    </select>
    {badge?.length>1 && (
      <div className="container">
      
          <div className="row">
            <div className="d-flex flex-column align-items-center justify-content-center gap-3">
                  <select
                  
                  className="form-control form-input bd-grad text-white my-3 w-25 mx-auto"
                  onChange={handleAssignLevel}
                  >
                  <option>--Select Player Level--</option>
                  {
                    [1,2,3,4,5].map((item:any)=>{return(
                      <option  key={item} value={item}>{item}</option>
                    )})
                  }
                  </select>
                  <button onClick={handleSubmit} className="default-btn mt-2">
                    Submit Level
                  </button>
            </div>
          </div>
         
          
      </div>
    )}
  </>
  )
}

// badgesName.map((item,index)=>{return(
//     <div key={index} className="form-field text-center">
//     <input
//       type="number"
//       id="name"
//       name=""
//       className="form-control form-input bd-grad text-white"
//       placeholder=" Points"
//       defaultValue={item.points}
//       onChange={(e)=>{handleBadgePoints(e,index)}}
//     />