import React, { useState, useEffect } from 'react'
import banner from 'assets/img/game-charecters.png'
import AdminPic from 'assets/img/adminPic.png'
import Team1 from 'assets/img/team-img-7.png'
import Team2 from 'assets/img/team-img-3.png'
import Team3 from 'assets/img/team-img-6.png'
import Team4 from 'assets/img/team-img-7.png'
import clientOne from 'assets/img/client-1.png'
import clientTwo from 'assets/img/client-2.png'
import clientThree from 'assets/img/client-3.png'
import clientFour from 'assets/img/client-4.png'
import clientFive from 'assets/img/client-5.png'
import clientSix from 'assets/img/client-6.png'
import product from 'assets/img/product-1.png'
// import blog from 'assets/img/post-1.jpg'
// import blog2 from 'assets/img/blog2.jpg'
// import blog3 from 'assets/img/blog3.jpg'
import team from 'assets/img/team-2.png'
import AvatarImg from 'assets/img/avatar.png'

import { useAllTeamScores } from 'utils/apis/home'
import { Link } from 'react-router-dom'
import G1 from 'assets/img/Game1.jpeg'
import G2 from 'assets/img/Game2.jpeg'
import G3 from 'assets/img/Game3.jpeg'
import ReactLoading from 'react-loading'
import { getAllTeams } from 'utils/apis/score-reporting'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import { handelGetProducts } from 'utils/helpers'
import { usePlayerByRanking } from 'utils/apis/home'
import { Autoplay, EffectCoverflow } from 'swiper'
import moment from 'moment'
import StarRating from 'components/starRating'
import { GamePlayers } from 'utils/types/home.type'
import { UseSendMessage } from 'utils/apis/sendMessage'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
export default function Home() {
  const [restMessage, setrestMessage] = useState(false)
  const { data: TeamsAllScores } = useAllTeamScores(10, 0)
  const { data: gamersByRanking } = usePlayerByRanking()
  const [linkType, setlinkType] = useState('Link')
  const [name, setname] = useState('')
  const [email, setemail] = useState('')
  const [message, setmessage] = useState('')
  const { mutate: sendMessage } = UseSendMessage()
  const { data: allTeams } = getAllTeams(10, 0)
  const [productArray, setproductArray]: any[] = useState() //for gettting the e-store products
  const handleSendMessage = () => {
    const data: any = {
      name: name,
      email: email,
      message: message,
    }
    const validateEmail = /\S+@\S+\.\S+/
    if (!validateEmail.test(data.email)) {
      toast.error('Please provide a valid email.....', {
        position: toast.POSITION.TOP_RIGHT,
      })
    } else {
      sendMessage(data)
      setTimeout(() => {
        toast.success('Message has been sent.....', {
          position: toast.POSITION.TOP_RIGHT,
        })
        setrestMessage(!restMessage)
      }, 1500)
    }
  }
  const handelProducts = async (endPoint: string) => {
    switch (endPoint) {
      case 'products': {
        const req: any = await handelGetProducts(endPoint)
        const res = await req
        setproductArray(res)
        break
      }

      default:
        break
    }
  }
  useEffect(() => {
    handelProducts('products')
    window.scrollTo(0, 0)
  }, [restMessage])
  return (
    <>
      <section className="hero-section">
        <div className="container">
          <div className="hero-content">
            <h3>STEM.org Accredited</h3>
            <h1 className="mb-5">
              The #1 E-Sports league & Sports Broadcasting Program for Students
            </h1>
            {/* <p className="my-3 fs-5">#WeAreStudentAthletes</p>
            <p className="mb-4 fs-4">WELCOME TO SEASON THREE!</p> */}
            <div className="btn-group">
              <a href="contact.html" className="default-btn d-none">
                Join With Us
              </a>
              <a
                data-autoplay="true"
                data-vbtype="video"
                href="https://youtu.be/14kbI4o64qE"
                className="dl-video-popup play-btn"
              >
                <svg
                  enableBackground="new 0 0 41.999 41.999"
                  version="1.1"
                  viewBox="0 0 41.999 41.999"
                  xmlSpace="preserve"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M36.068,20.176l-29-20C6.761-0.035,6.363-0.057,6.035,0.114C5.706,0.287,5.5,0.627,5.5,0.999v40  c0,0.372,0.206,0.713,0.535,0.886c0.146,0.076,0.306,0.114,0.465,0.114c0.199,0,0.397-0.06,0.568-0.177l29-20  c0.271-0.187,0.432-0.494,0.432-0.823S36.338,20.363,36.068,20.176z M7.5,39.095V2.904l26.239,18.096L7.5,39.095z" />
                </svg>
                <div className="ripple"></div>
              </a>
            </div>
            {/* Slider should be place here  */}
            <div className="hero-element border border-0 border-success pb-5">
              <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={false}
                slidesPerView={'auto'}
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: true,
                }}
                autoplay={true}
                pagination={false}
                modules={[EffectCoverflow, Autoplay]}
                className="mySwiper border border-0 border-danger mb-3"
                style={{ maxHeight: '600px' }}
              >
                <SwiperSlide>
                  <img className="img-fluid" src={G1} />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="img-fluid" src={G2} />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="img-fluid" src={G3} />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="img-fluid" src={G1} />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="img-fluid" src={G2} />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="img-fluid" src={G3} />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="img-fluid" src={G2} />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="img-fluid" src={G3} />
                </SwiperSlide>
              </Swiper>
            </div>
            {/* Slider should be place here  */}
            <div className="hero-element border border-0 border-success d-none">
              <img
                className="border border-0 border-success"
                src={banner}
              ></img>
              <div className="shape-wrap border border-0 border-danger">
                <div className="shape shape-1 border border-0 border-dark"></div>
                <div className="shape shape-2 border border-0 border-dark"></div>
                <div className="shape shape-3 border border-0 border-dark"></div>
                <div className="shape shape-4 border border-0 border-dark"></div>
              </div>
              <div className="shape-wrap right border border-0 border-warning">
                <div className="shape shape-1"></div>
                <div className="shape shape-2"></div>
                <div className="shape shape-3"></div>
                <div className="shape shape-4"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="gradiant-border"></div>
      </section>
      {/* // Hero Ends */}
      {/* About us starts */}
      <section className="about-section padding ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 sm-padding">
              <div className="about-content ">
                <div className="section-heading">
                  <h3 className="d-none">#wearegamers</h3>
                  <h3>#WeAreStudentAthletes</h3>
                  <h2 className="d-none">
                    Welcome to <span>Season</span> Two.
                  </h2>
                  <h2>
                    Welcome to <span>Season</span> Three.
                  </h2>
                  <p>
                    As a former NYC Charter School student, I feel that
                    regardless of a GPA or diploma, our goal for education
                    should focused on students developing tools to become
                    successful once they leave the classroom. E-Sports became
                    just that for me. In just a few years after I graduated, I
                    was recruited by the NBAs own Minnesota Timberwolves to join
                    their professional E-Sports team. l have always had a love
                    for gaming but never expected it would take me this far in
                    my career. I have been able to meet new people from
                    different backgrounds, travel the world and get paid for
                    doing something I absolutely love.
                  </p>
                  <p className="mt-20">
                    Through only E-Sports, I was able to tap into my own unique
                    talent, which I believe so many others from neighborhoods
                    like mine may have but simply miss out or are unaware of the
                    opportunities to explore. The ability to focus on my own
                    interests and talents while building amazing bonds with more
                    students and staff, developing my self confidence , and
                    competing in a safe and positive environment can really make
                    a big difference. It can often be the reason why a student
                    decides to fight through distractions and manage all the
                    external issues NYC teens deal with and come to school with
                    a purpose to fully engage. The reason I support the Yellas
                    program is that it allows all of our students to do just
                    that with the benefit of realizing the incredible
                    opportunities that lie ahead in the world of E-Sports. Times
                    are changing and I feel that schools desperately need
                    something different so that students like me dont miss out
                    on these cool opportunities. I feel that school should help
                    us learn about ourselves and what we are good at, not just
                    learn about what others want us to know.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 sm-padding">
              <img src={AdminPic} alt="img" />
            </div>
          </div>
        </div>
      </section>
      {/* Abouts us ends */}
      {/* //Team Macthces start */}
      <section className="matches-section">
        <div className="container">
          <div
            className="section-heading mb-40 text-center wow fade-in-bottom"
            data-wow-delay="200ms"
          >
            <h2>Last matches</h2>
            <h2 className="d-none">
              Battles Extreme <br />
              Masters <span>Tournament</span>
            </h2>
            <p className="d-none">
              Our success in creating business solutions is due in large part{' '}
              <br />
              to our talented and highly committed team.
            </p>
          </div>
          <ul className="upcoming-matches border border-0 border-success">
            {/* use array.map on this component start */}
            {TeamsAllScores?.length === 0 ? (
              <div className="container-fluid">
                <div className="row m-0 p-0">
                  <div className="col-12 d-flex align-items-center justify-content-center border border-0 border-success">
                    <h1>No records received </h1>
                  </div>
                </div>
              </div>
            ) : (
              TeamsAllScores?.map((item: any, index: number) => {
                return index < 5 ? (
                  <TeamsScores data={item} key={item.id} />
                ) : null
              })
            )}
            {/* use array.map on this component end */}
          </ul>
        </div>
      </section>

      {/* // Team Matches ends */}
      {/* Live Stream Vedeo Starts */}
      <section className="watch-live-section padding">
        <div className="container border border-0 border-success">
          <div
            className="section-heading mb-40 text-center wow fade-in-bottom"
            data-wow-delay="200ms"
          >
            <h3 className="d-none">Watch The Gameplay</h3>
            <h3>Click to watch latest episode</h3>
            <h2 className="d-none">
              Watch Live <span>Streaming</span>
            </h2>
            <h2>E-SportsCenter</h2>
            <p>
              Enjoy weekly coverage by our student broadcasting team
              highlighting the best performances across the league.
            </p>
            <p className="d-none">
              Our success in creating business solutions is due in large part{' '}
              <br />
              to our talented and highly committed team.
            </p>
          </div>
          <div className="carousel-wrap border border-0 border-success">
            <div className="w-100 mb-5 border border-0 border-danger d-flex align-items-center  justify-content-center">
              <button
                onClick={() => {
                  setlinkType('Link')
                }}
                className={
                  linkType === 'Link' ? 'default-btn mx-3' : 'mx-3 text-white'
                }
              >
                Youtube
              </button>
              <button
                onClick={() => {
                  setlinkType('twitchLink')
                }}
                className={
                  linkType === 'twitchLink'
                    ? 'default-btn mx-3'
                    : 'mx-3 text-white'
                }
              >
                Twitch
              </button>
            </div>
            <VedioLink
              dataDisplay={
                linkType === 'Link'
                  ? 'https://www.youtube.com/embed/14kbI4o64qE'
                  : 'https://player.twitch.tv/?video=1769701156&parent=www.example.com'
              }
            />
          </div>
        </div>
      </section>
      {/* Live Stream Vedeo Ends */}
      {/* Team Cards Start */}
      <section className="blog-section">
        <div className="container">
          <div
            className="section-heading mb-40 text-center wow fade-in-bottom"
            data-wow-delay="200ms"
          >
            {/* <h2>
            TEAMS THAT <br/><span> WILL CONTEND</span> 
            </h2> */}
            <h3 className="d-none">Our Gamers</h3>
            <h2 className="d-none">
              Coaches <span>Team</span>
            </h2>
            <h2>
              Top <span>Teams</span>
            </h2>
            <p className="d-none">
              Our success in creating business solutions is due in large part
              <br /> to our talented and highly committed team.
            </p>
          </div>
          <div className="row grid-post">
            {allTeams?.map((item: any, index: number) => {
              return index < 4 ? (
                <div
                  key={item.id}
                  className="col-lg-3 col-md-6 padding-15 wow fade-in-bottom flex border border-0 border-success"
                  data-wow-delay="300ms"
                >
                  <div className="post-card ">
                    <p className="text-center py-3 d-none">{item?.name}</p>
                    <div className="post-thumb text-center">
                      <img src={item?.logoUrl} className="team-logo" />
                    </div>
                    <div className="p-2">
                      <div className="post-content">
                        <h3 className="text-center">
                          <p>{item?.name}</p>
                        </h3>
                        <div className="match-time">
                          <ul className="watch-btn">
                            <li>
                              <a
                                className="dl-video-popup"
                                data-autoplay="true"
                                data-vbtype="video"
                                href={item?.youtubeLink}
                                rel="noreferrer"
                                target="_blank"
                              >
                                <i className="lab la-youtube"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                className="dl-video-popup"
                                data-autoplay="true"
                                data-vbtype="video"
                                href={item?.twitchLink}
                                rel="noreferrer"
                                target="_blank"
                              >
                                <i className="lab la-twitch"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                className="dl-video-popup"
                                data-autoplay="true"
                                data-vbtype="video"
                                href={item?.twitterLink}
                                rel="noreferrer"
                                target="_blank"
                              >
                                <i className="lab la-twitter"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null
            })}
          </div>
          <div className="row grid-post d-none">
            <div
              className="col-lg-3 col-md-6 padding-15 wow fade-in-bottom flex"
              data-wow-delay="300ms"
            >
              <div className="post-card ">
                <p className="text-center py-3">Dota 2</p>
                <div className="post-thumb text-center">
                  <img src={Team1} className="team-logo" />
                </div>
                <div className="p-2">
                  <div className="post-content">
                    <h3 className="text-center">
                      <a href="blog-details.html">Racons</a>
                    </h3>
                    <div className="match-time">
                      <ul className="watch-btn">
                        <li>
                          <a
                            className="dl-video-popup"
                            data-autoplay="true"
                            data-vbtype="video"
                            href="#"
                          >
                            <i className="lab la-youtube"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            className="dl-video-popup"
                            data-autoplay="true"
                            data-vbtype="video"
                            href="#"
                          >
                            <i className="lab la-twitch"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            className="dl-video-popup"
                            data-autoplay="true"
                            data-vbtype="video"
                            href="#"
                          >
                            <i className="lab la-twitter"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 padding-15 wow fade-in-bottom"
              data-wow-delay="400ms"
            >
              <div className="post-card">
                <p className="text-center py-3">Dota 2</p>
                <div className="post-thumb text-center">
                  <img src={Team2} className="team-logo" />
                </div>
                <div className="p-2">
                  <div className="post-content">
                    <h3 className="text-center">
                      <a href="blog-details.html">KNIGHTSOULS</a>
                    </h3>
                    <div className="match-time">
                      <ul className="watch-btn">
                        <li>
                          <a
                            className="dl-video-popup"
                            data-autoplay="true"
                            data-vbtype="video"
                            href="https://www.youtube.com/watch?v=tv7LfFeamsc"
                          >
                            <i className="lab la-youtube"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            className="dl-video-popup"
                            data-autoplay="true"
                            data-vbtype="video"
                            href="https://www.youtube.com/watch?v=tv7LfFeamsc"
                          >
                            <i className="lab la-twitch"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            className="dl-video-popup"
                            data-autoplay="true"
                            data-vbtype="video"
                            href="#"
                          >
                            <i className="lab la-twitter"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 padding-15 wow fade-in-bottom"
              data-wow-delay="500ms"
            >
              <div className="post-card">
                <p className="text-center py-3">Dota 2</p>
                <div className="post-thumb text-center">
                  <img src={Team3} className="team-logo" />
                </div>
                <div className="p-2">
                  <div className="post-content">
                    <h3 className="text-center">
                      <a href="blog-details.html">OGGORILLA</a>
                    </h3>
                    <div className="match-time">
                      <ul className="watch-btn">
                        <li>
                          <a
                            className="dl-video-popup"
                            data-autoplay="true"
                            data-vbtype="video"
                            href="https://www.youtube.com/watch?v=tv7LfFeamsc"
                          >
                            <i className="lab la-youtube"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            className="dl-video-popup"
                            data-autoplay="true"
                            data-vbtype="video"
                            href="https://www.youtube.com/watch?v=tv7LfFeamsc"
                          >
                            <i className="lab la-twitch"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            className="dl-video-popup"
                            data-autoplay="true"
                            data-vbtype="video"
                            href="#"
                          >
                            <i className="lab la-twitter"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 padding-15 wow fade-in-bottom"
              data-wow-delay="500ms"
            >
              <div className="post-card rounded">
                <p className="text-center py-3">Dota 2</p>
                <div className="post-thumb text-center">
                  <img src={Team4} className="team-logo" />
                </div>
                <div className="p-2">
                  <div className="post-content">
                    <h3 className="text-center">
                      <a href="blog-details.html">Racons</a>
                    </h3>
                    <div className="match-time">
                      <ul className="watch-btn">
                        <li>
                          <a
                            className="dl-video-popup"
                            data-autoplay="true"
                            data-vbtype="video"
                            href="https://www.youtube.com/watch?v=tv7LfFeamsc"
                          >
                            <i className="lab la-youtube"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            className="dl-video-popup"
                            data-autoplay="true"
                            data-vbtype="video"
                            href="https://www.youtube.com/watch?v=tv7LfFeamsc"
                          >
                            <i className="lab la-twitch"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            className="dl-video-popup"
                            data-autoplay="true"
                            data-vbtype="video"
                            href="#"
                          >
                            <i className="lab la-twitter"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Team Cards End */}
      {/* Teams start */}
      <section className="team-section padding d-none">
        <div className="container">
          <div
            className="section-heading text-center mb-40 wow fade-in-bottom"
            data-wow-delay="200ms"
          >
            <h3>Our Gamers</h3>
            <h2>
              Meet Our <span>Gamers</span>
            </h2>
            <p className="d-none">
              Our success in creating business solutions is due in large part
              <br /> to our talented and highly committed team.
            </p>
          </div>
          <div className="outside-spacing">
            <div className="team-carousel">
              <div className="swiper-wrapper">
                {/* this is the div where we have to integrate the raking players api                       <img className='img-fluid  border border-2 border-success' src={team}  /> */}
                {gamersByRanking?.map((item: GamePlayers) => {
                  return (
                    <div key={item?.name} className="swiper-slide">
                      <div className="team-item">
                        <div className="team-thumb">
                          {item?.ranking?.length <= 0 ? (
                            <img
                              className="img-fluid  border border-0 border-success"
                              src={team}
                            />
                          ) : (
                            <img
                              className="img-fluid  border border-0 border-success"
                              style={{ height: '300px' }}
                              src={item?.ranking[2]?.profilePicURL}
                            />
                          )}
                          <div className="shape-wrap">
                            <div className="shape shape-1"></div>
                            <div className="shape shape-2"></div>
                            <div className="shape shape-3"></div>
                            <div className="shape shape-4"></div>
                          </div>
                        </div>
                        <div className="team-content">
                          <span className="whte-shape"></span>
                          <h3>
                            {item?.ranking?.length <= 0 ? (
                              <a href="player-details.html">Félix Lengyel</a>
                            ) : (
                              <a href="player-details.html">
                                {item?.ranking[2]?.name}
                              </a>
                            )}
                          </h3>
                          <h4>Overwatch</h4>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              {/* <!-- Carousel Arrows --> */}
              <div className="swiper-nav swiper-next">
                <i className="las la-long-arrow-alt-right"></i>
              </div>
              <div className="swiper-nav swiper-prev">
                <i className="las la-long-arrow-alt-left"></i>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Teams ends */}

      {/* Newsletter starts */}
      <section className="contact-section padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 sm-padding">
              <div className="section-heading">
                {/* <h3>Send Us an Email</h3> */}
                <h2 className="d-none">
                  Join Us As a Super Fans and Get all <span>the Benefits</span>
                </h2>
                <h2>
                  Want this program at your school?
                  <span>
                    {' '}
                    Send us a message and our team will send your teacher or
                    principal all the info on how to get started now!
                  </span>
                </h2>
                <p className="d-none">
                  Our success in creating business solutions is due in large
                  part <br />
                  to our talented and highly committed team.
                </p>
                <a href="contact.html" className="default-btn">
                  Join Our Team <span></span>
                </a>
              </div>
            </div>
            <div className="col-md-6 sm-padding">
              <div className="contact-form ml-40">
                {/* <form 
                  className="form-horizontal"
                > */}
                <div className="contact-form-group">
                  <div className="form-field">
                    <input
                      type="text"
                      name="contact_name"
                      className="form-control"
                      placeholder="Your Name"
                      onChange={(e: any) => {
                        setname(e.target.value)
                      }}
                      required
                    />
                  </div>
                  <div className="form-field">
                    <input
                      type="email"
                      name="contact_email"
                      className="form-control"
                      placeholder="Email"
                      required
                      onChange={(e: any) => {
                        setemail(e.target.value)
                      }}
                    />
                  </div>
                  <div className="form-field message">
                    <textarea
                      name="message"
                      className="form-control"
                      placeholder="Message"
                      required
                      onChange={(e: any) => {
                        setmessage(e.target.value)
                      }}
                    />
                  </div>
                  <div className="form-field">
                    <button onClick={handleSendMessage} className="default-btn">
                      SEND MESSAGE<span></span>
                    </button>
                  </div>
                </div>
                <div className="alert" role="alert"></div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Game Logo starts */}
      <div className="sponsor-section">
        <div className="container border border-0 border-success">
          {/* idr slider put krna hai */}
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={false}
            slidesPerView={4}
            autoplay={true}
            pagination={false}
            modules={[Autoplay]}
            className="mySwiper border border-0 border-danger mb-3"
          >
            <SwiperSlide>
              <img src={clientOne} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={clientTwo} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={clientThree} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={clientFour} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={clientFive} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={clientSix} />
            </SwiperSlide>
          </Swiper>

          {/* idr slider end ho jay ga */}

          {/* <div className="outside-spacing">
            <div className="sponsor-carousel">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <img src={clientOne} />
                </div>
                <div className="swiper-slide">
                  <img src={clientTwo} />
                </div>
                <div className="swiper-slide">
                  <img src={clientThree} />
                </div>
                <div className="swiper-slide">
                  <img src={clientFour} />
                </div>
                <div className="swiper-slide">
                  <img src={clientFive} />
                </div>
                <div className="swiper-slide">
                  <img src={clientSix} />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* Game Logo Ends */}

      {/* Match Time Details */}
      <section className="match-details padding w-100 mx-auto">
        <div className="container">
          <div
            className="section-heading mb-40 text-center wow fade-in-bottom"
            data-wow-delay="200ms"
          >
            <h2>
              TIMETABLE FOR THE<span> TOURNAMENTS</span>
            </h2>
          </div>
          <div className="match-time-details d-flex justify-content-between  px-3 my-2 rounded">
            <h3 className="left-team text-center">
              <a href="https://overworld.qodeinteractive.com/tournament/vanilla-masters-2019/">
                Monday
              </a>
            </h3>

            <h4 className="">4:00PM - 6:00PM</h4>

            <h3 className="right-team text-center">
              <a href="team-details.html">NBA 2K</a>
            </h3>
          </div>
          <div className="match-time-details d-flex justify-content-between  px-3 my-2 rounded">
            <h3 className="left-team text-center">
              <a href="https://www.twitch.tv/phizzi">Tuesday</a>
            </h3>

            <h4 className="">4:00PM - 6:00PM</h4>

            <h3 className="right-team text-center">
              <a href="team-details.html">NBA 2k</a>
            </h3>
          </div>
          <div className="match-time-details d-flex justify-content-between px-3 my-2 rounded">
            <h3 className="left-team text-center">
              <a href="team-details.html">Wednesday</a>
            </h3>

            <h4 className="">4:00PM - 6:00PM</h4>

            <h3 className="right-team text-center">
              <a href="team-details.html">Call of Duty</a>
            </h3>
          </div>
          <div className="match-time-details d-flex justify-content-between  px-3 my-2 rounded">
            <h3 className="left-team text-center">
              <a href="https://www.twitch.tv/tylerootd">Thursday</a>
            </h3>

            <h4 className="">4:00PM - 6:00PM</h4>

            <h3 className="right-team text-center">
              <a href="team-details.html">NBA 2K</a>
            </h3>
          </div>
          <div className="match-time-details d-flex align-items-center justify-content-between  px-3 my-2 rounded">
            <h3 className="left-team text-center">
              <a href="team-details.html">Friday</a>
            </h3>

            <h4 className="">4:00PM - 6:00PM</h4>

            <h3 className="right-team text-center">
              <a href="team-details.html">Call of Duty</a>
            </h3>
          </div>
          <div className="match-time-details d-flex align-items-center justify-content-between  px-3 my-2 rounded">
            <h3 className="left-team text-center">
              <a href="team-details.html">Saturday</a>
            </h3>

            <h3 className="right-team text-center">
              <a href="team-details.html">No Streaming</a>
            </h3>
          </div>
          <div className="match-time-details d-flex align-items-center justify-content-between  px-3 my-2 rounded">
            <h3 className="left-team text-center">
              <a href="team-details.html">Sunday</a>
            </h3>
            <h4 className="">4:00PM - 6:00PM</h4>
            <h3 className="right-team text-center">
              <a href="team-details.html">Dota 2</a>
            </h3>
          </div>
        </div>
      </section>
      {/* Products Start */}
      <section className="shop-section">
        <div className="container">
          <div
            className="section-heading mb-40 text-center wow fade-in-bottom"
            data-wow-delay="200ms"
          >
            <h3>Earn badges</h3>
            <h2>
              Win <span>prizes!</span>
            </h2>
            <p className="d-none">
              Our success in creating business solutions is due in large part{' '}
              <br />
              to our talented and highly committed team. {productArray?.length}
            </p>
          </div>
          <div className="outside-spacing border border-0 border-success">
            {productArray === undefined ? (
              <div className="conatiner-fluid m-0 p-0">
                <div className="row m-0 p-0">
                  <div className="col-12 m-0 p-5 border border-0 border-danger d-flex align-items-center justify-content-center">
                    <ReactLoading
                      type="balls"
                      color="#9841ff"
                      height={100}
                      width={100}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <Swiper
                grabCursor={true}
                centeredSlides={false}
                slidesPerView={4}
                spaceBetween={10}
                autoplay={true}
                pagination={false}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 50,
                  },
                }}
                modules={[Autoplay]}
                className="mySwiper mb-3"
              >
                {productArray &&
                  productArray.map((item: any) => {
                    return item?.name != 'Wallet Topup' ? (
                      <SwiperSlide
                        key={item?.id}
                        className="col-md-4 padding-15"
                      >
                        <Link
                          to={`/productDetail/${item?.id}`}
                          state={{ data: item }}
                        >
                          <div className="product-card border border-0 border-success">
                            <div className="product-thumb">
                              <img
                                src={item?.images[0]?.src}
                                alt="img"
                                style={{ objectFit: 'cover' }}
                              />
                              {/*  badge hot badge in-stock badge out-stock */}
                              <a href="#" className="badge hot d-none">
                                Hot
                              </a>
                              <ul className="shop-action d-none">
                                <li>
                                  <a href="#">
                                    <i className="lar la-heart"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="las la-retweet"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="las la-expand-arrows-alt"></i>
                                  </a>
                                </li>
                              </ul>
                              {/* <Link className="default-btn" to={`/productDetail/${item?.id}`} state={{data:item}}>
                             Add To Cart
                          </Link> */}
                            </div>
                            <div className="product-info">
                              <div className="product-inner">
                                <ul className="category">
                                  <li>
                                    <a href="#">{item?.categories[0]?.name}</a>
                                  </li>
                                </ul>
                                <ul className="rating">
                                  {/* Add star rating products  */}
                                  <StarRating starCount={item?.rating_count} />
                                </ul>
                              </div>
                              <h3>
                                {/* <Link to={`/productDetail/${item?.id}`} state={{data:item}}> {item?.name}</Link> */}
                                <span> {item?.name}</span>
                              </h3>
                              <h4 className="price">${item?.regular_price}</h4>
                            </div>
                          </div>
                        </Link>
                      </SwiperSlide>
                    ) : null
                  })}
                <SwiperSlide></SwiperSlide>
                {/* <SwiperSlide>
                <div className="product-card border border-0 border-danger">
                          <div className="product-thumb border border-0 border-danger">
                            <img src={product} />
                            <a href="#" className="badge in-stock">
                              In Stock
                            </a>
                            <a href="cart.html" className="default-btn">
                              Add To Cart
                            </a>
                          </div>
                          <div className="product-info">
                            <div className="product-inner">
                              <ul className="category">
                                <li>
                                  <a href="#">Steering</a>
                                </li>
                              </ul>
                              <ul className="rating">
                                <li>
                                  <i className="las la-star"></i>
                                </li>
                                <li>
                                  <i className="las la-star"></i>
                                </li>
                                <li>
                                  <i className="las la-star"></i>
                                </li>
                                <li>
                                  <i className="las la-star"></i>
                                </li>
                                <li>
                                  <i className="las la-star"></i>
                                </li>
                              </ul>
                            </div>
                            <h3>
                              <Link to="/productDetail">Steering Wheel</Link>
                            </h3>
                            <h4 className="price">$69.00</h4>
                          </div>
                        </div>
                </SwiperSlide> */}
              </Swiper>
            )}
            {/* Slider should be added here  */}

            {/* Slider should be added here  */}
            <div className="shop-carousel d-none">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="product-card border border-2 border-danger">
                    <div className="product-thumb border border-0 border-danger">
                      <img src={product} />
                      <a href="#" className="badge in-stock">
                        In Stock
                      </a>
                      <a href="cart.html" className="default-btn">
                        Add To Cart
                      </a>
                    </div>
                    <div className="product-info">
                      <div className="product-inner">
                        <ul className="category">
                          <li>
                            <a href="#">Steering</a>
                          </li>
                        </ul>
                        <ul className="rating">
                          <li>
                            <i className="las la-star"></i>
                          </li>
                          <li>
                            <i className="las la-star"></i>
                          </li>
                          <li>
                            <i className="las la-star"></i>
                          </li>
                          <li>
                            <i className="las la-star"></i>
                          </li>
                          <li>
                            <i className="las la-star"></i>
                          </li>
                        </ul>
                      </div>
                      <h3>
                        <Link to="/productDetail">Steering Wheel</Link>
                      </h3>
                      <h4 className="price">$69.00</h4>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide border border-2 border-success">
                  <div className="product-card">
                    <div className="product-thumb">
                      <img src={product} />
                      <a href="#" className="badge hot">
                        Hot
                      </a>
                      <a href="cart.html" className="default-btn">
                        Add To Cart
                      </a>
                    </div>
                    <div className="product-info">
                      <div className="product-inner">
                        <ul className="category">
                          <li>
                            <a href="#">Mouse</a>
                          </li>
                        </ul>
                        <ul className="rating">
                          <li>
                            <i className="las la-star"></i>
                          </li>
                          <li>
                            <i className="las la-star"></i>
                          </li>
                          <li>
                            <i className="las la-star"></i>
                          </li>
                          <li>
                            <i className="las la-star"></i>
                          </li>
                          <li>
                            <i className="las la-star"></i>
                          </li>
                        </ul>
                      </div>
                      <h3>
                        <a href="shop-details.html">Fantech Mouse</a>
                      </h3>
                      <h4 className="price">$49.00</h4>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="product-card">
                    <div className="product-thumb">
                      <img src={product} />
                      <a href="#" className="badge sale">
                        -70%
                      </a>
                      <a href="cart.html" className="default-btn">
                        Add To Cart
                      </a>
                    </div>
                    <div className="product-info">
                      <div className="product-inner">
                        <ul className="category">
                          <li>
                            <a href="#">Console</a>
                          </li>
                        </ul>
                        <ul className="rating">
                          <li>
                            <i className="las la-star"></i>
                          </li>
                          <li>
                            <i className="las la-star"></i>
                          </li>
                          <li>
                            <i className="las la-star"></i>
                          </li>
                          <li>
                            <i className="las la-star"></i>
                          </li>
                          <li>
                            <i className="las la-star"></i>
                          </li>
                        </ul>
                      </div>
                      <h3>
                        <a href="shop-details.html">Targus Console</a>
                      </h3>
                      <h4 className="price">$39.00</h4>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="product-card">
                    <div className="product-thumb">
                      <img src={product} />
                      <a href="#" className="badge hot">
                        Hot
                      </a>
                      <a href="cart.html" className="default-btn">
                        Add To Cart
                      </a>
                    </div>
                    <div className="product-info">
                      <div className="product-inner">
                        <ul className="category">
                          <li>
                            <a href="#">Controller</a>
                          </li>
                        </ul>
                        <ul className="rating">
                          <li>
                            <i className="las la-star"></i>
                          </li>
                          <li>
                            <i className="las la-star"></i>
                          </li>
                          <li>
                            <i className="las la-star"></i>
                          </li>
                          <li>
                            <i className="las la-star"></i>
                          </li>
                          <li>
                            <i className="las la-star"></i>
                          </li>
                        </ul>
                      </div>
                      <h3>
                        <a href="shop-details.html">Xbox Controller</a>
                      </h3>
                      <h4 className="price">$19.00</h4>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="product-card">
                    <div className="product-thumb">
                      <img src={product} />
                      <a href="#" className="badge out-stock">
                        Out Of Stock
                      </a>
                      <a href="cart.html" className="default-btn">
                        Add To Cart
                      </a>
                    </div>
                    <div className="product-info">
                      <div className="product-inner">
                        <ul className="category">
                          <li>
                            <a href="#">Chair</a>
                          </li>
                        </ul>
                        <ul className="rating">
                          <li>
                            <i className="las la-star"></i>
                          </li>
                          <li>
                            <i className="las la-star"></i>
                          </li>
                          <li>
                            <i className="las la-star"></i>
                          </li>
                          <li>
                            <i className="las la-star"></i>
                          </li>
                          <li>
                            <i className="las la-star"></i>
                          </li>
                        </ul>
                      </div>
                      <h3>
                        <a href="shop-details.html">Ergonomic Chair</a>
                      </h3>
                      <h4 className="price">$39.00</h4>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Carousel Arrows --> */}
              <div className="swiper-nav swiper-next">
                <i className="las la-long-arrow-alt-right"></i>
              </div>
              <div className="swiper-nav swiper-prev">
                <i className="las la-long-arrow-alt-left"></i>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Products Ends */}

      {/* Blogs Start */}
      {/* <section className="blog-section">
        <div className="container">
          <div
            className="section-heading mb-40 text-center wow fade-in-bottom"
            data-wow-delay="200ms"
          >
            <h3>Whats on our mind</h3>
            <h2>
              News and <span>Headlines</span>
            </h2>
            <p className="d-none">
              Our success in creating business solutions is due in large part{' '}
              <br />
              to our talented and highly committed team.
            </p>
          </div>
          <div className="row grid-post">
            <div
              className="col-lg-4 col-md-6 padding-15 wow fade-in-bottom"
              data-wow-delay="300ms"
            >
              <div className="post-card">
                <div className="post-thumb">
                  <img src={blog} />
                  <a href="blog-classNameic.html" className="post-category">
                    E-sports
                  </a>
                </div>
                <div className="post-content-wrap">
                  <ul className="post-meta">
                    <li>
                      <i className="las la-calendar"></i>Jan 01 2022
                    </li>
                    <li>
                      <i className="las la-user"></i>Elliot Alderson
                    </li>
                  </ul>
                  <div className="post-content">
                    <h3>
                      <a href="blog-details.html">
                        How to start initiating an startup in few days.
                      </a>
                    </h3>
                    <p>
                      Financial experts support or help you to to find out which
                      way you can raise your funds more...
                    </p>
                    <a href="blog-details.html" className="read-more">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 padding-15 wow fade-in-bottom"
              data-wow-delay="400ms"
            >
              <div className="post-card">
                <div className="post-thumb">
                  <img src={blog2} />
                  <a href="blog-classNameic.html" className="post-category">
                    Tech
                  </a>
                </div>
                <div className="post-content-wrap">
                  <ul className="post-meta">
                    <li>
                      <i className="las la-calendar"></i>Jan 01 2022
                    </li>
                    <li>
                      <i className="las la-user"></i>Elliot Alderson
                    </li>
                  </ul>
                  <div className="post-content">
                    <h3>
                      <a href="blog-details.html">
                        Financial experts support help you to find out.
                      </a>
                    </h3>
                    <p>
                      Financial experts support or help you to to find out which
                      way you can raise your funds more...
                    </p>
                    <a href="blog-details.html" className="read-more">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 padding-15 wow fade-in-bottom"
              data-wow-delay="500ms"
            >
              <div className="post-card">
                <div className="post-thumb">
                  <img src={blog3} />
                  <a href="blog-classNameic.html" className="post-category">
                    Games
                  </a>
                </div>
                <div className="post-content-wrap">
                  <ul className="post-meta">
                    <li>
                      <i className="las la-calendar"></i>Jan 01 2022
                    </li>
                    <li>
                      <i className="las la-user"></i>Elliot Alderson
                    </li>
                  </ul>
                  <div className="post-content">
                    <h3>
                      <a href="blog-details.html">
                        Innovative business all over the world.
                      </a>
                    </h3>
                    <p>
                      Financial experts support or help you to to find out which
                      way you can raise your funds more...
                    </p>
                    <a href="blog-details.html" className="read-more">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* Blogs Ends */}
    </>
  )
}

function TeamsScores({ data }: any) {
  // console.log('Team one name :  ',data);
  // console.log('Team two name : ',data?.team2);
  return (
    <li className="matches-list">
      <div className="participate-team wow fade-in-left" data-wow-delay="200ms">
        <img
          className="customTeamLogo"
          src={
            data?.team1?.logoUrl || data?.player1?.profilePicURL || AvatarImg
          }
        ></img>
        {/* <img src={teamlogoOne}></img> */}
        <h3>
          <a href="team-details.html">
            {(data?.team1?.name || data?.player1?.name)?.substring(0, 10)}....
          </a>
        </h3>
        <div className="match-info">{data?.team1Result}</div>
      </div>
      <div className="match-time">
        <p className="fs-24 text-center">
          {moment(data?.createdAt).format('LT')}
          <br />
          <span>{moment(data?.createdAt).format('ll')}</span>
        </p>
        <ul className="watch-btn d-none">
          <li>
            <a
              className="dl-video-popup"
              data-autoplay="true"
              data-vbtype="video"
              href="https://www.youtube.com/watch?v=tv7LfFeamsc"
            >
              <i className="lab la-youtube"></i>
            </a>
          </li>
          <li>
            <a
              className="dl-video-popup"
              data-autoplay="true"
              data-vbtype="video"
              href="https://www.youtube.com/watch?v=tv7LfFeamsc"
            >
              <i className="lab la-twitch"></i>
            </a>
          </li>
        </ul>
        <div>
          <h2 className="text-center">
            <Link to={`/game-report/${data.id}`}>View Report</Link>
          </h2>
        </div>
      </div>
      <div
        className="participate-team oponent wow fade-in-right"
        data-wow-delay="200ms"
      >
        <h3>
          <a href="team-details.html">
            {(data?.team2?.name || data?.player2?.name)?.substring(0, 10)}....
          </a>
        </h3>
        <div className="match-info">{data?.team2Result}</div>
        <img
          className="customTeamLogo"
          src={
            data?.team2?.logoUrl || data?.player2?.profilePicURL || AvatarImg
          }
        ></img>
        {/* <img src={teamlogoTwo}></img> */}
      </div>
    </li>
  )
}

// Twitch and vedio Link Vedio Component
function VedioLink({ dataDisplay }: any) {
  return (
    <div className="w-100 d-flex align-items-center justify-content-center">
      <div className="w-75 border border-0 border-success">
        <iframe
          width="100%"
          height="450px"
          src={dataDisplay}
          frameBorder="0"
          allowFullScreen
        ></iframe>
        <a
          data-autoplay="true"
          data-vbtype="video"
          href={dataDisplay}
          className="dl-video-popup play-btn d-none"
        >
          <svg
            enableBackground="new 0 0 41.999 41.999"
            version="1.1"
            viewBox="0 0 41.999 41.999"
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M36.068,20.176l-29-20C6.761-0.035,6.363-0.057,6.035,0.114C5.706,0.287,5.5,0.627,5.5,0.999v40  c0,0.372,0.206,0.713,0.535,0.886c0.146,0.076,0.306,0.114,0.465,0.114c0.199,0,0.397-0.06,0.568-0.177l29-20  c0.271-0.187,0.432-0.494,0.432-0.823S36.338,20.363,36.068,20.176z M7.5,39.095V2.904l26.239,18.096L7.5,39.095z" />
          </svg>
          <div className="ripple"></div>
        </a>
      </div>
    </div>
    //   <div className="watch-carousel swiper-container border border-2 border-danger">
    //   <div className="swiper-wrapper">
    //     <div className="swiper-slide">
    //       <iframe width="100%" height="450px" src={dataDisplay} frameBorder="0" allowFullScreen></iframe>
    //       <a
    //         data-autoplay="true"
    //         data-vbtype="video"
    //         href={dataDisplay}
    //         className="dl-video-popup play-btn d-none"
    //       >
    //         <svg
    //           enableBackground="new 0 0 41.999 41.999"
    //           version="1.1"
    //           viewBox="0 0 41.999 41.999"
    //           xmlSpace="preserve"
    //           xmlns="http://www.w3.org/2000/svg"
    //         >
    //           <path d="M36.068,20.176l-29-20C6.761-0.035,6.363-0.057,6.035,0.114C5.706,0.287,5.5,0.627,5.5,0.999v40  c0,0.372,0.206,0.713,0.535,0.886c0.146,0.076,0.306,0.114,0.465,0.114c0.199,0,0.397-0.06,0.568-0.177l29-20  c0.271-0.187,0.432-0.494,0.432-0.823S36.338,20.363,36.068,20.176z M7.5,39.095V2.904l26.239,18.096L7.5,39.095z" />
    //         </svg>
    //         <div className="ripple"></div>
    //       </a>
    //     </div>
    //   </div>
    // </div>
  )
}
