import React, { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useCreateGame } from 'utils/apis/live-game'
import {  toast } from 'react-toastify'
import { useUploadFile } from 'utils/apis/signup'
import 'react-toastify/dist/ReactToastify.css'
import { Role } from 'utils/types/adminType'
type MyValues = {
  name: string
  gameLink: string
}
export default function AddGame({role}:Role) {
  const { register, handleSubmit, reset } = useForm<MyValues>({})
  const {
    mutate: uploadFile,
    data: imageData,
    isError: errorUploadingImg,
  } = useUploadFile() //  this is the custom hook for uploading the file
  const {
    mutate: createGame,
    isSuccess: isGameCreated,
    isLoading: creatingGame,
  } = useCreateGame()
  useEffect(() => {
    if (isGameCreated) {
      toast.success('Game is created successfully.....', {
        position: toast.POSITION.TOP_RIGHT,
      })
      reset({
        name: '',
        gameLink: '',
      })
    } 
  }, [isGameCreated])

  const onSubmit: SubmitHandler<MyValues> = data => {
    if (errorUploadingImg) {
      alert('Can not Upload Logo Url try again...')
    }

    const payload = {
      name: data.name,
      gameLink: imageData,
    }
    createGame(payload)
  }
  const handleGmaeLogoUrl = (e: any) => {
    uploadFile({
      folderName: 'TeamScore',
      field: 'TeamsScorePic',
      file: e.target.files[0],
    })
    // console.log(e.target.value);
  }
  console.log('Role is : ',role);
  return (
    <div className="container ">
      <div
        className="section-heading mb-40 text-center wow fade-in-bottom pt-5 pb-2"
        data-wow-delay="200ms"
      >
        {' '}
        <h2>
          ADD <span>Game</span>
        </h2>
      </div>
      <form
        action=""
        method="post"
        id="liveGame"
        className="form-horizontal"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="d-flex justify-content-between gap-3 ">
          <div className="form-field w-100">
            <label>Name</label>
            <input
              type="text"
              id="name"
              className="form-control form-input bd-grad text-white"
              placeholder="Enter game name"
              {...register('name', { required: true })}
            />
          </div>
          <div className="form-field w-100">
            <label>Game Logo</label>
            <input
              type="file"
              id="gameLink"
              className="form-control form-input bd-grad text-white"
              placeholder="Please enter game link"
              {...register('gameLink', { required: true })}
              onChange={handleGmaeLogoUrl}
            />
          </div>
        </div>
        <div className="form-field py-2 text-center mt-3">
          {creatingGame ? (
            <button className="default-btn">
              Creating Game...<span></span>
            </button>
          ) : (
            <button id="submit" className="default-btn" type="submit">
              Submit<span></span>
            </button>
          )}
        </div>
      </form>
      
    </div>
  )
}
