import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "./api-client";
import { IGetLiveGames, ILiveGame } from "utils/types/liveGame.type";
import { queryClient } from "index";





export const useGetAllGames = (offset:number,limit:number) =>

useQuery<IGetLiveGames[], Error>(['userGames'],() =>
client(`live-game/getLiveGames?limit=${limit}&offset=${offset}'`),
)
export const useAddGames = () =>{
const queryClient = useQueryClient()
 return useMutation<IGetLiveGames,Error,ILiveGame>(data =>
    client(`live-game/addLiveGame`, {
      data,
    }),
    {
        onSuccess() {
          queryClient.invalidateQueries(['userGames'])
        },
      },
)}

// for creating games by admin
export const useCreateGame = ()=>
{
  return useMutation<unknown,Error,any>((data)=>
  client('live-game/addLiveGame',{data}),
 {
   onSuccess(){
     queryClient.invalidateQueries(['userGames'])
   },
 },
 )
}