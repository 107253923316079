import React, { useState } from 'react'
import Footer from 'components/footer'
import Header from 'components/header'
import 'assets/css/elements.css'
import 'assets/css/blog.css'
import 'assets/css/header.css'
import 'assets/css/main.css'
import 'assets/css/responsive.css'
import 'assets/css/shop.css'
import AppRoutes from 'routes'
import { ShopingCart } from 'store'
import { ToastContainer } from 'react-toastify'
function App() {
  const [cartCount, setcartCount] = useState<number>(0)
  return (
    <>
      <ShopingCart.Provider value={{ cartCount, setcartCount }}>
        <Header />
        <AppRoutes />
        <Footer />
        <ToastContainer />
      </ShopingCart.Provider>
    </>
  )
}

export default App
