import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useIsLogin } from 'hooks'
import TeamReporting from './teamReporting'
import PlayerReporting from './playerReporting'

export default function ScoreReporting() {
  const { isLogin } = useIsLogin()
  const [scoreType, setTeamPlayer] = useState('team')
  return isLogin ? (
    <>
      <div
        className="section-heading mb-40 text-center wow fade-in-bottom py-5"
        data-wow-delay="200ms"
      >
        <h2>
          YELLAS SCORE <span>REPORTING</span>
        </h2>
      </div>
      <div className="container">
        <div className="tab-navigation">
          <button
            onClick={() => setTeamPlayer('team')}
            className={`${scoreType === 'team' ? 'active' : ''}`}
          >
            Add Team score
          </button>
          <button
            onClick={() => setTeamPlayer('player')}
            className={`${scoreType === 'player' ? 'active' : ''}`}
          >
            Add PLayer score
          </button>
        </div>
        <div className="tab-content">
          <div className="tab-pane" style={{ display: 'block' }}>
            <div className="contact-form">
              {scoreType === 'team' && <TeamReporting />}
              {scoreType === 'player' && <PlayerReporting />}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div
      className="section-heading mb-40 text-center wow fade-in-bottom py-5"
      data-wow-delay="200ms"
    >
      <h2>
        YELLAS SCORE <span>REPORTING</span>
      </h2>
      <div className="conatiner-fluid m-0 p-0">
        <div className="row d-flex align-items-center justify-content-center  m-0 p-0">
          <p className="py-4">Please login and come back again...</p>
          <div className="w-25 m-0 p-0">
            <Link to="/login" className="w-25 default-btn">
              Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
