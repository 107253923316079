import { useMutation,useQuery} from "@tanstack/react-query";
import { client } from "./api-client";
export const UserLogin = () =>
  useMutation((data: unknown) =>
    client(`auth/login`, {
      data,
    }),
)
export const UserLogout = () =>{
 useQuery(['user/login'],()=>{client('/user/getLoggedInUser')})
}