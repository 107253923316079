/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@tanstack/react-query'
import { client } from './api-client'
import { TeamPlayer } from 'utils/types/scoreReporting.Type'

export const getAllteams = () =>
  useQuery(['getAllTeams'], () => {
    client('team/getAllTeams')
  })

export const useGetAllPlayers = () =>
  useQuery<TeamPlayer[], Error>(['getAllPlayers'], () =>
    client('user/getAllPlayers'),
  )

export const useGetTeamOnePlayers = (id: string) =>
  useQuery<TeamPlayer[], Error>(
    ['GetTeamOnePlayers', id],
    () => client(`user/getPlayersByTeam/${id}`),
    {
      enabled: !!id,
    },
  )
export const useTeamTwoPlayers = (id: string) =>
  useQuery<TeamPlayer[], Error>(
    ['GetTeamTowPlayers', id],
    () => client(`user/getPlayersByTeam/${id}`),
    {
      enabled: !!id,
    },
  )
