import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { useUploadFile, adminSignup } from 'utils/apis/signup'
import { ISignup } from 'utils/types/signup.type'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
export default function CoachSignup() {
  const { mutate: signUp, isSuccess, isLoading } = adminSignup() //this is a custom hook for registering the user
  const {
    mutate: uploadFile,
    data: imageData,
    isLoading: imageUploading,
  } = useUploadFile() //  this is the custom hook for uploading the file
  // const { mutate: uploadTeamFile, data: teamData } = useUploadFile()
  const navigate = useNavigate()
  useEffect(() => {
    if (isSuccess) {
      toast.success('You have successfully create account.....', {
        position: toast.POSITION.TOP_RIGHT,
      })
      setTimeout(() => {
        navigate('/login')
      }, 2500)
    }
  }, [isSuccess])
  const handleLogin = () => {
    const data = getValues()
    const payload = {
      ...data,
      profilePicURL: imageData?.url,
    }
    signUp(payload)
    // if (data.youtubeLinkURL.includes('youtube')) {
    //   const payload = {
    //     ...data,
    //     profilePicURL: imageData?.url,
    //     // teamLogoURL: teamData?.url,
    //   }
    //   signUp(payload)
    // } else {
    //   toast.error('Please provide a valid youtube link.....', {
    //     position: toast.POSITION.TOP_RIGHT,
    //   })
    // }
  }

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm<ISignup>()
  const handleUploadMedia = (e: any) => {
    uploadFile({
      folderName: 'user',
      field: 'userProfile',
      file: e.target.files[0],
    })
  }
  // const handleUploadTeamLogo = (e: any) => {
  //   uploadTeamFile({
  //     folderName: 'userTeamLogo',
  //     field: 'userProfile',
  //     file: e.target.files[0],
  //   })
  // }
  return (
    <>
      <div className="Studentform">
        <section className="contact-section ">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sm-padding py-5 px-5 lg:w-75 mx-auto">
                <div className="contact-title text-center">
                  <h2>
                    Coach <span>Register</span>
                  </h2>
                  <p className="d-none">
                    Register Your Student Profile and Enjoy E-sports
                  </p>
                </div>
                {/* Registr Form starts */}
                <div className="contact-form">
                  <form
                    action=""
                    method="POST"
                    id="studentLogin"
                    className="form-horizontal"
                    onSubmit={handleSubmit(handleLogin)}
                  >
                    <div className="contact-form-group">
                      <div className="form-field">
                        <input
                          type="text"
                          id="student_name"
                          className="form-control bd-grad"
                          placeholder="Your Name"
                          {...register('name', { required: true })}
                        />
                        {errors.name && 'Name is required'}
                      </div>

                      <div className="form-field">
                        <input
                          type="email"
                          id="student_email"
                          className="form-control bd-grad"
                          placeholder="Your Email"
                          {...register('email', { required: true })}
                        />
                        {errors.email && 'Email is required'}
                      </div>
                      <div className="form-field">
                        <input
                          type="text"
                          id="school_name"
                          className="form-control bd-grad"
                          placeholder="School Name"
                          {...register('school', { required: true })}
                        />
                        {errors.school && 'School Name is required'}
                      </div>

                      <div className="form-field position-relative">
                        <span className="position-absolute  customText">
                          upload profile picture
                        </span>
                        <input
                          type="file"
                          id="profilePicURL"
                          className="form-control bd-grad border border-2 border-success"
                          {...register('profilePicURL')}
                          onChange={handleUploadMedia}
                        />
                      </div>

                      <div className="form-field d-none">
                        <input
                          type="text"
                          id="youtubeLink"
                          className="form-control bd-grad"
                          placeholder="Youtube Link"
                          {...register('youtubeLinkURL')}
                        />
                      </div>
                      <div className="form-field d-none">
                        <input
                          type="text"
                          id="twichLink"
                          className="form-control bd-grad"
                          placeholder="Twitch Link "
                          {...register('twitchLinkURL')}
                        />
                      </div>
                      <div className="form-field">
                        <input
                          type="password"
                          id="student_password"
                          className="form-control bd-grad"
                          placeholder="Enter Password"
                          {...register('password', { required: true })}
                        />
                        {errors.password && 'Password is required'}
                      </div>
                      <div className="form-field message d-none">
                        <p>
                          MY SKILLSET OR POSITION (GIVE THE POSITION YOU PLAY,
                          DESCRIBE HOW YOU PLAY THE GAME & HOW YOU LEAD)
                        </p>
                        <textarea
                          id="message"
                          className="form-control bd-grad"
                          {...register('skillset')}
                        ></textarea>
                      </div>
                    </div>
                    <div className="mt-4 form-field">
                      <button
                        id="submit"
                        className="default-btn"
                        type="submit"
                        disabled={isLoading || imageUploading}
                      >
                        {isLoading ? 'Submiting......' : 'Submit'}
                      </button>
                    </div>
                    {isSuccess && (
                      <div id="form-messages" className="alert" role="alert">
                        Your Form Submitted
                      </div>
                    )}
                  </form>
                </div>
                {/* Registr Form ends */}

                <div className="col-md-12 sm-padding py-5">
                  <div className="contact-details-wrap text-center">
                    <div className="contact-title ">
                      <h3>ALREADY HAVE AN ACCOUNT ?</h3>
                    </div>
                    <Link className="default-btn" to="/login">
                      Sign in
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
