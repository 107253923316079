import React,{useState,useEffect} from 'react'
// import product from 'assets/img/product-1.png'
import { getAllProducts } from 'utils/helpers'
import { Link } from 'react-router-dom'
import { handelGetProducts } from 'utils/helpers'
import ReactLoading from 'react-loading';
import StarRating from 'components/starRating';
export default function Estore() {
  const [productArray,setproductArray]:any[] = useState();
  const[productCatagories,setproductCatagories]:any[] = useState();
  const [filterType,setfilterType] = useState('')
  useEffect(()=>{
    // handelProducts('products');
    handelProducts('allProducts');
    handelProducts('products/categories');
  },[])
  const handelProducts = async(endPoint:string)=>{
    switch (endPoint) {
      case 'allProducts':{
        const req:any = await getAllProducts();
        const res = await req;
        setproductArray(res);
        break;
      }
      case 'products':{
        const req:any = await handelGetProducts(endPoint);
        const res = await req;
        setproductArray(res);
        break;
      }
      case 'products/categories':{
        const reqCatagorey:any = await handelGetProducts(endPoint);
        const resCatagorey = await reqCatagorey;
        setproductCatagories(resCatagorey);
      }
      break;
      default:
        break;
    }
    //  console.log('Response of the api is : ',res);
  }
  return (
    <>
      <section className="shop-section padding-top">
        <div className="container">
          <div className="row border border-0 border-success">
            <div className="col-lg-9 sm-padding">
              <div className="row ">
                <h1 className='text-center text-uppercase' style={{color:'#9841ff',fontSize:'3.5rem'}}>E-Store</h1>
              
                <div className="product-shorting">
                  <div className='d-none'>Showing 1–6 of 22 results</div>
                  <div>
                    <select
                      name="orderby"
                      className="orderby d-none"
                      aria-label="Shop order"
                    >
                      <option value="popularity">Sort by popularity</option>
                      <option value="rating">Sort by average rating</option>
                      <option value="date">Sort by latest</option>
                      <option value="price">Sort by price: low to high</option>
                      <option value="price-desc">
                        Sort by price: high to low
                      </option>
                    </select>
                  </div>
                </div>
                {
                  productArray === undefined?
                  <div className='conatiner-fluid m-0 p-0'>
                    <div className='row m-0 p-0'>
                      <div className='col-12 m-0 p-5 border border-0 border-danger d-flex align-items-center justify-content-center'>
                        <ReactLoading type='balls' color='#9841ff' height={100} width={100} />
                      </div> 
                    </div>
                  </div>:
                  productArray&&productArray?.filter((item:any)=>item.categories[0]?.name.toLowerCase() === filterType.toLowerCase()).map((item:any)=>{
                    return(
                      item?.name!='Wallet Topup'?
                              <div key={item?.id} className="col-md-4 padding-15">
                                <Link to={`/productDetail/${item?.id}`} state={{data:item}}>
                  <div className="product-card border border-0 border-success">
                    <div className="product-thumb">
                      <img src={item?.images[0]?.src} alt="img" style={{objectFit:'cover'}}/>
                      {/*  badge hot badge in-stock badge out-stock */}
                      <a href="#" className="badge hot d-none">
                        Hot
                      </a>
                      <ul className="shop-action d-none">
                        <li>
                          <a href="#">
                            <i className="lar la-heart"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="las la-retweet"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="las la-expand-arrows-alt"></i>
                          </a>
                        </li>
                      </ul>
                      <Link className="default-btn" to={`/productDetail/${item?.id}`} state={{data:item}}>
                         View {item?.name}
                      </Link>
                    </div>
                    <div className="product-info">
                      <div className="product-inner">
                        <ul className="category">
                          <li>
                            <a href="#">{item?.categories[0]?.name}</a>
                          </li>
                        </ul>
                        <ul className="rating">
                        {/* Add star rating products  */}
                        <StarRating starCount={item?.rating_count}/>
                        </ul>
                      </div>
                      <h3>
                        {/* <Link to={`/productDetail/${item?.id}`} state={{data:item}}> {item?.name}</Link> */}
                        <span> {item?.name}</span>
                      </h3>
                      <h4 className="price">${item?.regular_price}</h4>
                    </div>
                  </div>
                      </Link>
                </div>      
                :null
                  )
                })
                }
                {
                  ((filterType==='')||(filterType==='all'))?<>
                    {
                       productArray&&productArray?.map((item:any)=>{
                        return(
                          item?.name!='Wallet Topup'?
                                  <div key={item?.id} className="col-md-4 padding-15">
                                    <Link to={`/productDetail/${item?.id}`} state={{data:item}}>
                      <div className="product-card border border-0 border-success">
                        <div className="product-thumb">
                          <img src={item?.images[0]?.src} alt="img" style={{objectFit:'cover'}}/>
                          {/*  badge hot badge in-stock badge out-stock */}
                          <a href="#" className="badge hot d-none">
                            Hot
                          </a>
                          <ul className="shop-action d-none">
                            <li>
                              <a href="#">
                                <i className="lar la-heart"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="las la-retweet"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="las la-expand-arrows-alt"></i>
                              </a>
                            </li>
                          </ul>
                          <Link className="default-btn" to={`/productDetail/${item?.id}`} state={{data:item}}>
                             View {item?.name}
                          </Link>
                        </div>
                        <div className="product-info">
                          <div className="product-inner">
                            <ul className="category">
                              <li>
                                <a href="#">{item?.categories[0]?.name}</a>
                              </li>
                            </ul>
                            <ul className="rating">
                            {/* Add star rating products  */}
                            <StarRating starCount={item?.rating_count}/>
                            </ul>
                          </div>
                          <h3>
                            {/* <Link to={`/productDetail/${item?.id}`} state={{data:item}}> {item?.name}</Link> */}
                            <span> {item?.name}</span>
                          </h3>
                          <h4 className="price">${item?.regular_price}</h4>
                        </div>
                      </div>
                          </Link>
                    </div>      
                    :null
                      )
                    })
                    }
                  </>:null
                }
              </div>
              <ul className="pagination-wrap text-left mt-40 d-none">
                <li>
                  <a href="#">
                    <i className="las la-arrow-left"></i>
                  </a>
                </li>
                <li>
                  <a href="#">1</a>
                </li>
                <li>
                  <a href="#" className="active">
                    2
                  </a>
                </li>
                <li>
                  <a href="#">3</a>
                </li>
                <li>
                  <a href="#">
                    <i className="las la-arrow-right"></i>
                  </a>
                </li>
              </ul>
              {/* <!--Pagination--> */}
            </div>
            {/* <!--Shop Grid--> */}
            <div className="col-lg-3 sm-padding">
              <div className="shop-sidebar">
                <div className="shop-sidebar-widget">
                  <div className="shop-widget-title">
                    <h3>
                      <span>||</span> Categories
                    </h3>
                  </div>
                  <ul className="category-list">
                    <li style={{cursor:'pointer'}} onClick={()=>{setfilterType('all')}}>
                       <a className={filterType==='all'?'fw-bold':''}>All</a>
                       <span>{productArray&&productArray?.length}</span>
                      </li>
                    {
                      productCatagories&&productCatagories.map((item:any)=>{return(
                      <li style={{cursor:'pointer'}} key={item?.id} onClick={()=>{setfilterType(item?.name)}}>
                       <a className={filterType===item.name?'fw-bold':''}>{item?.name}</a>
                       <span>{item?.count}</span>
                      </li>
  
                      )})
                    }
                    {/* <li>
                      <a href="#">Jackets &amp; Coats</a>
                      <span>23</span>
                    </li>
                    <li>
                      <a href="#">Loungewear</a>
                      <span>05</span>
                    </li>
                    <li>
                      <a href="#">Polo shirts</a>
                      <span>18</span>
                    </li>
                    <li>
                      <a href="#">Swimwear</a>
                      <span>04</span>
                    </li>
                    <li>
                      <a href="#">T-shirts</a>
                      <span>15</span>
                    </li>
                    <li>
                      <a href="#">Trousers</a>
                      <span>48</span>
                    </li>
                    <li>
                      <a href="#">Accessories</a>
                      <span>75</span>
                    </li>
                    <li>
                      <a href="#">Lingerie</a>
                      <span>13</span>
                    </li>
                    <li>
                      <a href="#">Flip-flops</a>
                      <span>82</span>
                    </li>
                    <li>
                      <a href="#">Rainproof</a>
                      <span>61</span>
                    </li> */}
                  </ul>
                </div>
                {/* <!--Categories--> */}
                <div className="shop-sidebar-widget d-none">
                  <div className="shop-widget-title">
                    <h3>
                      <span>||</span> Filter By Price
                    </h3>
                  </div>
                  <div className="range-slider">
                    <input
                      type="range"
                      min="20"
                      max="500"
                      value="0"
                      id="price-range"
                    />
                    <div className="range-slider-output">
                      <span id="price-output"></span>
                      <a href="#">Filter</a>
                    </div>
                  </div>
                </div>
                {/* <!--Range Slider--> */}
                <div className="shop-sidebar-widget d-none">
                  <div className="shop-widget-title">
                    <h3>
                      <span>||</span> Colors
                    </h3>
                  </div>
                  <ul className="color-list">
                    <li>
                      <label>
                        <input type="checkbox" />
                        <span className="checkmark black"></span>Black
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type="checkbox" />
                        <span className="checkmark green"></span>Green
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type="checkbox" />
                        <span className="checkmark blue"></span>Blue
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type="checkbox" />
                        <span className="checkmark red"></span>Red
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type="checkbox" />
                        <span className="checkmark yellow"></span>Yellow
                      </label>
                    </li>
                  </ul>
                </div>
                {/* <!--Size List--> */}
                <div className="shop-sidebar-widget d-none">
                  <div className="shop-widget-title">
                    <h3>
                      <span>||</span> Size
                    </h3>
                  </div>
                  <ul className="size-list">
                    <li>
                      <a href="#">L</a>
                    </li>
                    <li>
                      <a href="#">M</a>
                    </li>
                    <li>
                      <a href="#">S</a>
                    </li>
                    <li>
                      <a href="#">Xl</a>
                    </li>
                    <li>
                      <a href="#">Xs</a>
                    </li>
                  </ul>
                </div>
                {/* <!--Size List--> */}
                <div className="shop-sidebar-widget d-none">
                  <div className="shop-widget-title">
                    <h3>
                      <span>||</span> Filter By Tags
                    </h3>
                  </div>
                  <ul className="tags">
                    <li>
                      <a href="#">Bags</a>
                    </li>
                    <li>
                      <a href="#">Loungewear</a>
                    </li>
                    <li>
                      <a href="#">Dress</a>
                    </li>
                    <li>
                      <a href="#">Pants</a>
                    </li>
                    <li>
                      <a href="#">Earrings</a>
                    </li>
                    <li>
                      <a href="#">Shirt</a>
                    </li>
                  </ul>
                </div>
                {/* <!--Tags--> */}
              </div>
            </div>
            {/* <!--Shop Sidebar--> */}
          </div>
        </div>
      </section>
    </>
  )
}

// function StarRating({starCount}:{starCount:number}){
//   console.log('Star count of the product is : ',starCount)
//  return(
//   <>
//     {
//       [0,1,2,3,4].map((item:number)=>{return(
//         item<starCount?
//      <li>
//         <i className="las la-star"></i>
//       </li>:null
//       )})
//     }
//   </>
//  )
// }