import React, { useEffect, useState } from 'react'
import { useAllPlayers } from 'utils/apis/coach'
import Badge1 from 'assets/badges/sports-manship.png'
import Badge2 from 'assets/badges/hall-of-fame.png'
import Badge3 from 'assets/badges/goat-b.png'
import Badge4 from 'assets/badges/game-breaker.png'
import Badge5 from 'assets/badges/champ-of-the-week.png'
import Badge6 from 'assets/badges/academic.png'
import Badge7 from 'assets/badges/vr-workout.png'
import Badge8 from 'assets/badges/news-reporter.png'
import Badge9 from 'assets/badges/sports-writer.png'
import Badge10 from 'assets/badges/sports-anchor.png'
import Badge11 from 'assets/badges/yogi-b.png'
import Badge12 from 'assets/badges/hero-b.png'
import { useAssignBadge } from 'utils/apis/coach'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

interface BadgesType {
  bageName: string
  imgUrl: string
  points: number
}

export default function Badges() {
  //declaring data
  const badges = [
    {
      bageName: 'Sportsmanship',
      imgUrl: Badge1,
      points: 0,
    },
    {
      bageName: 'Hall Of Fame',
      imgUrl: Badge2,
      points: 0,
    },
    {
      bageName: 'Goat',
      imgUrl: Badge3,
      points: 0,
    },
    {
      bageName: 'Gamebreaker',
      imgUrl: Badge4,
      points: 0,
    },
    {
      bageName: 'Champ Of The Week',
      imgUrl: Badge5,
      points: 0,
    },
    {
      bageName: 'Academic',
      imgUrl: Badge6,
      points: 0,
    },
    {
      bageName: 'VR Workout',
      imgUrl: Badge7,
      points: 0,
    },
    {
      bageName: 'News Reporter',
      imgUrl: Badge8,
      points: 0,
    },
    {
      bageName: 'Sports Writer',
      imgUrl: Badge9,
      points: 0,
    },
    {
      bageName: 'Sports Anchor',
      imgUrl: Badge10,
      points: 0,
    },
    {
      bageName: 'Yogi Master',
      imgUrl: Badge11,
      points: 0,
    },
    {
      bageName: 'Hero',
      imgUrl: Badge12,
      points: 0,
    },
  ]
  const [playerId, setPlayerId] = useState('')
  const { data: playerData } = useAllPlayers(Number.MAX_SAFE_INTEGER, 0)
  const { mutate: assignBadges, isSuccess, reset } = useAssignBadge()
  const [badgesName, setBadgesName] = useState<BadgesType[]>([])

  const handleAssignBadge = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPlayerId(event.target.value)
    setBadgesName(badges)
  }

  const handleBadgePoints = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const points: number = parseInt(e.target.value)
    const updateBadges = [...badgesName]
    updateBadges[index].points = points
    setBadgesName(updateBadges)
  }

  const handleSubmit = () => {
    const badgesAssign = badgesName.filter(item => item?.points > 0)
    const badgePayload = badgesAssign?.map(item => ({
      badgeName: item.bageName,
      points: item.points,
      userId: playerId,
    }))
    assignBadges(badgePayload)
  }
  useEffect(() => {
    if (isSuccess) {
      toast.success('Badge is assign to the user!', {
        position: toast.POSITION.TOP_RIGHT,
      })
      reset()
      setBadgesName([])
      setPlayerId('')
    }
  }, [isSuccess])

  return (
    <>
      <div className="container">
        <div
          className="section-heading mb-40 text-center wow fade-in-bottom pt-5 pb-2"
          data-wow-delay="200ms"
        >
          <h2>
            Assign <span>Badges</span>
          </h2>
          <p>Select Player to Assign Badges</p>
        </div>
      </div>
      <select
        className="form-control form-input bd-grad text-white my-3 w-25 mx-auto"
        onChange={e => handleAssignBadge(e)}
        value={playerId}
      >
        <option>--Select Player--</option>
        {playerData?.map((item: any) => {
          return (
            <option key={item.id} value={item?.id}>
              {item?.name}
            </option>
          )
        })}
      </select>
      <div className="container">
        <div className="row">
          <div className="d-flex justify-content-between gap-3 border border-0 border-success ">
            <div className="row m-0 p-0 border border-0 border-success">
              {badgesName?.map((item, index) => (
                <div
                  key={item?.bageName}
                  className="col-12 col-sm-6 col-md-4 col-lg-2"
                >
                  <div className="form-field text-center">
                    <img src={item?.imgUrl} className="badges" />
                    <input
                      type="number"
                      id="name"
                      className="form-control form-input bd-grad text-white"
                      placeholder=" Points"
                      value={item?.points}
                      onChange={e => handleBadgePoints(e, index)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {badgesName?.length ? (
          <button className="default-btn mt-5" onClick={handleSubmit}>
            Submit
          </button>
        ) : null}
      </div>
    </>
  )
}
