import React from 'react'
export default function StarRating({starCount}:{starCount:number}){
   return(
    <>
      {
        [0,1,2,3,4].map((item:number)=>{return(
          item<starCount?
       <li>
          <i className="las la-star"></i>
        </li>:null
        )})
      }
    </>
   )
  }