import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { UserLogin } from 'utils/apis/login'
import { userLogin } from 'utils/types/login.type'
import { useNavigate } from 'react-router-dom'
import {  toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
export default function CoachLogin() {
  // const { REACT_APP_Yellas_sports } = process.env
  const navigate = useNavigate()
  const [passwordType, setpasswordType] = useState(true)
  const {
    mutate, // a calback function that is responsible of login the user
    isSuccess, //success check for the user login
    data: response, // response of the mutate as api callback response
  } = UserLogin()

  const handleLogin = (data: userLogin) => {
    mutate(data)
  }
  useEffect(() => {
    // success case when the user is login
    if (isSuccess) {
      localStorage.setItem(
        'access_token',
        response?.access_token?.split(' ')[1],
      )
      if (response?.user?.isAdmin) {
        localStorage.setItem('role', 'Admin')
      }
   
      toast.success('you have login successfully.....', {
        position: toast.POSITION.TOP_RIGHT,
      })
      navigate('/coach-dashboard')
    }
    
  }, [isSuccess])

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<userLogin>({
    defaultValues: {
      email: '',
      password: '',
    },
  })
  // const handleLogin =()=>{

  // }
  // const onSubmit: SubmitHandler<userLogin> = data => console.log(data)
  return (
    <>
      <div className="container">
        <div className="col-md-12 sm-padding py-5">
          <div className="contact-title text-center ">
            <h2>Coach Sign In</h2>
            <p>Enter Username and Password </p>
          </div>
          <div className="contact-form ">
            <form
              action=""
              method="post"
              id="streamListing"
              className="form-horizontal"
              onSubmit={handleSubmit(handleLogin)}
            >
              <div className="report-form-group w-50 mx-auto">
                <div className="form-field ">
                  <input
                    type="text"
                    id="contact_name"
                    className="form-control bd-grad"
                    placeholder="Email"
                    {...register('email', { required: true })}
                  />
                  {errors.email && 'email required'}
                </div>
                <div className="form-field pt-3 border border-0 border-success position-relative">
                  <input
                    type={passwordType ? 'password' : 'text'}
                    id="contact_name"
                    className="form-control bd-grad"
                    placeholder="Passowrd"
                    {...register('password', { required: true })}
                  />
                  <i
                    className="position-absolute fa fa-thin fa-eye-slash"
                    onClick={() => {
                      setpasswordType(!passwordType)
                    }}
                    style={{ right: '5%', top: '50%', cursor: 'pointer' }}
                  ></i>
                  {errors.password && 'password required'}
                </div>
                <div className="form-field pt-4">
                  <button id="submit" className="default-btn" type="submit">
                    Login<span></span>
                  </button>
                </div>
              </div>
              <div id="form-messages" className="alert" role="alert"></div>
            </form>
          </div>
        </div>
        
      </div>
    </>
  )
}
