import React, { useEffect, useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ShopingCart } from 'store'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { BsArrowLeftShort } from 'react-icons/bs'
import EmptyCart from 'assets/img/EmptyCart.svg'
export default function Cart() {
  const [cartItems, setcartItems]: any[] = useState()
  const navigate = useNavigate()
  const [totall, settotall] = useState<number>(0)
  const data: any = useContext(ShopingCart)
  const { setcartCount } = data
  const handleDeleteItems = (id: string) => {
    // alert(id);
    const newArray = cartItems?.filter((item: any) => item.id !== id)
    // console.log('New array is : ',newArray);
    // alert('New array is ...');
    localStorage.setItem('cart', JSON.stringify(newArray))

    setcartItems(newArray)
    setTimeout(() => {
      const newCount = newArray.length
      setcartCount(newCount)
      setTimeout(() => {
        handleTotall(newArray)
      }, 500)
      setTimeout(() => {
        handleDeleteAlert({ message: 'Product is removed from the cart' })
      }, 250)
    }, 1000)
  }
  const handleDeleteAlert = ({ message }: { message: string }) => {
    toast.success(`${message}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    })
  }
  const handleTotall = (cartItems: any) => {
    let bill = 0
    for (let i = 0; i < cartItems.length; i++) {
      bill += cartItems[i]?.productQty * parseInt(cartItems[i]?.regular_price)
    }
    settotall(bill)
  }
  useEffect(() => {
    const products = localStorage.getItem('cart')
    if (products) {
      setcartItems(JSON.parse(products))
      setTimeout(() => {
        setcartCount(JSON.parse(products).length)
        handleTotall(JSON.parse(products))
      }, 1500)
    } else {
      setcartCount(0)
    }
  }, [])
  return (
    <>
      {cartItems?.length ? (
        <section className="cart-section padding-top">
          <div className="container">
            <button
              className="text-light mb-3 fs-4"
              onClick={() => {
                navigate(-1)
              }}
            >
              <BsArrowLeftShort /> Back
            </button>
            <div className="row cart-header">
              <div className="col-lg-6">Product</div>
              <div className="col-lg-3">Quantity</div>
              <div className="col-lg-1">Price</div>
              <div className="col-lg-1">Total</div>
              <div className="col-lg-1"></div>
            </div>
            {cartItems?.map((item: any) => {
              return (
                <div key={item?.id} className="row cart-body pb-30">
                  <div className="col-lg-6">
                    <div className="cart-item">
                      <img
                        style={{ objectFit: 'cover' }}
                        src={item?.images[0]?.src}
                        alt="food"
                      />
                      <div className="cart-content">
                        <h3>
                          <a href="shop-details.html">{item?.name}</a>
                        </h3>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item?.short_description,
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 col-lg-3">
                    <div className="cart-item">
                      <span className="mx-4">{item?.productQty}</span>
                      {/* <input type="number" defaultValue={item?.productQty} min="1" max="10" /> */}
                    </div>
                  </div>
                  <div className="col-3 col-lg-1">
                    <div className="cart-item">
                      <p>${parseInt(item?.regular_price)}.00</p>
                    </div>
                  </div>
                  <div className="col-3 col-lg-1">
                    <div className="cart-item">
                      <p>
                        ${parseInt(item?.regular_price) * item?.productQty}.00
                      </p>
                    </div>
                  </div>
                  <div className="col-2 col-lg-1">
                    <div className="cart-item">
                      <button
                        onClick={() => {
                          handleDeleteItems(item?.id)
                        }}
                        className="btn outline-none d-flex align-items-center justify-content-center remove"
                      >
                        <i className="las la-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
              )
            })}
            <div className="row">
              <div className="col-lg-6 offset-lg-6">
                <ul className="cart-total mt-30">
                  <li className="d-none">
                    <span>Subtotal:</span>${totall}.00
                  </li>
                  <li className="d-none">
                    <span>Estimated shipping:</span>$50
                  </li>
                  <li>
                    <span>Total:</span>${totall}.00
                    {/* <span>Total:</span>${totall+50}.00 */}
                  </li>
                  <li>
                    <Link className="default-btn" to="/estore">
                      Continue Shopping
                    </Link>
                    <Link
                      to={'/checkout'}
                      state={{ data: totall }}
                      className="default-btn"
                    >
                      Checkout
                      <span></span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div className="conatiner-fluid m-0 p-5 d-flex align-items-center justify-content-center">
          <div className="d-flex flex-column p-3 shadow  rounded justify-content-center align-items-center shadow">
            <img
              className="img-fluid"
              width="350px"
              height="350px"
              src={EmptyCart}
            />
            <p className="my-3 text-light fs-3 text-center">
              Your cart is empty
            </p>
            <Link to="/estore" className="default-btn w-25 text-light">
              Shop Now
            </Link>
          </div>
        </div>
      )}
    </>
  )
}
