import React, { Fragment, useState } from 'react'
import AddGame from 'views/dashboard/adminAddGame'
import AddLeague from 'views/dashboard/adminAddLeague'
import CoachTeam from './team'
import CoachLevels from './levels'
// import AllPlayers from 'views/dashboard/adminAllPlayers';
import CoachPlayers from './coachPlayers'
// import CoachScoreReports from 'views/dashboard/adminScoreReport';
import Badges from './badges'
import { useProfile } from 'utils/apis/profile'
import MyCoachScore from './scoreReporting'
export default function MainCoachDashboard() {
  const [state, setState] = useState('team')
  const { data: profileData } = useProfile()
  return (
    <Fragment>
      <div className="container mx-auto">
        {profileData?.team?.name ? (
          <div className="row mt-5">
            <div className="col-12 col-md-2 left-section border bd-grad p-2 dashboard-content bg-grad shadow-lg">
              <h4 className="d-flex align-items-center justify-content-between">
                <span>{profileData?.name}</span>
                <img
                  className="img-fluid m-0 p-0 coach-pic"
                  src={profileData?.profilePicURL}
                />{' '}
              </h4>
              <ul className="cursorHand">
                <li className="py-2">
                  <a
                    className={state === 'team' ? 'fw-bold shadow' : ''}
                    onClick={() => setState('team')}
                  >
                    Team
                  </a>
                </li>
                <li className="py-2">
                  <a
                    className={
                      state === 'scoreReporting' ? 'fw-bold shadow' : ''
                    }
                    onClick={() => setState('scoreReporting')}
                  >
                    Score Reporting
                  </a>
                </li>
                <li className="py-2">
                  <a
                    className={state === 'allPlayer' ? 'fw-bold shadow' : ''}
                    onClick={() => setState('allPlayer')}
                  >
                    Players
                  </a>
                </li>
                <li className="py-2 d-none">
                  <a
                    className={state === 'league' ? 'fw-bold shadow' : ''}
                    onClick={() => setState('league')}
                  >
                    League
                  </a>
                </li>
                <li className="py-2 d-none">
                  <a
                    className={state === 'addGame' ? 'fw-bold shadow' : ''}
                    onClick={() => setState('addGame')}
                  >
                    Games
                  </a>
                </li>
                <li className="py-2">
                  <a
                    className={state === 'bage' ? 'fw-bold shadow' : ''}
                    onClick={() => setState('badge')}
                  >
                    Badges
                  </a>
                </li>
                <li className="py-2">
                  <a
                    className={state === 'level' ? 'fw-bold shadow' : ''}
                    onClick={() => setState('level')}
                  >
                    Levels
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-10 right-section p-2  right-dashboard">
              {/* {team === 'team' ? ( */}
              {state === 'team' && (
                <>
                  <CoachTeam
                    twitchLink={profileData?.team?.twitchLink}
                    twitterLink={profileData?.team?.twitterLink}
                    youtubeLink={profileData?.team?.youtubeLink}
                    teamLogo={profileData?.team?.logoUrl}
                    teamName={profileData?.team?.name}
                  />
                </>
              )}
              {state === 'allPlayer' && (
                <>
                  <CoachPlayers
                    teamId={profileData?.team?.id}
                    id={profileData?.id}
                  />
                </>
              )}
              {state === 'addGame' && (
                <>
                  <AddGame role="coach" />
                </>
              )}
              {state === 'league' && (
                <>
                  <AddLeague />
                </>
              )}
              {state === 'scoreReporting' && (
                <>
                  <MyCoachScore />
                </>
              )}
              {state === 'badge' && (
                <>
                  <Badges />
                </>
              )}
              {state === 'level' && (
                <>
                  <CoachLevels
                    teamId={profileData?.team?.id}
                    id={profileData?.id}
                  />
                </>
              )}
            </div>
          </div>
        ) : (
          <div>
            <div className="section-heading my-4 text-center wow fade-in-bottom pt-5 pb-0 row m-0 p-0 d-flex align-items-center justify-content-center">
              <h2 className="text-center">Congratulations!</h2>
            </div>
            <p className="mx-auto text-center" style={{ maxWidth: '650px' }}>
              You have successfully registered as a coach. We are in the process
              of setting up your team and you will be able to see all the
              details once {`it's`} finished.
            </p>
          </div>
        )}
      </div>
    </Fragment>
  )
}
