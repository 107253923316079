import React, { useEffect } from 'react'
// import teamlogoOne from 'assets/img/team-logo-1.png'
import teamlogoTwo from 'assets/img/team-logo-2.png'
// import reportImg from 'assets/img/reportImg.png'
import { Link, useParams } from 'react-router-dom'
import { useAllTeamScores } from 'utils/apis/home'
import AvatarImg from 'assets/img/avatar.png'

export default function GameReport() {
  const { id } = useParams()
  const { data: TeamsAllScores } = useAllTeamScores(10, 0)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      {id ? (
        TeamsAllScores?.filter((item: any) => item.id == id).map(
          (item: any) => {
            return (
              <div key={item?.id} className="reportData">
                <div className="container bd-grad p-5">
                  <div className="row ">
                    <div className="col-md-6">
                      <div className="upcoming-matches">
                        <div
                          className="report-team wow fade-in-left"
                          data-wow-delay="200ms"
                        >
                          <img
                            src={
                              item?.team1?.logoUrl ||
                              item?.player1?.profilePicURL ||
                              AvatarImg
                            }
                          ></img>
                          <h3>
                            <a>{item?.team1?.name || item?.player1?.name}</a>
                          </h3>
                          <div className="match-info">{item?.team1Result}</div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table bg-gradient bd-grad rounded table-bordered mt-5">
                          <thead>
                            {item?.team1Players.map(
                              (item: any, index: number) => {
                                return (
                                  <tr key={index}>
                                    {Object.keys(item.points).map(
                                      (item2, index: number) => {
                                        return (
                                          <td
                                            key={index}
                                            className="text-white"
                                          >
                                            {item2}
                                          </td>
                                        )
                                      },
                                    )}
                                  </tr>
                                )
                              },
                            )}
                          </thead>
                          <tbody>
                            {item?.team1Players.map(
                              (item: any, index: number) => {
                                return (
                                  <tr key={index}>
                                    {Object.keys(item.points).map(
                                      (item2, index: number) => {
                                        return (
                                          <td
                                            key={index}
                                            className="text-white"
                                          >
                                            {index == 0 ? (
                                              <Link to={`/profile/${item?.id}`}>
                                                {item?.points[item2]}
                                              </Link>
                                            ) : (
                                              <span>{item?.points[item2]}</span>
                                            )}
                                          </td>
                                        )
                                      },
                                    )}
                                  </tr>
                                )
                              },
                            )}
                          </tbody>
                        </table>
                      </div>

                      <p className="text-white">
                        Final Score <span>{item?.team1Points}</span>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <div
                        className="report-team oponent wow fade-in-right"
                        data-wow-delay="200ms"
                      >
                        <h3>
                          <a>{item?.team2?.name || item?.player2?.name}</a>
                        </h3>
                        <div className="match-info">{item?.team2Result}</div>
                        <img
                          src={
                            item?.team2?.logoUrl ||
                            item?.player2?.profilePicURL ||
                            AvatarImg
                          }
                        ></img>
                      </div>
                      <div className="table-responsive">
                        <table className="table bg-gradient bd-grad rounded table-bordered mt-5">
                          <thead>
                            {item?.team2Players.map(
                              (item: any, index: number) => {
                                return (
                                  <tr key={index}>
                                    {Object.keys(item.points).map(
                                      (item2, index: number) => {
                                        return (
                                          <td
                                            key={index}
                                            className="text-white"
                                          >
                                            {item2}
                                          </td>
                                        )
                                      },
                                    )}
                                  </tr>
                                )
                              },
                            )}
                          </thead>
                          <tbody>
                            {item?.team2Players.map(
                              (item: any, index: number) => {
                                return (
                                  <tr key={index}>
                                    {Object.keys(item.points).map(
                                      (item2, index: number) => {
                                        return (
                                          <td
                                            key={index}
                                            className="text-white"
                                          >
                                            {index < 1 ? (
                                              <Link to={`/profile/${item?.id}`}>
                                                {item?.points[item2]}
                                              </Link>
                                            ) : (
                                              <span>{item?.points[item2]}</span>
                                            )}
                                          </td>
                                        )
                                      },
                                    )}
                                  </tr>
                                )
                              },
                            )}
                          </tbody>
                        </table>
                      </div>
                      <p className="text-white">
                        Final Score <span>{item?.team2Points}</span>
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <img
                      src={item?.imageUrl}
                      className="img-fluid reportSize"
                    />
                  </div>
                  <div className="row py-3">
                    <h2>Game Note</h2>
                    <p>{item?.gameNotes}</p>
                    <Link
                      className="default-btn w-25"
                      target={'_blank'}
                      to={item?.videoLink}
                    >
                      Click Here To view Game Link
                    </Link>
                  </div>
                </div>
              </div>
            )
          },
        )
      ) : (
        <>
          {TeamsAllScores &&
            TeamsAllScores[0]?.map((item: any) => {
              return (
                <div key={item?.id} className="reportData">
                  <div className="container bd-grad p-5">
                    <div className="row ">
                      <div className="col-md-6">
                        <div className="upcoming-matches">
                          <div
                            className="report-team wow fade-in-left"
                            data-wow-delay="200ms"
                          >
                            <img src={item?.team1?.logoUrl}></img>
                            <h3>
                              <a href="team-details.html">
                                {item?.team1?.name}
                              </a>
                            </h3>
                            <div className="match-info">
                              {item?.team1Result}
                            </div>
                          </div>
                        </div>
                        <div className="table-responsive">
                          <table className="table bg-gradient bd-grad rounded table-bordered mt-5">
                            <thead>
                              {item?.team1Players.map(
                                (item: any, index: number) => {
                                  return (
                                    <tr key={index}>
                                      {Object.keys(item.points).map(
                                        (item2, index: number) => {
                                          return (
                                            <td
                                              key={index}
                                              className="text-white"
                                            >
                                              {item2}
                                            </td>
                                          )
                                        },
                                      )}
                                    </tr>
                                  )
                                },
                              )}
                            </thead>
                            <tbody>
                              {item?.team1Players.map(
                                (item: any, index: number) => {
                                  return (
                                    <tr key={index}>
                                      {Object.keys(item.points).map(
                                        (item2, index: number) => {
                                          return (
                                            <td
                                              key={index}
                                              className="text-white"
                                            >
                                              {item.points[item2]}
                                            </td>
                                          )
                                        },
                                      )}
                                    </tr>
                                  )
                                },
                              )}
                            </tbody>
                          </table>
                        </div>

                        <p className="text-white">
                          Final Score <span>{item?.team1Points}</span>
                        </p>
                      </div>
                      <div className="col-md-6">
                        <div
                          className="report-team oponent wow fade-in-right"
                          data-wow-delay="200ms"
                        >
                          <h3>
                            <a href="team-details.html">{item?.team2?.name}</a>
                          </h3>
                          <div className="match-info">{item?.team2Result}</div>
                          <img src={teamlogoTwo}></img>
                        </div>
                        <div className="table-responsive">
                          <table className="table bg-gradient bd-grad rounded table-bordered mt-5">
                            <thead>
                              {item?.team2Players.map(
                                (item: any, index: number) => {
                                  return (
                                    <tr key={index}>
                                      {Object.keys(item.points).map(
                                        (item2, index: number) => {
                                          return (
                                            <td
                                              key={index}
                                              className="text-white"
                                            >
                                              {item2}
                                            </td>
                                          )
                                        },
                                      )}
                                    </tr>
                                  )
                                },
                              )}
                            </thead>
                            <tbody>
                              {item?.team2Players.map(
                                (item: any, index: number) => {
                                  return (
                                    <tr key={index}>
                                      {Object.keys(item.points).map(
                                        (item2, index: number) => {
                                          return (
                                            <td
                                              key={index}
                                              className="text-white"
                                            >
                                              {item?.points[item2]}
                                            </td>
                                          )
                                        },
                                      )}
                                    </tr>
                                  )
                                },
                              )}
                            </tbody>
                          </table>
                        </div>
                        <p className="text-white">
                          Final Score <span>{item?.team2Points}</span>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <img src={item?.imageUrl} className="img-fluid" />
                    </div>
                    <div className="row py-3">
                      <h2>Game Note</h2>
                      <p>{item?.gameNotes}</p>
                      <Link
                        className="default-btn w-25"
                        target={'_blank'}
                        to={item?.videoLink}
                      >
                        Click Here To view Game Link
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })}
        </>
      )}
    </>
  )
}
