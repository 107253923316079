import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"
import { client } from "./api-client"
type GamePlayers = {
    tagLine:string
    age:number
    nationality:string
    name:string
    email:string
    school:string
    teamId:string
    profilePicURL:string
    youtubeLinkURL:string
    twitchLinkURL:string
    pasword:string
    isAdmin:boolean
    isCoach:boolean
    skillset:string
    isDeleted:boolean
    createdAt:string
    updatedAt:string
    playerLevel:number
    id:string
    team:any
}
export const getAllPlayers = (limit:number,offset:number)=>
useQuery<GamePlayers[],Error>(
    ['getAllPlayers'],
    ()=>client(`user/getAllUsers?limit=${limit}&offset=${offset}`)
)
export const useplayerUpdate =()=>{
    const queryClient = useQueryClient();
    return useMutation<any,Error,any>((data)=>
    client(`user/updateUser/${data?.id}`,{data:data.payload}),
    {
        onSuccess(){
            queryClient.invalidateQueries(["getAllPlayers"])
        }
    }
    )
}
export const usePlayerDelete = () =>{
    const queryClient = useQueryClient();
    return useMutation<any,Error>((id)=>
       client(`user/deleteUser/${id}`,{data: {}}),
       {
        onSuccess(){
            queryClient.invalidateQueries(["getAllPlayers"])
        }
       }
     
    )
}
