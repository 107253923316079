import React from 'react'
import OrderConfirmation from 'assets/img/OrderConfimation.svg'
import { Link } from 'react-router-dom'
import {  useProfile } from 'utils/apis/profile'
export const Congratulation  = () => {
  const { data: profileData} = useProfile();
  return (
    <div className='conatiner-fluid m-0 p-0' style={{height:'100vh'}}>
        <div className='w-100 d-flex flex-column align-items-center justify-content-center'>
          <img className='mt-5 my-2 m-0 p-0 img-fluid ' src={OrderConfirmation} width="350px" height="350px"/>
          <p className='my-4'>Thankyou for your purchase!</p>
          <span className='my-3'>A confirmation email has been sent to <small className='fw-bold'>{profileData&&profileData?.email}</small></span>
          <Link to="/estore" className='default-btn'> Return to store</Link>
        </div>
    </div>
  )
}
