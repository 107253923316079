import { useQuery } from '@tanstack/react-query'
import { client } from './api-client'
type Games = {
  name: string
  date: string
  season: string
  logoUrl: string
  isDeleted: false
  resultsKeys: string[]
  createdAt: string
  updatedAt: string
  id: string
}


export const getAllLeagues = (limit: number, offset: number) =>
  useQuery<Games[], Error>(['getAllgames'], () =>
    client(`league/getAllLeagues?limit=${limit}&offset=${offset}`),
  )
