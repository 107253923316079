import React,{useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { getAllTeams } from 'utils/apis/score-reporting'
import { getAllPlayers,useplayerUpdate,usePlayerDelete } from 'utils/apis/players'
import { SubmitHandler,useForm } from 'react-hook-form'
import { useUploadFile } from 'utils/apis/signup'
import { Role } from 'utils/types/adminType'
import {  toast } from 'react-toastify';
import DeleteModal from 'components/deleteModal'
import 'react-toastify/dist/ReactToastify.css';
export default function AllPlayers({role}:Role) {
  // States of the components (START)
  const [editTeam,seteditTeam] = useState<number|any>();
  const [show, setShow] = useState(false);
  const [playerName,setplayerName] = useState('');
  const [showDelete, setshowDelete] = useState(false);
  // States of the components (END)
  // API calling for getting the required data (START)
   const {data} = getAllPlayers(40,0);
   const { mutate:deletePlayer,isSuccess } = usePlayerDelete()
  // API calling for getting the required data (END)
  // Functions of the component (START)
  const handleDeleteClose = () => setshowDelete(!showDelete);
  const handleClose = () => setShow(false)
  const [deletePlayerId,setdeletePlayerId] = useState<string|any>();
  const handleShow = (team:any ) => {
    seteditTeam(team);
    modalOpenClese();
  }
  const modalOpenClese = ()=>{
    setShow(!show);
  }
  const handleDeleteModalShow=(id:string)=>{
    setdeletePlayerId(id);
    handleShowDeleteModal();
  }
  const handleShowDeleteModal = () =>{
    setshowDelete(!showDelete);
  }
  const handleDelete = ()=>{
    // alert(`Delete player id is : ${deletePlayerId}`)
    // deleteTeam(deletePlayerId);
    deletePlayer(deletePlayerId)
    handleDeleteClose();
  }
  // Functions of the component (END)
  useEffect(()=>{
    if(isSuccess)
    {
      
      toast.success('Player is deleted successfully...', {
        position: toast.POSITION.TOP_RIGHT
       });
    }
 
  },[isSuccess])
  return (
    <>
      <div className="container">
        <div className="section-heading text-center py-5 ">
          <h2>
            All <span>Player</span>
          </h2>
        </div>
      </div>
      <div className="container ">
        <div className="crud shadow-lg p-3 mb-5 bg-gradient rounded">
          <div className="row ">
            <div className="col-sm-3  mb-4 text-gred">
              <div className="search">
                <form className="form-inline">
                  <input
                    onChange={(e:any)=>{setplayerName(e.target.value)}}
                    className="form-control mr-sm-2- bg-grad"
                    type="search"
                    placeholder="Search "
                    aria-label="Search"
                  />
                </form>
              </div>
            </div>
            <div
              className="col-sm-3 offset-sm-2  mb-4 text-gred"
              style={{ color: 'green' }}
            >
              <p className="text-white"></p>
            </div>
          </div>
          <div className="row">
            <div className="table-responsive ">
              <table className="table  bg-gradient text-white">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Positions</th>
                    <th>Level</th>
                    <th>Team</th>
                    <th>School</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {
                  role==='coach'?<tbody>
                    <tr>
                      <td><p>This is test admin</p></td>
                    </tr>
                  </tbody>:
                <tbody>
                  {
                   data?.filter((item)=>
                   {
                    return playerName.toLowerCase() ===''?item:item?.name.toLowerCase().includes(playerName)
                   }  
                   ).map((item)=>{return(
                    (item?.isAdmin||item?.isCoach)?null:
                      <tr key={item.id}>
                      <td className="text-white">{item?.name}</td>
                      <td className="text-white">{item?.skillset}</td>
                      <td className="text-white">{item?.playerLevel>=1?item?.playerLevel:0}</td>
                      <td className="text-white">{item?.team?.name}</td>
                      <td className="text-white">
                        {item?.school}
                      </td>
                      <td>
                        <Link
                          to={`/profile/${item.id}`}
                          className="edit"
                          title="View"
                          data-toggle="tooltip"
                        >
                          <i className="material-icons">&#xE417;</i>
                        </Link>
                        <a
                          href="#"
                          className="edit"
                          title="Edit"
                          data-toggle="tooltip"
                        >
                          <i className="material-icons" onClick={()=>{handleShow(item)}}>
                            &#xE254;
                          </i>
                        </a>
                        <a
                          className="delete"
                          title="Delete"
                          data-toggle="tooltip"
                        >
                          <i className="material-icons" style={{cursor:'pointer'}} onClick={()=>{handleDeleteModalShow(item?.id)}}>&#xE872;</i>
                        </a>
                      </td>
                    </tr>                         
                    )})
                  }
                </tbody>
                }
              </table>
            </div>
          </div>
          {
            show?<UpdatePlayer show={show} data={editTeam} games={data} onClose={handleClose} />:null
          }
          {
          showDelete?<DeleteModal title='Delete Player' message='Are you sure that you want to delete this player ?' show={showDelete} onClose={handleDeleteClose} onDelete={handleDelete} deleteTeamId={deletePlayerId}/>:null
          }
        </div>
        
      </div>
    </>
  )
}
type UserUpdate = {
    name: string,
    email: string,
    school: string,
    teamId: string,
    teamLogoURL: string,
    profilePicURL: string,
    youtubeLinkURL: string,
    twitchLinkURL: string,
    skillset: string
}
function UpdatePlayer (props:any) {
  const {data} = getAllTeams(10,0);
  const { register,
    handleSubmit,  
    reset
    } = useForm<UserUpdate>({});
    const {
      mutate: uploadTeamLogo,
      data: imageData,
      // isSuccess: imageUploaded,
      // isLoading: isImgUploaded,
      // isError: errorUploadingImg,
    } = useUploadFile();
    const {mutate:updateUser,isSuccess:isUserUpdate} = useplayerUpdate();
    const onUpdate: SubmitHandler<UserUpdate> = (data) =>{
      const dataPayload = {
        id:props?.data?.id,
        payload:{
          ...data,
          profilePicURL:imageData?.url,
        }
      }
      updateUser(dataPayload);

      // console.log('data of the form : ',dataPayload);
      // console.log('image url is : ',imageData);
    }
    const handleUploadMedia = (e:any) =>{
      uploadTeamLogo({folderName: "profileLogo", field: "profileLogoUrl", file: e.target.files[0]});
    }
  useEffect(()=>{
      if(isUserUpdate){
        toast.success('Player is updated successfully.....', {
          position: toast.POSITION.TOP_RIGHT
         });
         setTimeout(()=>{
          reset({
            name:'',
            email:'',
            school:'',
            skillset:'',
            teamId:'',
            youtubeLinkURL:'',
            twitchLinkURL:'',
            profilePicURL:''
          })
          props.onClose();
         },2500)
      }
  },[isUserUpdate])
  return(
    <div className="model_box">
    <Modal
      show={props?.show}
      onHide={props?.onClose}
      backdrop="static"
      keyboard={false}
      className="bg-gradient"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Player Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form 
        onSubmit={handleSubmit(onUpdate)}
        >
          <div className="form-group ">
            <label className='mb-1'>Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              defaultValue={props?.data?.name}
              placeholder="Player Name"
              {...register('name',{required:false})}
            />
          </div>
          <div className="form-group mt-3">
            <label className='mb-1'>Email</label>
            <input
              type="email"
              className="form-control mt-3"
              id="email"
              defaultValue={props?.data?.email}
              placeholder="email"
              {...register('email',{required:false})}
            />
          </div>
          <div className="form-group mt-3">
            <label>School Name</label>
            <input
              type="text"
              className="form-control mt-3"
              id="school"
              defaultValue={props?.data?.school}
              placeholder="school"
              {...register('school',{required:false})}
            />
          </div>
  
          <div className="form-group mt-3">
            <label>Skill Set</label>
            <input
              type="text"
              className="form-control mt-3"
              id="skillset"
              placeholder="Bio"
              defaultValue={props?.data?.skillset}
              {...register('skillset',{required:false})}
            />
          </div>
          <div className="form-group mt-3">
            <label>Team</label>
          <select
           id='teamId'
           className="form-control text-dark"
           defaultValue={props?.data?.team?.id}
           {...register('teamId',{required:false})}
           >
            {
              data?.map((item)=>{return(
                <option className='text-dark' key={item.name} value={item?.id}>{item.name}</option>
              )})

            }
          </select>
          </div>       
          <div className="form-group mt-3">
            <label>Youtube Link</label>
            <input
              type="text"
              className="form-control"
              id="youtubeLinkURL"
              placeholder="Youtube Link"
              defaultValue={props?.data?.youtubeLinkURL}
              {...register('youtubeLinkURL',{required:false})}
            />
          </div>
          <div className="form-group mt-3">
            <label>Twitch Link</label>
            <input
              type="text"
              className="form-control"
              id="twitchLinkURL"
              placeholder="Twitch Link"
              defaultValue={props?.data?.twitchLinkURL}
              {...register('twitchLinkURL',{required:false})}
            />
          </div>
          
          <div className='py-3 d-flex justify-content-center  align-items-center w-100 ' >
              <img className='img-fluid rounded' style={{height:'135px'}} src={props?.data?.profilePicURL}/>
          </div>
          <div className="form-group mt-3">
            <label>Profile Picture</label>
            <input
              type="file"
              className="form-control"
              id="profilePicURL"
              placeholder="Upload Profile Photo"
              onChange={handleUploadMedia}
            />
          </div>
          <div className='d-flex align-items-center justify-content-center'>
             <button type="submit" className="btn btn-success w-25 mt-4">
               Update
             </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  </div>
  )
}

