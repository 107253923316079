import React, { useEffect, useId, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useIsLogin } from 'hooks'
import { useProfile } from 'utils/apis/profile'
import { BsArrowLeftShort } from 'react-icons/bs'
import { toast } from 'react-toastify'
import { useUpdateDigitalDollars } from 'utils/apis/profile'
import { placeOrder } from 'utils/helpers'
export default function Checkout() {
  const navigate = useNavigate()
  const [ loader, setLoader ] = useState(false)
  const location = useLocation()
  const { isLogin } = useIsLogin()
  const paymentId = useId()
  let cartItems = localStorage.getItem('cart')
  cartItems = cartItems ? JSON.parse(cartItems) : []
  const { data: profileData } = useProfile()
  const {
    mutate: getPayed,
    isSuccess,
    isError,
    isLoading,
  } = useUpdateDigitalDollars()
  const data = location?.state?.data

  const handlePayment = async () => {
    if (profileData && data > profileData?.digitalDollarsBalance) {
      toast.warning('You have insufficient balance.')
    } else if (cartItems?.length) {
      setLoader(true)
      const e_data = {
        payment_method: paymentId,
        payment_method_title: 'Digital Dollars',
        set_paid: true,
        billing: {
          first_name: profileData?.name,
          country: profileData?.nationality,
          email: profileData?.email,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        line_items: (cartItems as any)?.map((product: any) => ({
          product_id: product?.id,
          quantity: product?.productQty,
          price: product?.price,
        })),
        // shipping_lines: [
        //   {
        //     method_id: 'flat_rate',
        //     method_title: 'Flat Rate',
        //     total: '70.00',
        //   },
        // ],
      }
      const isPlaced = await placeOrder(e_data)
      // return
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (isPlaced?.order_key) {
      setLoader(false)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const dat: any = {
          rdd: data,
          userData: profileData,
        }
        getPayed(dat)
      }
    }
  }

  useEffect(() => {
    if (isSuccess) {
      navigate('/congratulation')
      localStorage.removeItem('cart')
    }
    if (isError) {
      toast.warning('You payment is unsuccessful due to network failure!')
    }
  }, [isError, isSuccess])

  console.log(!cartItems?.length, data)
  return (
    <>
      <section className="checkout-section padding-top">
        {isLogin ? (
          <div className="container">
            <div className="row flex-column align-items-center justify-content-center">
              <button
                className="text-start text-light mb-3 fs-4"
                onClick={() => {
                  navigate(-1)
                }}
              >
                <BsArrowLeftShort /> Back
              </button>

              <div className="col-lg-4 sm-padding">
                <h3>Payment</h3>

                <ul className="cart-total">
                  <li>
                    <span>Total Amount: </span>${data}
                  </li>
                  <li>
                    <span>Wallet:</span>$
                    {profileData && profileData?.digitalDollarsBalance}
                  </li>
                  <li className="d-flex align-items-center justify-content-center">
                    <button
                      className="default-btn"
                      disabled={!cartItems?.length}
                      onClick={handlePayment}
                    >
                      {(isLoading || loader) ? (
                        <div className="spinner-border mt-2" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        'Complete Payment'
                      )}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div className="conatiner-fluid m-0 p-0">
            <div className="row m-0 p-0">
              <p className="text-center">Please login before proceeding...</p>
            </div>
          </div>
        )}
      </section>
    </>
  )
}
