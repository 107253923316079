import React, { useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { useEditUserProfile, useUploadFile } from 'utils/apis/signup'
import { EditProfile } from 'utils/types/signup.type'
import { toast } from 'react-toastify'

import { useProfile } from 'utils/apis/profile'
export default function EditStudentProfile() {
  const { data: profileData } = useProfile()
  const { mutate: editProfile, isSuccess, isLoading } = useEditUserProfile() //this is a custom hook for registering the user
  const {
    mutate: uploadFile,
    data: imageData,
    isLoading: imageUploading,
  } = useUploadFile() //  this is the custom hook for uploading the file

  const navigate = useNavigate()
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    getValues,
    control,
    reset,
  } = useForm<EditProfile>({
    defaultValues: {
      highlightsArr: [{ url: '' }],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'highlightsArr', // unique name for your Field Array
  })

  const handleUpdateProfile = (data: EditProfile) => {
    if (data.youtubeLinkURL.includes('youtube')) {
      if (!data?.profilePicURL?.length) {
        const payload = {
          ...data,
          highlights: data?.highlightsArr?.map(item => item?.url),
          profilePicURL: profileData?.profilePicURL,
        }
        editProfile(payload)
      } else {
        handleUploadMedia()
      }
    } else {
      toast.error('Please provide a valid youtube link.....', {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }

  const handleUploadMedia = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const files: any = watch('profilePicURL')
    if (files?.[0])
      uploadFile({
        folderName: 'user',
        field: 'userProfile',
        file: files?.[0],
      })
  }

  useEffect(() => {
    if (profileData?.highlights) {
      reset({
        highlightsArr: profileData?.highlights?.map(url => ({ url })) || [
          { url: '' },
        ],
      })
    }
  }, [profileData])

  useEffect(() => {
    if (imageData) {
      const data = getValues()
      const payload = {
        ...data,
        highlights: data?.highlightsArr?.map(item => item?.url),
        profilePicURL: imageData?.url,
      }
      editProfile(payload)
    }
  }, [imageData])

  useEffect(() => {
    if (isSuccess) {
      toast.success('Profile updated successfully!', {
        position: toast.POSITION.TOP_RIGHT,
      })
      setTimeout(() => {
        navigate('/profile')
      }, 2500)
    }
  }, [isSuccess])

  return (
    <>
      <div className="Studentform">
        <section className="contact-section ">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sm-padding py-5 px-5 lg:w-75 mx-auto">
                <div className="contact-title text-center">
                  <h2>
                    Edit <span>Profile</span>
                  </h2>
                </div>
                <div className="contact-form">
                  {profileData && (
                    <form
                      action=""
                      method="POST"
                      id="studentLogin"
                      className="form-horizontal"
                      onSubmit={handleSubmit(handleUpdateProfile)}
                    >
                      <div className="contact-form-group">
                        <div
                          className="form-field position-relative"
                          style={{ gridColumn: '1/3' }}
                        >
                          <div className="page-header-info player-details">
                            <div className="player-thumb">
                              <img
                                src={profileData?.profilePicURL}
                                className="profile-image"
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="form-field">
                            <label className="mb-2">
                              Update profile picture
                            </label>
                            <input
                              type="file"
                              // defaultValue={profileData?.profilePicURL}
                              id="profilePicURL"
                              className="form-control bd-grad"
                              {...register('profilePicURL')}
                              // onChange={handleUploadMedia}
                            />
                          </div>
                        </div>
                        <div></div>

                        <div className="form-field">
                          <label className="mb-2">Name</label>
                          <input
                            type="text"
                            id="student_name"
                            className="form-control bd-grad"
                            placeholder="Name"
                            defaultValue={profileData?.name}
                            {...register('name', {
                              required: true,
                              maxLength: {
                                value: 20,
                                message: 'Max 20 characters allowed.',
                              },
                            })}
                          />
                          {(errors.name && errors.name?.message) ||
                            'Name is required'}
                        </div>

                        <div className="form-field">
                          <label className="mb-2">Youtube Link</label>
                          <input
                            type="text"
                            id="youtubeLink"
                            className="form-control bd-grad"
                            placeholder="Youtube Link"
                            {...register('youtubeLinkURL')}
                            defaultValue={profileData?.youtubeLinkURL}
                          />
                        </div>
                        <div className="form-field">
                          <label className="mb-2">Twitch Link</label>
                          <input
                            type="text"
                            id="twichLink"
                            className="form-control bd-grad"
                            placeholder="Twitch Link "
                            {...register('twitchLinkURL')}
                            defaultValue={profileData?.twitchLinkURL}
                          />
                        </div>
                        <div className="form-field">
                          <label className="mb-2">School</label>
                          <input
                            type="text"
                            className="form-control bd-grad"
                            disabled
                            defaultValue={profileData?.school}
                          />
                        </div>
                        <div className="form-field">
                          <label className="mb-2">Team</label>
                          <input
                            type="text"
                            className="form-control bd-grad"
                            disabled
                            defaultValue={profileData?.team?.name}
                          />
                        </div>
                        <div className="form-field">
                          <label className="mb-2">Highlights</label>
                          {fields.map((field, index) => (
                            <div
                              className="d-flex align-items-center gap-3"
                              key={field.id} // important to include key with field's id
                            >
                              <input
                                className={`form-control bd-grad ${
                                  index > 0 ? 'mt-3' : ''
                                }`}
                                placeholder="Youtube or Twitch link "
                                defaultValue={profileData?.highlights[index]}
                                {...register(`highlightsArr.${index}.url`, {
                                  validate: {
                                    isValidLink: value =>
                                      value
                                        ? value.includes('youtube') ||
                                          value.includes('twitch')
                                        : true,
                                  },
                                })}
                              />
                              {index > 0 && (
                                <i
                                  onClick={() => remove(index)}
                                  className="las la-minus-circle fa-2x cursorHand"
                                ></i>
                              )}
                            </div>
                          ))}
                          <p className="text-danger">
                            {errors?.highlightsArr &&
                              'Enter valid youtube or twitch link.'}
                          </p>

                          <button
                            type="button"
                            className="text-white mt-2 ml-auto d-block"
                            onClick={() => append({ url: '' })}
                          >
                            Add Link
                          </button>
                        </div>
                        <div className="form-field message">
                          <p>
                            MY SKILLSET OR POSITION (GIVE THE POSITION YOU PLAY,
                            DESCRIBE HOW YOU PLAY THE GAME & HOW YOU LEAD)
                          </p>
                          <textarea
                            id="message"
                            className="form-control bd-grad"
                            {...register('skillset')}
                            defaultValue={profileData?.skillset}
                          ></textarea>
                        </div>
                        <div className="form-field btn-group">
                          <button
                            id="submit"
                            className="default-btn"
                            // type="button"
                            disabled={isLoading || imageUploading}
                          >
                            {isLoading || imageUploading
                              ? 'Submitting......'
                              : 'Update'}
                          </button>
                          <Link
                            to="/profile"
                            className="default-btn"
                            style={{ background: '#9841ff' }}
                          >
                            Cancel
                          </Link>
                        </div>
                      </div>
                      {isSuccess && (
                        <div id="form-messages" className="alert" role="alert">
                          Your Form Submitted
                        </div>
                      )}
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
