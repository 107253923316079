import React from 'react'
import { Navigate } from 'react-router-dom'
// import { parseJwt } from 'utils/helper'

export default function PrivateRoute({ children }: { children: JSX.Element }) {
  const token = localStorage.getItem('access_token')
  if (token) {
    return children 
  }
  return <Navigate to="/" />
}