// import { useMutation } from "@tanstack/react-query"; //is a react-qurey hook which is responsible holding the data that we need to send to the server
// import { ISignup } from "utils/types/signup.type";
// import { UploadedFileResponse, UploadFileType } from "utils/types/uploadMedia.type";
// import { client } from "./api-client";//Client is just like the axios which is responsible for quering the DB
// export const useUserSignup = () =>
//   useMutation<unknown, Error, ISignup>((data) =>
//     client(`auth/signup`, {
//       data,
//     }),
//   )
//   // useUploadFile:()=> { This is a function which is responsible for uploading the image of the user or any image}
//   export const useUploadFile = () =>
//   useMutation<UploadedFileResponse, Error, UploadFileType>(data => {
//     const formData = new FormData()
//     formData.append('file', data?.file)
//     return client(`media-upload/mediaFiles/${data?.folderName}`,{data: formData})
//   })
import { useMutation } from '@tanstack/react-query' //is a react-qurey hook which is responsible holding the data that we need to send to the server
import { EditProfile, ISignup } from 'utils/types/signup.type'
import {
  UploadedFileResponse,
  UploadFileType,
} from 'utils/types/uploadMedia.type'
import { client } from './api-client' //Client is just like the axios which is responsible for quering the DB

export const useUserSignup = () =>
  useMutation<unknown, Error, ISignup>(data =>
    client(`auth/signup`, {
      data,
    }),
  )
export const adminSignup = () =>
  useMutation<unknown, Error, ISignup>(data =>
    client(`auth/coachSignup`, {
      data,
    }),
  )
// useUploadFile:()=> { This is a function which is responsible for uploading the image of the user or any image}
export const useUploadFile = () =>
  useMutation<UploadedFileResponse, Error, UploadFileType>(data => {
    const formData = new FormData()
    formData.append('file', data?.file)
    return client(`media-upload/mediaFiles/${data?.folderName}`, {
      data: formData,
    })
  })

export const useEditUserProfile = () =>
  useMutation<unknown, Error, EditProfile>(data =>
    client(`user/updateLoggedInUser`, {
      data,
    }),
  )
