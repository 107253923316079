import React, {useState,useEffect} from 'react'
import { useUploadFile } from 'utils/apis/signup'
// import { getAllLeagues } from 'utils/apis/Leagues'
import { SubmitHandler,useForm } from 'react-hook-form'
import { getAllTeams,useAddTeam,useDeleteTeam,useTeamUpdate } from 'utils/apis/score-reporting'
import teamlogoOne from 'assets/img/team-logo-1.png';
import { Modal } from 'react-bootstrap'
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Role } from 'utils/types/adminType'
import { MyValues } from 'utils/types/adminType'
import { useAllCoaches } from 'utils/apis/coach'
import DeleteModal from 'components/deleteModal';
export default function AddTeam({role}:Role) {
  const [show, setshow] = useState(false)
  const [showDelete, setshowDelete] = useState(false)
  const {data:allTeams} = getAllTeams(10,0);
  const handleClose = () => setshow(!show);
  const handleDeleteClose = () => setshowDelete(!showDelete);
  const [editTeam,seteditTeam] = useState<number|any>();
  const [deleteTeamId,setdeleteTeamId] = useState<string>();
  const handleShow = (team:any ) => {
    // seteditTeam(teamId);
    seteditTeam(team);
      modalOpenClese();
  }
  const modalOpenClese = ()=>{
    setshow(!show);
  }
  const handleDeleteModalShow=(id:string)=>{
    setdeleteTeamId(id);
    handleShowDeleteModal();
  }
  const handleShowDeleteModal = () =>{
    setshowDelete(!showDelete);
  }
  const [teamName,setteamName] = useState('');
  // const {data:getAllgames} = getAllLeagues(10,0);
  const {data:allCoaches} = useAllCoaches(20,0);
  const {
    mutate:createTeam,
    isSuccess:teamCreate,isLoading:createTeamLoading,isError:errorTeamCreation} = useAddTeam();
  const {
    mutate:deleteTeam,
    isSuccess:teamDelete} = useDeleteTeam();
  const {
    mutate: uploadFile,
    data: imageData,
    // isSuccess: imageUploaded,
    isLoading: isImgUploaded,
    isError: errorUploadingImg,
  } = useUploadFile() //  this is the custom hook for uploading the file
  // const { data: getAllgames } = getAllLeagues(10, 0) //getting all games
  const { register,
     handleSubmit, 
     reset
     } = useForm<MyValues>({})
     const onSubmit: SubmitHandler<MyValues> = (data) => {
      // const selectedGameId = getAllgames?.filter(item=>item.name==data.leagueId)
      const payload ={
        ...data,
        logoUrl:imageData?.url,
        // leagueId:selectedGameId&&selectedGameId[0]?.id
      }
      if(!errorUploadingImg){
        createTeam(payload);
      }
    }
    const handleUploadMedia = (e:any)=>{
     uploadFile({ folderName: "teamLogo", field: "teamLogoUrl", file: e.target.files[0] })
    }
    const handleDelete = ()=>{
      //  alert(`Delete team id is : ${deleteTeamId}`)
      deleteTeam(deleteTeamId);
      handleDeleteClose();
    }
    useEffect(()=>{
      if(!errorTeamCreation){
        if(teamCreate){
          toast.success('Team is craeted successfully.....', {
            position: toast.POSITION.TOP_RIGHT
           });
           reset({
            name:'',
            logoUrl:'',
            mode:'',
            coachId:'',
            season:'',
            youtubeLink:'',
            twitchLink:'',
            twitterLink:''
           })
        }
      }
      if(teamDelete)
      {
        toast.success('Team is deleted successfully.....', {
          position: toast.POSITION.TOP_RIGHT
         });
      }
      
    },[teamCreate,errorTeamCreation,teamDelete])
     
     return (
    <div className="container">
      <div
        className="section-heading mb-40 text-center wow fade-in-bottom pt-5 pb-0"
        data-wow-delay="200ms"
      >
        <h2>
          ADD <span>TEAM</span>
        </h2>
      </div>
      <div className='conatiner m-0 p-0'>

      </div>
      <form
        action=""
        method="post"
        id="liveGame"
        className="form-horizontal"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="d-flex justify-content-between gap-3 mt-5">
       
          <div className="form-field w-100">
            <label>Name</label>
            <input
              type="text"
              id="name"
              className="form-control form-input bd-grad text-white"
              placeholder="Team Name"
              {...register('name', { required: false })}
            />
          </div>
          <div className="form-field w-100">
            <label>
              Logo URL      
            </label>
                <input
                  type="file"
                  className="form-control bd-grad"
                  placeholder=""
                  id="logoUrl"
                  {...register('logoUrl')}
                  onChange={handleUploadMedia}
                />
          </div>
        </div>    
        <div className="d-flex justify-content-between gap-3 mt-5">
          {/* <div className="form-field w-100">
            <label>Choose Mode</label>
            <select
                  className="form-control form-input bd-grad text-white"
                  id="mode"
                  {...register('mode', { required: true })}
                >
                  <option value='Team'>Team</option>
                  <option value='player'>Player</option>
                </select>
          </div> */}
          {
            role==='coach'?null:
          <div className="form-field w-50">
              <label>
                 Team Coach     
              </label>
              <select
                  className="form-control py-2 form-input bd-grad text-white"
                  id="leagueId"
                  {...register('coachId', { required: true })}
                >
                  {
                   allCoaches?.map((item:any)=>{return(
                     <option key={item?.name} value={item?.id}>{item?.name}</option>
                   )})  
                  }
                </select>
          </div>
          }
          <div className="form-field w-50">
            <label>Youtube</label>
            <input
                  type="text"
                  id="youtubeLink"
                  className="form-control form-input bd-grad text-white"
                  placeholder="Team Youtube Link"
                  {...register('youtubeLink')}
                 />
          </div>
        </div>    
        <div className="d-flex justify-content-between gap-3 mt-5">
         
          <div className="form-field w-50">
            <label>Twitch</label>
            <input
                  type="text"
                  id="twitchLink"
                  className="form-control form-input bd-grad text-white"
                  placeholder="Team Twitch Link"
                  {...register('twitchLink')}
                 />
          </div>
          <div className="form-field w-50">
            <label>Twitter</label>
            <input
                  type="text"
                  id="twitterLink"
                  className="form-control form-input bd-grad text-white"
                  placeholder="Team Twitter Link"
                  {...register('twitterLink')}
                 />
          </div>
        
        </div>    
        <div className="form-field py-2 text-center mt-3">
            
            {
              isImgUploaded||createTeamLoading? 
              <button disabled className="default-btn">
               Creating team...
               <span></span>
              </button>:
              <button id="submit" className="default-btn" type="submit" >
                 Submit
                 <span></span>
              </button>
            }
        </div>
      </form>

      <div className="container ">
        <div className="crud shadow-lg p-3 mb-5 mt-5 bg-gradient rounded">
          <div className="row ">
            <div className="col-sm-3  mb-4 text-gred">
              <div className="search">
                <form className="form-inline">
                  <input
                    onChange={(e:any)=>{setteamName(e?.target?.value)}}
                    className="form-control form-feild bg-grad mr-sm-2"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                </form>
              </div>
            </div>
            <div
              className="col-sm-3 offset-sm-2  mb-4 text-gred"
              style={{ color: 'green' }}
            >
              <p className="text-white">Team List</p>
            </div>
          </div>
          <div className="row">
            <div className="table-responsive ">
              <table className="table  bg-gradient text-white">
                <thead>
                  <tr>
                    <th>Logo</th>
                    <th>Name </th>
                    
                    <th>Coach Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    allTeams?.filter((item:any)=>{
                      return teamName.toLowerCase() ===''?item:item?.name.toLowerCase().includes(teamName)
                    })
                    .map((item)=>{
                      return(
                        <tr key={item.id}>
                        <td className="text-white">
                          {
                            item?.logoUrl?.includes('https')?
                            <img
                            className="img-list img-fluid inline-block"
                            src={item?.logoUrl}
                          />
                            :<img
                            className="img-list img-fluid inline-block"
                            src={teamlogoOne}
                          />
                          }
                        </td>
                        <td className="text-white">{item?.name}</td>
                        <th>{item?.coach?.name}</th>
                       
                        <td>
                          <a
                            href="#"
                            className="edit"
                            title="Edit"
                            data-toggle="tooltip"
                          >
                            <i className="material-icons" onClick={()=>{handleShow(item)}}>
                              &#xE254;
                            </i>
                          </a>
                          <a
                            className="delete"
                            title="Delete"
                            data-toggle="tooltip"
                          >

                            {/* <i className="material-icons" onClick={()=>{deleteTeam(item?.id)}}>&#xE872;</i> */}
                            
                            {/* <i className="material-icons" onClick={() => {
    const confirmBox = window.confirm(
      "Do you really want to delete this Crumb?"
    )
    if (confirmBox === true) {
      handleDelete(item?.id)
    }
  }}>&#xE872;</i> */}
     <i className="material-icons" style={{cursor:'pointer'}} onClick={()=>{handleDeleteModalShow(item?.id)}}>&#xE872;</i>
                          </a>
                        </td>
                      </tr>      
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>

          {/* <!--- Model Box ---> */}
         
        </div>
        {
          show?<UpdateTeam show={show} data={editTeam} games={allCoaches} onClose={handleClose}/>:null
        }
        {
          showDelete?<DeleteModal title='Delete Team' message='Are you sure that you want to delete this team ?' show={showDelete} onClose={handleDeleteClose} onDelete={handleDelete} deleteTeamId={deleteTeamId}/>:null
        }
      </div>
      
    </div>
  )
}

function UpdateTeam(props:any){
  const [imagePreviewUrl,setimagePreviewUrl] = useState();
  const {mutate:teamUpdate,isSuccess:teamUpdates} = useTeamUpdate();
  const { register,
    handleSubmit,  
    reset
    } = useForm<MyValues>({});
    const {
      mutate: uploadTeamLogo,
      data: imageData,
      // isSuccess: imageUploaded,
      // isLoading: isImgUploaded,
      isError: errorUploadingImg,
    } = useUploadFile()
    const onUpdate: SubmitHandler<MyValues> = (updatedData) =>{
      if(errorUploadingImg)
      {
        toast.success('Team is updated successfully.....', {
          position: toast.POSITION.TOP_RIGHT
         });
         props.onClose();
        // alert('Failed to upload the team logo')
      }
      else{
        const currentCoachId  = props?.games?.filter((item:any)=>item.name==updatedData.coachId);
        const data = {
          id:props?.data?.id,
          payload:{
            ...updatedData,
            coachId:currentCoachId&&currentCoachId[0]?.id,
            logoUrl:imageData?.url,
          }
        }

        // alert('Data before sending to api');
        teamUpdate(data);
      }
      // console.log('data before the update is : ',data);
    }
    // const {mutate:updateTeam,isSuccess:teamUpdated} = useUpdateTeam();
  const getImagePreview = (e:any)=>{
    if (e.target.files && e.target.files.length > 0) {
      setimagePreviewUrl(e.target.files[0]);
    }
    
  }
  const handleUploadMedia = (e:any) =>{
    getImagePreview(e)
    uploadTeamLogo({ folderName: "teamLogo", field: "teamLogoUrl", file: e.target.files[0] });
  }
  useEffect(()=>{
    if(teamUpdates)
    {
      toast.success('Team is updated successfully.....', {
        position: toast.POSITION.TOP_RIGHT
       });
       setTimeout(()=>{
        reset({
          name:'',
          coachId:'',
        })
        props.onClose();
       },2500)
    }
  },[teamUpdates])
  return(
    <div className="model_box">
            <Modal
              show={props?.show}
              onHide={props.onClose}
              backdrop="static"
              keyboard={false}
              className="bg-gradient"
            >
              <Modal.Header closeButton>
                <Modal.Title className='text-dark mb-0'>Edit Team</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <form
        action=""
        method="post"
        id="liveGame"
        className="form-horizontal"
        onSubmit={handleSubmit(onUpdate)}
      >
        <div className="d-flex flex-column justify-content-between gap-3">
          <div className="form-field w-100">
            <label>Name</label>
            <input
              type="text"
              id="name"
              className="form-control text-black"
              placeholder="admin"
              defaultValue={props?.data?.name}
              {...register('name', { required: false })}
            />
          </div>
        </div>    
        <div className="d-flex flex-column justify-content-between gap-3 mt-2">
        
          <div className="form-field w-100">
              <label>
                 Team Coach      
              </label>
              <select
                  className="form-control text-black"
                  id="coachId"
                  defaultValue={props?.data?.coach?.name}
                  {...register('coachId', { required: true })}
                >
                  {
                   props?.games?.map((item:any)=>{return(
                     <option key={item?.name} value={item?.name}>{item?.name}</option>
                   )})  
                  }
                </select>
          </div>
        </div>    
        <div className="form-field w-100 mt-2">
            <label>Youtube</label>
            <input
                  type="text"
                  id="youtubeLink"
                  className="form-control text-dark"
                  placeholder="Team Youtube Link"
                  defaultValue={props?.data?.youtubeLink}
                  {...register('youtubeLink')}
                 />
        </div>
        <div className="form-field w-100 mt-2">
            <label>Twitch</label>
            <input
                  type="text"
                  id="twitchLink"
                  className="form-control text-dark"
                  placeholder="Team Twitch Link"
                  defaultValue={props?.data?.twitchLink}
                  {...register('twitchLink')}
                 />
          </div>
          <div className="form-field w-100 mt-2">
            <label>Twitter</label>
            <input
                  type="text"
                  id="twitterLink"
                  className="form-control text-dark"
                  placeholder="Team Twitter Link"
                  defaultValue={props?.data?.twitterLink}
                  {...register('twitterLink')}
                 />
          </div>
   
        <div className='d-flex flex-column align-items-center justify-content-center w-100 mt-3 p-0'>
             <label>
                Team Logo         
             </label>
             {
              imagePreviewUrl?<img  className='img-fluid m-0 p-0 updatedLogo'  src={URL.createObjectURL(imagePreviewUrl)}></img>:
             <img  className='img-fluid m-0 p-0 updatedLogo'  src={props?.data?.logoUrl}></img>
             }
        </div>
        <div className="form-field w-100 my-2">
            <label>
              Logo URL      
            </label>
                <input
                  type="file"
                  className="form-control "
                  onChange={handleUploadMedia}
                />
          </div>
          <div className='d-flex align-items-center justify-content-center py-2 m-0 p-0'>
            <button id="submit" className="btn btn-success w-25 mt-2 text-center" type="submit" >
                Update
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  {/* Model Box Finsihs */}
 </div>
)}
// function DeleteModal (props:any){
//   return(
//     <div className="model_box">
//             <Modal
//               show={props?.show}
//               onHide={props?.onClose}
//               backdrop="static"
//               keyboard={false}
//               className="bg-gradient"
//             >
//               <Modal.Header closeButton>
//                 <Modal.Title className='text-dark mb-0'>Delete Team</Modal.Title>
//               </Modal.Header>
//               <Modal.Body>
//                <div className='d-flex flex-column align-items-center justify-content-center m-0 p-3'>
//                  <p className='text-center text-dark'>{props?.message}</p>
//                  <div className='d-flex align-items-center justify-content-center w-100 gap-4'>
//                     <button className='btn btn-danger text-white fs-6 w-25' onClick={props.onDelete}>Delete</button>
//                     <button className='btn btn-light text-dark border border-1 fs-6 w-25' onClick={props?.onClose}>Cancel</button>
//                  </div>
//                </div>
//               </Modal.Body>
//     </Modal>
//   {/* Model Box Finsihs */}
//  </div>
//   )
// }