import { client } from "./api-client";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
// get all games by ranking
export const GetAllRankings = (limit:number,offset:number)=>
useQuery<any,Error>(
    ['getRanking'],
    ()=> client(`team/getAllLeaguesRanking?limit=${limit}&offset=${offset}`)
)
// get all players by ranking
export const UseAllPlayersRanking = ()=>
useQuery<any,Error>(
    ['getPlayersRanking'],
    ()=>client('score/getPlayerRanking')
)
export const addScore = ()=>{
    const queryClient = useQueryClient()
   return useMutation<any,Error>((data)=>
     client(`score/addScore`,{data}),
     {
        onSuccess(){
            queryClient.invalidateQueries(['getPlayersRanking','getRanking'])
        }
    }
    )
}