import React from 'react'
import aboutus from 'assets/docs/yellasports.pdf'

export default function Schedule() {
  return (
    <div className="container">
      <div className="section-heading text-center pt-5">
        <h2>About US</h2>
      </div>
      <embed src={aboutus} width="100%" height="850" type="application/pdf" />
    </div>
  )
}
