import React from 'react'
import { useFieldArray, useForm } from 'react-hook-form'

const ScoreData = (prop: any) => {
  const { register, control } = prop

  const { append, fields, remove } = useFieldArray({
    control,
    name: 'user',
  })

  return (
    <>
      <div className="container">
        {fields.map((item, index) => (
          <>
            <div
              className="form-row form-group d-flex align-items-center gap-2"
              key={item.id}
            >
              <div className="col">
                <label>Select Player</label>
                <select
                  className=" form-control w-100 "
                  {...register}
                  name={`user[${index}.players]`}
                >
                  <option selected>Select Player</option>
                  <option value="p1">Player 1</option>
                  <option value="p2">Player 2</option>
                  <option value="p3">Player 3</option>
                  <option value="p4">Player 4</option>
                </select>
              </div>
              <div className="col">
                <label>Points</label>
                <input
                  type="text"
                  className="form-control w-100"
                  id="points"
                  name={`user[${index}.points]`}
                  {...register}
                />
              </div>
              <div className="col">
                <label>Assits</label>
                <input
                  type="text"
                  className="form-control w-100"
                  id="assist"
                  name={`user[${index}.assist]`}
                  {...register}
                />
              </div>
              <div className="col">
                <label>Rebounds</label>
                <input
                  type="text"
                  className="form-control w-100"
                  id="rebounds"
                  name={`user[${index}.rebounds]`}
                  {...register}
                />
              </div>
              <div className="col">
                <label>Block</label>
                <input
                  type="text"
                  className="form-control w-100"
                  id="blocks"
                  name={`user[${index}.block]`}
                  {...register}
                />
              </div>
              <div className="col">
                <label>Steal</label>
                <input
                  type="text"
                  className="form-control w-100"
                  id="blocks"
                  name={`user[${index}.steal]`}
                  {...register}
                />
              </div>
              <div className="col">
                <button
                  className="btn btn-danger"
                  onClick={() => remove(index)}
                >
                  Delete
                </button>
              </div>
            </div>
          </>
        ))}
      </div>

      <button
        className="btn btn-primary text-right"
        onClick={() =>
          append({ assit: '', ponts: '', rebound: '', steal: '', player: '' })
        }
      >
        Add Score
      </button>
    </>
  )
}

export default function Reporting() {


  const { register, control } = useForm()
  const basicForm = (
    <div className="container ">
      <div className="report-form-group ">
        <div className="d-flex justify-content-between gap-3">
          <div className="form-field w-100 ">
            <label>Select Team 1</label>
            <select
              className="form-control bd-grad"
              {...register('team1Id', { required: true })}
            >
              <option value="Ascend-Charter-School">
                Ascend Charter School
              </option>
              <option value="Brooklyn-Charter-School">
                Brooklyn Charter School
              </option>
              <option value="Melee(UD)">Melee(UD)</option>
              <option value="Urban-Dove-Team-Flyers">
                Urban Dove Team Flyers
              </option>
            </select>
          </div>
          <div className="form-field w-100 ">
            <label>Select Team 2</label>
            <select
              className="form-control bd-grad"
              {...register('team1Id', { required: true })}
            >
              <option value="Ascend-Charter-School">
                Ascend Charter School
              </option>
              <option value="Brooklyn-Charter-School">
                Brooklyn Charter School
              </option>
              <option value="Melee(UD)">Melee(UD)</option>
              <option value="Urban-Dove-Team-Flyers">
                Urban Dove Team Flyers
              </option>
            </select>
          </div>
        </div>

        <div className="d-flex justify-content-between gap-3">
          <div className="form-field w-100 ">
            <label>(Win , Lose, Forfeit) for Team 1</label>
            <select
              className="form-control bd-grad"
              {...register('team1Result', { required: true })}
            >
              <option value="win">Win</option>
              <option value="lose">Lose</option>
              <option value="forfiet">Forfiet</option>
            </select>
          </div>
          <div className="form-field w-100 ">
            <label>(Win , Lose, Forfeit) for Team 1</label>
            <select
              className="form-control bd-grad"
              {...register('team1Result', { required: true })}
            >
              <option value="win">Win</option>
              <option value="lose">Lose</option>
              <option value="forfiet">Forfiet</option>
            </select>
          </div>
        </div>
        <div className="d-flex justify-content-between gap-3">
          <div className="form-field w-100">
            <label>Total points for team 1</label>
            <input
              type="text"
              id="Team_one_point"
              className="form-control bd-grad"
              placeholder=""
              {...register('team1Points', { required: true })}
            />
          </div>
          <div className="form-field w-100">
            <label>Total points for team 1</label>
            <input
              type="text"
              id="Team_one_point"
              className="form-control bd-grad"
              placeholder=""
              {...register('team1Points', { required: true })}
            />
          </div>
        </div>
        <div className="form-field py-2">
          <label>Twitch or YouTube link for Game</label>
          <input
            type="text"
            id="gameURL"
            className="form-control bd-grad"
            placeholder=""
            {...register('gameLink', { required: true })}
          />
        </div>
        <div className="form-field py-2">
          <label className="py-2">
            Final Score Screenshot <br />
            Upload Screenshot of final score on screen
          </label>
          <input
            type="file"
            id="scoreIMG"
            name="Team_two_point"
            className="form-control bd-grad"
            placeholder=""
          />
        </div>
        <div className="form-field message py-2">
          <textarea
            id="gameNote"
            className="form-control bd-grad"
            placeholder="Game Notes"
            {...register('gameNotes', { required: true })}
          ></textarea>
        </div>
        <div className="form-field py-2 ">
          <label>Select Game</label>
          <select
            className="form-control bd-grad"
            id="teams"
            // onChange={e => SelectTeam(e)}
          >
            <option value="">Select Team</option>
            <option value="NBA-2K">NBA 2K</option>
            <option value="Super-Smash-Brother">Super Smash Brother</option>
            <option value="Rocket-League">Rocket League</option>
            <option value="Madden">Madden</option>
            <option value="Call-of-Duty">Call of Duty</option>
          </select>
        </div>
      </div>
    </div>
  )

  return (
    <>
      <div
        className="section-heading mb-40 text-center wow fade-in-bottom py-5"
        data-wow-delay="200ms"
      >
        <h2>
          Play Now <span>Form</span>
        </h2>
      </div>
      <div className="container ">
        <form
          id="liveGame"
          className="form-horizontal"
          action=""
          method=""
        >
          {basicForm}
          <ScoreData register={register} control={control} />
          <button className="default-btn mt-3" type="submit">
            Submit
          </button>
        </form>
      </div>
    </>
  )
}
