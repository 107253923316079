import React,{useState,useContext,useEffect} from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper";
import { ShopingCart } from 'store';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StarRating from 'components/starRating';
import { useIsLogin } from 'hooks';
import {BsArrowLeftShort} from 'react-icons/bs'
export default function ProductDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const {isLogin} = useIsLogin();
  const productData = location?.state?.data;
  const [thumbsSwiper, setThumbsSwiper] = useState<number>(0);
  const [productQuantity,setproductQuantity] = useState<number>(1);
  const  [isAdded,setisAdded] = useState(false);
  const data:any = useContext(ShopingCart);
  const {cartCount,setcartCount} = data;
  const[cartArray,setcartArray]:any[] = useState([]);
  const handleAddToCart =()=>{
    const getIndex = cartArray.find((item:any)=>  item.id===productData?.id);
    if(getIndex === undefined)
    {
      const productPayload = {
        ...productData,
        productQty:productQuantity
      }
      cartArray.push(productPayload);
      localStorage.setItem('cart',JSON.stringify(cartArray));
      setcartCount(cartCount+1);
      setTimeout(()=>{
        setproductQuantity(1);
        handleSuccessMessage({message:'Product is added to the cart....'});
      },500);
    }
    else{
      const currentIndex = cartArray?.findIndex((item:any)=>item.id===productData.id)
      cartArray[currentIndex].productQty+=productQuantity;
      localStorage.setItem('cart',JSON.stringify(cartArray));
      handleSuccessMessage({message:'Product is added to the cart....'});
      // console.log('Object is found at index : ',currentIndex,' and quantity of the product is : ',cartArray[currentIndex]?.productQty);
    } 
  }
  const handleSuccessMessage = ({message}:{message:string}) =>{
    toast.success(`${message}`, {
      position: toast.POSITION.TOP_RIGHT
     });
     setisAdded(true);   
    // alert(message);
  }
  useEffect(() => {
    window.scrollTo(0,0);
   const products:any = localStorage.getItem('cart');
   if(products===null)
   {
    setcartCount(0);
   }
   else{
    setcartArray(JSON.parse(products));
    setTimeout(() => {
      setcartCount(JSON.parse(products)?.length);
    }, 1500);
   }
  //  console.log('Products from local storage are : ',JSON.parse(products).length);
  }, [])
  
  return (
    <>
      <section className="shop-section single padding">
        <div className='container'>
          <button className='text-light mb-3 fs-4' onClick={()=>{navigate(-1)}}><BsArrowLeftShort/> Back</button>
        </div>
        <div className="container">
          {
            isAdded&&cartCount>0?
            <div className='conatiner-fluid mb-4 m-0 p-4 bd-grad' >
             <div className='d-flex align-items-center justify-content-between'>
              <h4 className='fs-4'>{productData?.name} is added to the cart </h4>
              <Link className='mx-2 default-btn' to={isLogin?"/addcart":"/login"}>View Cart</Link></div>
            </div>:null
          }
          <div className="row">
            <div className="col-md-6 sm-padding product-details-wrap">
            <Swiper      
             loop={true}
             spaceBetween={10}
             modules={[FreeMode, Thumbs]}
             className="mySwiper2">
            <SwiperSlide>
              <img style={{height:'350px',width:'100%',objectFit:'cover'}} src={productData?.images[thumbsSwiper]?.src} />
            </SwiperSlide>
      </Swiper>
      <Swiper
        loop={true}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper mt-3"
      >
        {
          productData?.images?.map((item:any,index:number)=>{
           return(
            <SwiperSlide key={item}>
              <img onClick={()=>{setThumbsSwiper(index)}} className='border border-0 border-success' style={{objectFit:'cover',height:'120px'}} src={item?.src} />
            </SwiperSlide>
           ) 
          })
        }
      </Swiper>
            </div>
            <div className="col-md-6 sm-padding">
              <div className="product-details">
                <div className="product-info">
                  <div className="product-inner">
                    <ul className="category">
                      <li>
                        <a href="#">{productData?.categories[0]?.name}</a>
                      </li>
                    </ul>
                    <ul className="rating">
                    <StarRating starCount={productData?.rating_count}/>
                    </ul>
                  </div>
                  <h3>{productData?.name}</h3>
                  <h4 className="price">
                    ${productData?.regular_price}.00 <span>({productData?.stock_status})</span>
                  </h4>
                  <p dangerouslySetInnerHTML={{__html: productData?.short_description}}></p>

                  <div className="product-btn">
                  <div className="btn-group" role="group" aria-label="Basic example">
                    <button type="button" className="btn text-light" onClick={()=>{productQuantity>1?setproductQuantity(productQuantity-1):toast.warning('Quantity can not be reduced...')}}>-</button>
                    <button type="button" className="btn text-light">{productQuantity}</button>
                    <button type="button" className="btn text-light" onClick={()=>{productQuantity<10?setproductQuantity(productQuantity+1):toast.warning('Quantity can not be increase')}}>+</button>
                  </div>
                    {/* <form>
                      <input type="number" name="age" id="age" min="1" />
                    </form> */}
                    <div>
                      <button onClick={handleAddToCart} className="purchase-btn">
                        Add To Cart
                      </button>
                    </div>
                  </div>
                  <ul className="product-meta">
                    <li>
                      SKU:<a href="#">{productData?.sku}</a>
                    </li>
                    <li className='d-none'>
                      Categories:<a href="#">Bag</a> <a href="#">Womens</a>
                    </li>
                    <li >
                      Tags:
                      {
                        productData?.tags?.map((item:any)=>{return(
                          <a key={item} href="#">{item?.name}</a>   
                        )})
                      }
                      {/* <a href="#">Dress</a> <a href="#">Pants</a> */}
                    </li>
                  </ul>
                  <ul className="social-list d-none">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* product descp */}
      <section className="product-description padding-top">
        <div className="container">
          <ul
            className="nav tab-navigation"
            id="product-tab-navigation"
            role="tablist"
          >
            <li role="presentation">
              <button
                className="active"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                Description
              </button>
            </li>
            {/* <li role="presentation">
              <button
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Additional information
              </button>
            </li> */}
            {/* <li role="presentation">
              <button
                id="contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#contact"
                type="button"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
              >
                Reviews (2)
              </button>
            </li> */}
          </ul>
          <div className="tab-content" id="product-tab-content">
            <div
              className="tab-pane fade show active description"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
             <p dangerouslySetInnerHTML={{__html: productData?.short_description}}></p>
              <ul className="description-meta d-none">
                <li>
                  <span>Model wears:</span> UK 10/ EU 38/ US 6
                </li>
                <li>
                  <span>Occasion:</span> Lifestyle, Sport
                </li>
                <li>
                  <span>Country:</span> Italy
                </li>
                <li>
                  <span>Outer:</span> Leather 100%, Polyamide 100%
                </li>
                <li>
                  <span>Lining:</span> Polyester 100%
                </li>
                <li>
                  <span>CounSoletry:</span> Rubber 100%
                </li>
              </ul>
            </div>
            <div
              className="tab-pane fade d-none"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <table className="table product-table">
                <thead>
                  <tr>
                    <th scope="col">Size</th>
                    <th scope="col">Bust</th>
                    <th scope="col">Waist</th>
                    <th scope="col">Hip</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>S</td>
                    <td>34 -36</td>
                    <td>28-30</td>
                    <td>38-40</td>
                  </tr>
                  <tr>
                    <td>M</td>
                    <td>36 -38</td>
                    <td>30-32.5</td>
                    <td>40-43</td>
                  </tr>
                  <tr>
                    <td>L</td>
                    <td>38-40</td>
                    <td>32-34.5</td>
                    <td>42-45.5</td>
                  </tr>
                  <tr>
                    <td>XL</td>
                    <td>40-42</td>
                    <td>35-37</td>
                    <td>46-38</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className="tab-pane fade review d-none"
              id="contact"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <ul className="comment-list">
                <li>
                  <div className="comment-thumb">
                    <img src="assets/img/comment-1.png" alt="img" />
                  </div>
                  <div className="comment-text">
                    <div className="comment-author">
                      <h3>
                        <span>January 5, 2022</span>Elliot Alderson
                      </h3>
                      <ul className="rating">
                        <li>
                          <i className="las la-star"></i>
                        </li>
                        <li>
                          <i className="las la-star"></i>
                        </li>
                        <li>
                          <i className="las la-star"></i>
                        </li>
                        <li>
                          <i className="las la-star"></i>
                        </li>
                        <li>
                          <i className="las la-star"></i>
                        </li>
                      </ul>
                    </div>
                    <p>
                      Our versatile team is built of designers, developers and
                      digital marketers.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="comment-thumb">
                    <img src="assets/img/comment-2.png" alt="img" />
                  </div>
                  <div className="comment-text">
                    <div className="comment-author">
                      <h3>
                        <span>January 5, 2022</span>Melania Trump
                      </h3>
                      <ul className="rating">
                        <li>
                          <i className="las la-star"></i>
                        </li>
                        <li>
                          <i className="las la-star"></i>
                        </li>
                        <li>
                          <i className="las la-star"></i>
                        </li>
                        <li>
                          <i className="las la-star"></i>
                        </li>
                        <li>
                          <i className="las la-star"></i>
                        </li>
                      </ul>
                    </div>
                    <p>
                      Our versatile team is built of designers, developers and
                      digital marketers.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        
      </section>
    </>
  )
}
