/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { useUploadFile } from 'utils/apis/signup'
import { useNavigate } from 'react-router-dom'
import { addScore } from 'utils/apis/ranking'
import { PlayerFormData } from 'utils/types/scoreReporting.Type'
import { getAllLeagues } from 'utils/apis/Leagues'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useGetAllPlayers } from 'utils/apis/teams'

export default function PlayerReporting() {
  const navigate = useNavigate()
  const { data: allPlayers } = useGetAllPlayers()
  const {
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm<PlayerFormData>({
    defaultValues: {
      player1Records: [],
      player2Records: [],
      player1Result: 'win',
      player2Result: 'lose',
    },
  })

  const { data: getAllgames } = getAllLeagues(10, 0) /// for getting all the games
  const player1Id = watch('player1Id')
  const player2Id = watch('player2Id')
  const player1Result = watch('player1Result')
  const player2Result = watch('player2Result')

  const {
    mutate: submitGameScore,
    isSuccess: scoreSubmited,
    isLoading: submitingScore,
    data: scoreData,
  } = addScore()
  const {
    mutate: uploadFile,
    data: imageData,
    isSuccess: imageUploaded,
  } = useUploadFile() //  this is the custom hook for uploading the file

  const selectedGame = watch('selectedGame')
  const {
    fields: player1Records,
    append: appendPlayerResult1,
    remove: removePlayerResult1,
  } = useFieldArray({
    control,
    name: 'player1Records',
  })
  const {
    fields: player2Records,
    append: appendPlayerResult2,
    remove: removePlayerResult2,
  } = useFieldArray({
    control,
    name: 'player2Records',
  })
  const handleUploadMedia = (e: any) => {
    uploadFile({
      folderName: 'playerScore',
      field: 'playersScorePic',
      file: e.target.files[0],
    })
  }
  const onSubmit: SubmitHandler<PlayerFormData> = () => {
    // check that weather the image is uploded if so then check weather the link is a valid youtube or twitch
    if (imageUploaded) {
      sendplayersData()
    }
  }
  const sendplayersData = () => {
    const data = getValues()

    const playerOneObject = {
      id: player1Id,
      points: data.player1Records[0],
    }
    const playerTwoObject = {
      id: player2Id,
      points: data.player2Records[0],
    }

    const payload = {
      player1Id,
      player2Id,
      team1Id: '',
      team2Id: '',
      isTeamSport: false,
      team1Result: data?.player1Result,
      team2Result: data?.player2Result,
      team1Points: data?.player1Points,
      team2Points: data?.player2Points,
      videoLink: data?.videoLink,
      gameNotes: data?.gameNotes,
      leagueId: selectedGame,
      imageUrl: imageData?.url,
      team1Players: playerOneObject,
      team2Players: playerTwoObject,
    }
    ChangeData(payload)
  }

  useEffect(() => {
    if (player1Records?.length || player2Records?.length) {
      setValue('player1Records', [])
      setValue('player2Records', [])
    }
  }, [selectedGame])

  useEffect(() => {
    if (allPlayers?.length) {
      setValue('player1Id', allPlayers?.[0]?.id)
      setValue('player2Id', allPlayers?.[1]?.id)
    }
  }, [allPlayers])

  useEffect(() => {
    if (scoreSubmited) {
      toast.success('Score report is submited successfully.', {
        position: toast.POSITION.TOP_RIGHT,
      })
      reset()
      const id: any = scoreData?.id
      navigate(`/game-report/${id}`)
    }
  }, [scoreSubmited])

  //  function for converting the players points from string to number
  function ChangeData(data: any) {
    const points: any = {}
    const points2: any = {}

    Object.entries(data?.team1Players?.points)?.map(
      item =>
        (points[item[0]?.replace('_', '.')] =
          item[0] === 'player'
            ? allPlayers?.filter(item => item?.id === data?.player1Id)[0]?.name
            : item[1]),
    )
    Object.entries(data?.team2Players?.points)?.map(
      item =>
        (points2[item[0]?.replace('_', '.')] =
          item[0] === 'player'
            ? allPlayers?.filter(item => item?.id === data?.player2Id)[0]?.name
            : item[1]),
    )

    const obj: any = {
      id: data?.player1Id,
      points: points,
    }

    const obj2: any = {
      id: data?.player2Id,
      points: points2,
    }

    const testSample = {
      ...data,
      team1Players: [obj],
      team2Players: [obj2],
    }

    submitGameScore(testSample)
  }

  return (
    <form
      action=""
      method="post"
      id="liveGame"
      className="form-horizontal"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="report-form-group ">
        <div className="d-flex justify-content-between gap-3">
          <div className="form-field w-100 ">
            <label>Select Player 1</label>
            {player1Id && (
              <select
                defaultValue={player1Id}
                className="form-control bd-grad"
                {...register('player1Id', { required: true })}
              >
                {allPlayers
                  ?.filter(item => item.id !== getValues('player2Id'))
                  ?.map((item, index) => (
                    <option key={index} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
              </select>
            )}
          </div>
          <div className="form-field w-100">
            <label>Select Player 2 </label>
            {player2Id && (
              <select
                defaultValue={player2Id}
                className="form-control bd-grad"
                {...register('player2Id', { required: true })}
              >
                {allPlayers
                  ?.filter(item => item.id !== getValues('player1Id'))
                  .map((item, index) => (
                    <option key={index} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
              </select>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between gap-3">
          <div className="form-field py-2 w-100">
            <label>(Win , Lose, Forfeit) for Player 1</label>
            {player2Result && (
              <select
                className="form-control bd-grad"
                defaultValue="win"
                {...register('player1Result', { required: true })}
              >
                {['Win', 'Lose', 'Forfeit']
                  .filter(item => item.toLowerCase() !== player2Result)
                  .map(item => {
                    return (
                      <option key={item} value={item.toLowerCase()}>
                        {item}
                      </option>
                    )
                  })}
              </select>
            )}
          </div>
          <div className="form-field py-2 w-100 ">
            <label>(Win , Lose, Forfeit) for Player 2</label>
            {player1Result && (
              <select
                defaultValue="lose"
                className="form-control bd-grad"
                {...register('player2Result', { required: true })}
              >
                {['Win', 'Lose', 'Forfeit']
                  .filter(item => item.toLowerCase() !== player1Result)
                  .map(item => {
                    return (
                      <option key={item} value={item.toLowerCase()}>
                        {item}
                      </option>
                    )
                  })}
              </select>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between gap-3">
          <div className="form-field w-100">
            <label>Total points for player 1</label>
            <input
              type="number"
              id="Team_one_point"
              className="form-control bd-grad"
              placeholder=""
              {...register('player1Points', {
                required: 'Add total points for player 1',
              })}
            />
            {errors?.player1Points && errors?.player1Points?.message && (
              <p className="error text-danger">
                {errors?.player1Points?.message}
              </p>
            )}
          </div>
          <div className="form-field w-100">
            <label>Total points for player 2</label>
            <input
              type="number"
              id="Team_two_point"
              className="form-control bd-grad"
              placeholder=""
              {...register('player2Points', {
                required: 'Add total points for player 2',
              })}
            />
            {errors?.player2Points && errors?.player2Points?.message && (
              <p className="error text-danger">
                {errors?.player2Points?.message}
              </p>
            )}
          </div>
        </div>

        <div className="form-field py-2">
          <label>Twitch or YouTube link for Game</label>
          <input
            type="text"
            id="videoLink"
            className="form-control bd-grad"
            placeholder=""
            {...register('videoLink', {
              required: 'Enter valid youtube link',
            })}
          />
          {errors?.videoLink && errors?.videoLink?.message && (
            <p className="error text-danger">{errors?.videoLink?.message}</p>
          )}
        </div>
        <div className="form-field py-2">
          <label className="py-2">
            Final Score Screenshot <br />
            Upload Screenshot of final score on screen
          </label>
          <input
            type="file"
            id="imageUrl"
            className="form-control bd-grad"
            placeholder=""
            {...register('imageUrl')}
            onChange={handleUploadMedia}
          />
        </div>
        <div className="form-field message py-2">
          <textarea
            id="gameNote"
            className="form-control bd-grad"
            placeholder="Game Notes"
            {...register('gameNotes')}
          ></textarea>
        </div>
        <div className="form-field py-2 ">
          <label>Select Game</label>
          <select
            className="form-control bd-grad"
            id="teams"
            {...register('selectedGame', {
              required: 'Please select game',
            })}
          >
            <option value="">Select Game</option>
            {/* these teams should be dynamic (START) */}
            {getAllgames?.map(item => {
              return (
                <option key={item?.name} value={item?.id}>
                  {item?.name}
                </option>
              )
            })}
          </select>
          {errors?.selectedGame && errors?.selectedGame?.message && (
            <p className="error text-danger">{errors?.selectedGame?.message}</p>
          )}
        </div>
        {selectedGame && (
          <div className="scoreTable py-3">
            <p className="text-white">Game result for Player 1</p>
            <div className="table-responsive">
              <table className="table table-bordered bd-grad">
                <thead>
                  <tr>
                    <th className="text-white">Player</th>
                    {getAllgames
                      ?.filter(game => game?.id === selectedGame)[0]
                      ?.resultsKeys?.map(item => (
                        <th key={item} className="text-white">
                          {item}
                        </th>
                      ))}
                    <th className="text-white">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {player1Records?.map((field, index) => {
                    return (
                      <tr key={field.id}>
                        {Object.keys(field)?.map(item => {
                          if (item !== 'id')
                            return (
                              <td key={item} className="text-white">
                                {item === 'player' ? (
                                  allPlayers?.filter(
                                    item => item?.id === player1Id,
                                  )[0]?.name
                                ) : (
                                  <input
                                    type="number"
                                    {...register(
                                      `player1Records.${index}.${[item]}`,
                                      { required: true },
                                    )}
                                    placeholder={item
                                      ?.toUpperCase()
                                      ?.replace('_', '.')}
                                  />
                                )}
                              </td>
                            )
                          return null
                        })}
                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={() => removePlayerResult1(index)}
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            {player1Records?.length < 1 && (
              <button
                type="button"
                className="text-white"
                onClick={() => {
                  const values = getAllgames?.filter(
                    game => game?.id === selectedGame,
                  )[0]?.resultsKeys
                  const newObj = values?.reduce(
                    (obj, cur) => ({
                      ...obj,
                      [cur?.replace('.', '_')]: '',
                    }),
                    { player: '' },
                  )
                  appendPlayerResult1(newObj)
                }}
              >
                Add Results
              </button>
            )}
          </div>
        )}
        {/* Selection for the second team Start */}
        {selectedGame && (
          <div className="scoreTable py-3">
            <p className="text-white">Game result for Player 2</p>
            <div className="table-responsive m-0 p-0">
              <table className="table table-bordered bd-grad">
                <thead>
                  <tr>
                    <th className="text-white">Player</th>
                    {getAllgames
                      ?.filter(game => game?.id === selectedGame)[0]
                      ?.resultsKeys?.map(item => (
                        <th key={item} className="text-white">
                          {item}
                        </th>
                      ))}
                    <th className="text-white">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {player2Records?.map((field, index) => {
                    return (
                      <tr key={field.id}>
                        {Object.keys(field)?.map(item => {
                          if (item !== 'id')
                            return (
                              <td key={item} className="text-white">
                                {item === 'player' ? (
                                  allPlayers?.filter(
                                    item => item?.id === player2Id,
                                  )[0]?.name
                                ) : (
                                  <input
                                    type="number"
                                    {...register(
                                      `player2Records.${index}.${item}`,
                                      { required: true },
                                    )}
                                    placeholder={item
                                      ?.toUpperCase()
                                      ?.replace('_', '.')}
                                  />
                                )}
                              </td>
                            )
                          return null
                        })}
                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={() => removePlayerResult2(index)}
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            {player2Records?.length < 1 && (
              <button
                type="button"
                className="text-white"
                onClick={() => {
                  const values = getAllgames?.filter(
                    game => game?.id === selectedGame,
                  )[0]?.resultsKeys
                  const newObj = values?.reduce(
                    (obj, cur) => ({ ...obj, [cur.replace('.', '_')]: '' }),
                    { player: '' },
                  )
                  appendPlayerResult2(newObj)
                }}
              >
                Add Results
              </button>
            )}
          </div>
        )}

        {/* Selection for the second team End */}

        <div className="form-field py-2 text-center">
          {submitingScore ? (
            <button className="default-btn">
              Submiting Score.....<span></span>
            </button>
          ) : (
            <button id="submit" className="default-btn" type="submit">
              Submit Report<span></span>
            </button>
          )}
        </div>
      </div>
      <div id="form-messages" className="alert" role="alert"></div>
    </form>
  )
}
